import {Injectable} from "@angular/core";
import {APIClientService} from "../../services/apiclient.service";
import {catchError, Observable, tap, throwError} from "rxjs";
import {Category} from "../interfaces/Category";
import {APIType} from "../../common/enums/APIType";
import {RequestType} from "../../common/enums/RequestType";
import {ListCategoriesResponse} from "../interfaces/response-pagination/ListCategoriesResponse";
import {CategoryFilterData} from "../classes/CategoryFilterData";


@Injectable({providedIn: 'root'})
export class CategoryAPI {
  constructor(private readonly apiClient: APIClientService) {

  }


  save(parameters: {
    category: Category,
  }): Observable<string> {

    let category = parameters.category;
    let id = parameters.category.id ?? "";
    let dataUrl = id.hasActualValue() ? `/${id}` : "";
    let request = {
      id: category.id,
      nameEn: category.nameEn,
      nameAr: category.nameAr,
      descriptionEn: category.descriptionEn,
    }

    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_CATEGORY,
      requestType: RequestType.POST,
      body: request,
      dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  getList(filterData: CategoryFilterData): Observable<ListCategoriesResponse> {
    let parameter = CategoryFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListCategoriesResponse>({
      apiType: APIType.GET_LIST_CATEGORIES,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getDetails(parameters: {
    categoryId: string,
  }): Observable<Category> {
    let dataUrl = parameters.categoryId;
    return this.apiClient.call<any, Category>({
      apiType: APIType.GET_CATEGORY_DETAILS,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  delete(parameters: {
    category: Category,
  }): Observable<void> {
    let dataUrl = parameters.category.id
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_CATEGORY,
      requestType: RequestType.DELETE,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

}

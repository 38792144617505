import { BooleanValue, Boolean } from "../data-types/Boolean";

declare global {
  interface String {
    hasActualValue(): boolean;

    toBool(): boolean;

    toBoolean(): BooleanValue;

    toDate(): Date | null;

    format(...args: any[]): string;

    toCents(): string;

    toDollars(): string;
  }


  interface Date {
    toTimeStamp(): string;
  }
}


String.prototype.format = function (...args: string[]): string {
  let formattedString = this.toString();

  args.forEach(arg => {
    formattedString = formattedString.replace('{}', arg.toString());
  });

  return formattedString;
};

String.prototype.toCents = function (): string {
  const amount = this
  if (amount?.hasActualValue()) {
    const intValue: number = parseFloat(amount.toString());
    return (intValue * 100).toString() ?? "";
  }
  return ""
}

String.prototype.toDollars = function (): string {
  const amount = this
  if (amount?.hasActualValue()) {
    const intValue: number = parseFloat(amount.toString());
    return String(intValue / 100).toString() ?? "";
  }
  return ""
}


Date.prototype.toTimeStamp = function (this: any | null | undefined): string {
  let value = "";
  if (this !== null && this !== undefined) {
    value = parseInt((new Date(this).getTime() / 1000).toFixed(0)).toString() ?? "";
  }
  return value;
}


String.prototype.toDate = function (this: any | null | undefined): Date | null {
  let value = null;
  if (this !== null && this !== undefined) {
    value = parseInt(this) ? new Date(parseInt(this) * 1000) : null
  }
  return value;
}


String.prototype.hasActualValue = function (this: string | null | undefined): boolean {
  return this !== null && this !== undefined && this.length > 0
}


String.prototype.toBool = function (this: string | null | undefined): boolean {
  return this !== null && this !== undefined && this.length > 0 && this?.toLowerCase() === "true"
}

String.prototype.toBoolean = function (this: string | null | undefined): BooleanValue {
  if (this?.toLowerCase() === 'true') {
    return Boolean.True;
  } else if (this?.toLowerCase() === 'false') {
    return Boolean.False;
  } else {
    return Boolean.NotAssigned;
  }
}





export enum MerchantStatus {
  REJECTED = "REJECTED",
  APPROVED = "Approved",
  NO_REJECTED_OR_ACCEPTED = "NO_REJECTED_OR_ACCEPTED",
}








import { Component, EventEmitter, Output } from "@angular/core";


export interface DateRange {
  start: Date;
  end: Date;
}


@Component({
  selector: 'date-range',
  styleUrls: ['./style/date-rage-filter-style.css'], // Specify the URL of your styles file
  template: `
    <div class="row center-row date-range-container">
      <input type="datetime-local" class="form-control date-range-input" id="startDate" [(ngModel)]="startDate"
             (change)="handleDateSelection()">
      <input type="datetime-local" class="form-control date-range-input" id="endDate" [(ngModel)]="endDate"
             (change)="handleDateSelection()">
    </div>
  `
})

export class DateRangeFilter {
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>()
  startDate: string = "";
  endDate: string = "";

  constructor() {
  }


  handleDateSelection() {
    if (this.startDate.length > 0 && this.endDate.length > 0) {
      const startDateFormat = new Date(this.startDate);
      const endDateFormat = new Date(this.endDate);

      let value = {
        start: startDateFormat,
        end: endDateFormat
      };
      this.onSelect?.emit(value);
    } else if (this.startDate.length === 0 && this.endDate.length === 0) {
      // Handle the case when both startDate and endDate are empty
      this.onSelect?.emit(null);

    } else {
      // Handle other cases if needed
    }

  }


}

<div class="input-text-field-container">
  <div class="form-floating mb-3">
    <input id="{{ id }}"
           type="date"
           class="form-control"
           [ngClass]="isInputValid()"
           placeholder="{{floatingLabel}}"
           [formControl]="control"
           [(ngModel)]="inputValue"
           (input)="onInputChange($event)"
           [readonly]="isReadOnly"
           data-provide="datepicker"
    >

    <label for="{{ id }}">{{ floatingLabel }}</label>
    <div class="text-danger">{{ errorMessage }}</div>
  </div>
</div>

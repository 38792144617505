<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">List Cities</a></li>
    <li *ngIf="cityDetails" aria-current="page" class="breadcrumb-item active">{{cityDetails.nameEn}}</li>
    <li *ngIf="!cityDetails" aria-current="page" class="breadcrumb-item active">Add</li>
  </ol>
</nav>

<!-- Show this title only if myObject is null -->
<h1 *ngIf="!cityDetails" class="h3 mb-3"><strong>Add</strong> City</h1>

<!-- Show this title only if myObject exists (not null) -->
<h1 *ngIf="cityDetails" class="h3 mb-3"><strong>Update</strong> City</h1>


<div class="fields-layout">

  <!-- nameEnglishItem -->
  <input-text-field (onchange)="updateInputFieldById(nameEnglishItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameEnglishItem.floatingLabel"
                    [group]="form"
                    [id]="nameEnglishItem.id.toString()"
                    [inputType]="nameEnglishItem.type"
                    [loadData]="nameEnglishItem.loadData"
                    [validators]="nameEnglishItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- nameArabicItem -->
  <input-text-field (onchange)="updateInputFieldById(nameArabicItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameArabicItem.floatingLabel"
                    [group]="form"
                    [id]="nameArabicItem.id.toString()"
                    [inputType]="nameArabicItem.type"
                    [loadData]="nameArabicItem.loadData"
                    [validators]="nameArabicItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- countryItem -->
    <country-search-text-field (onchange)="updateInputFieldById(countryItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [group]="form"
                    [id]="countryItem.id.toString()"
                    [loadData]="countryItem.loadData"
                    class="input-field-layout">
    </country-search-text-field>

<!--  <city-search-text-field (onchange)="updateInputFieldById(countryItem.id.toString(), $event)"-->
<!--                  [errorHandling]="errorHandling"-->
<!--                  [group]="form"-->
<!--                  [id]="countryItem.id.toString()"-->
<!--                  [loadData]="countryItem.loadData"-->
<!--                  class="input-field-layout">-->
<!--  </city-search-text-field>-->





</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="cityDetails ? 'Update' : 'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>






<div  class="list-layout">
  <table-view
    [isLoading] = isScreenLoading
    [tableConfiguration] = "tableConfiguration">
  </table-view>

  <div class="action-button">
    <primary-button
      (onClick)="navigateToCreateNewItem()"
      [loading]="false"
      [title]="'Add Social Media'">
    </primary-button>
  </div>

  <div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
       role="alert">
    {{ errorHandling.errorMessage }}
  </div>

</div>


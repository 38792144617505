import { Component } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../components/table-view/table-view.component";
import { format } from "date-fns";
import { BehaviorSubject, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { UserAPI } from "../../business/apis/UserAPI";
import { UserFilterData } from "../../business/classes/UserFilterData";
import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { ListUserResponse } from "../../business/interfaces/response-pagination/ListUserResponse";
import { TableAction } from "../../components/table-view/enum/TableAction";
import { UserNameId } from "../../business/name-id/UserNameId";
import { User } from "../../business/interfaces/User";

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent {
  static navigatorName: string = NavigatorTarget.users.valueOf();

  tableConfiguration: TableConfiguration<User> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: User[] = [];
  isScreenLoading = false;

  constructor(
    private sanitizer: DomSanitizer,
    private userApi: UserAPI,
    private appNavigator: AppNavigator
  ) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let user = item as User;

    let isDeleted = user.isDeleted ?? false;
    let isBlocked = user.isBlocked ?? false;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let blockItem = {
      title: "Block",
      type: "btn btn-warning me-2",
      iconClass: "bi bi-exclamation-lg",
      actionType: TableAction.BLOCK,
      onTapAction: this.onTapAction.bind(this),
    };

    let unBlockItem = {
      title: "UnBlock",
      type: "btn btn-primary",
      iconClass: "bi bi-arrow-counterclockwise",
      actionType: TableAction.UNBLOCK,
      onTapAction: this.onTapAction.bind(this),
    };

    let detailsItem = {
      title: "Details",
      type: "btn btn-primary",
      iconClass: "bi bi-info-circle",
      actionType: TableAction.DETAILS,
      onTapAction: this.onTapAction.bind(this),
    };


    let actionItems: [boolean, ActionRow][] = [
      [true, detailsItem],
      [!isBlocked && !isDeleted, blockItem],
      [isBlocked && !isDeleted, unBlockItem],
      [!isDeleted, deleteItem],
    ];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {

    let optionBool = [
      {
        value: true,
        label: "true",
        isSelected: false,
      },
      {
        value: false,
        label: "false",
        isSelected: false,
      },
    ];

    let idItem = {
      columId: UserNameId.ID,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let nameEn = {
      columId: UserNameId.NAME,
      name: "Name",
      filterType: ColumnFilterType.text,
    };

    let email = {
      columId: UserNameId.EMAIL,
      name: "Email",
      filterType: ColumnFilterType.text,
    };

    let countryCode = {
      columId: UserNameId.COUNTRY_CODE,
      name: "Country Code",
      filterType: ColumnFilterType.text,
    };

    let phoneNumber =
      {
        columId: UserNameId.PHONE_NUMBER,
        name: "Phone Number",
        filterType: ColumnFilterType.text,
      };

    let birthDate = {
      columId: UserNameId.BIRTHDATE,
      name: "Birth Date",
      filterType: ColumnFilterType.text,
    };


    let emailVerified = {
      columId: UserNameId.EMAIL_VERIFIED,
      name: "Email Verified",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let isAdminItem = {
      columId: UserNameId.IS_ADMIN,
      name: "Is Admin",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let isDeletedItem = {
      columId: UserNameId.IS_DELETED,
      name: "Is Deleted",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let createdAtItem = {
      columId: UserNameId.CREATED_AT,
      name: "Created",
      filterType: ColumnFilterType.dateRang,
    };


    return [
      idItem,
      nameEn,
      email,
      countryCode,
      phoneNumber,
      birthDate,
      isAdminItem,
      emailVerified,
      isDeletedItem,
      createdAtItem,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == UserNameId.ID) {
      this.onTapUserDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let user = item as User;
    let value = user[id as keyof User];
    let styleClass = null;
    if (id == UserNameId.ID) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({ target: NavigatorTarget.userDetails });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new UserFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    this.userApi.getList(filter).subscribe({
      next: (response: ListUserResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let userAction = action as TableAction;
    let user = item as User;
    let call: Observable<any>;

    if (userAction == TableAction.REMOVE) {
      call = this.userApi.delete({ user: user });
    } else if (userAction == TableAction.BLOCK) {
      call = this.userApi.block({ user: user });
    } else if (userAction == TableAction.UNBLOCK) {
      call = this.userApi.unblock({ user: user });
    }  else if (userAction == TableAction.DETAILS) {
      this.onTapUserDetails(item)
      return;
    }else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        let filter = new UserFilterData();
        this.reloadDataSource(null);
      },
      error: (error: any) => {
        this.isScreenLoading = false;
      },
    });
  }

  private onTapUserDetails<T>(item: T) {
    let user = item as User;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.userDetails,
      id: user.id,
    });
  }

  private reloadTable(response: ListUserResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}

<div (click)="toggleCollapse()" class="header" [attr.data-bs-target]="'#' + id" data-bs-toggle="collapse">
  <div>
    <label>{{ title }}</label>
  </div>
  <i class="bi bi-chevron-down"></i>
</div>

<div [ngClass]="{'collapse': true, 'show': isOpen}" [attr.id]="id">
  <div class="content">
    <ng-content></ng-content>
  </div>
</div>

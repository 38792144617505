import { Injectable } from "@angular/core";
import { APIClientService } from "../../services/apiclient.service";
import { catchError, Observable, tap, throwError } from "rxjs";
import { Rate } from "../interfaces/Rate";
import { APIType } from "../../common/enums/APIType";
import { ListRateResponse } from "../interfaces/response-pagination/ListRateResponse";
import { RateFilterData } from "../classes/RateFilterData";
import { RequestType } from "../../common/enums/RequestType";
import { ProductFilterData } from "../classes/ProductFilterData";

@Injectable({ providedIn: 'root' })
export class RateAPI {
  constructor(private readonly apiClient: APIClientService) {

  }

  getAllMerchantRate(filterData: RateFilterData): Observable<ListRateResponse> {
    let parameter = RateFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListRateResponse>({
      apiType: APIType.GET_ALL_MERCHANT_RATE,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getMerchantRate(parameters: {
    filterData: ProductFilterData,
    merchantId: string,
  }): Observable<ListRateResponse> {
    let filterData = parameters.filterData
    let parameter = ProductFilterData.getParameterApi(filterData);

    let dataUrl = [parameters.merchantId]
    return this.apiClient.call<any, ListRateResponse>({
      apiType: APIType.GET_MERCHANT_RATE,
      requestType: RequestType.GET,
      listDataUrl: dataUrl,
      body: parameter,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  getDetails(parameters: {
    id: string,
  }): Observable<Rate> {
    let dataUrl = parameters.id;
    return this.apiClient.call<any, Rate>({
      apiType: APIType.GET_RATE_DETAILS,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  approve(parameters: {
    rate: Rate,
  }): Observable<void> {
    let dataUrl = parameters.rate.id
    return this.apiClient.call<any, void>({
      apiType: APIType.APPROVE_RATE,
      requestType: RequestType.POST,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  addReplayRate(parameters: {
    rate: Rate,
    message: String,
  }): Observable<void> {
    let dataUrl = parameters.rate.id
   var body = {}
    return this.apiClient.call<any, void>({
      apiType: APIType.ADD_REPLAY_RATE,
      requestType: RequestType.POST,
      dataUrl: dataUrl,
      body:body
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  removeReplayRate(parameters: {
    rate: Rate,
  }): Observable<void> {
    let dataUrl = parameters.rate.id
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_REPLAY_RATE,
      requestType: RequestType.DELETE,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  deleteRate(parameters: {
    rate: Rate,
  }): Observable<void> {
    let dataUrl = parameters.rate.id
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_RATE,
      requestType: RequestType.DELETE,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  reject(parameters: {
    rate: Rate,
  }): Observable<void> {
    let dataUrl = parameters.rate.id
    return this.apiClient.call<any, void>({
      apiType: APIType.REJECT_RATE,
      requestType: RequestType.POST,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }




  getAllProductsRate(filterData: RateFilterData): Observable<ListRateResponse> {
    let parameter = RateFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListRateResponse>({
      apiType: APIType.GET_ALL_PRODUCT_RATE,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

  //Product
  getProductRate(parameters: {
    filterData: ProductFilterData,
    id: string,
  }): Observable<ListRateResponse> {
    let filterData = parameters.filterData
    let parameter = ProductFilterData.getParameterApi(filterData);

    let dataUrl = [parameters.id]
    return this.apiClient.call<any, ListRateResponse>({
      apiType: APIType.GET_PRODUCT_RATE,
      requestType: RequestType.GET,
      listDataUrl: dataUrl,
      body: parameter,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }


}

<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">Merchant</a></li>
  </ol>
</nav>

<h1 class="h3 mb-3"><strong>Add</strong> Url</h1>


<div class="fields-layout">

  <!-- longitudeItem -->
  <input-text-field (onchange)="updateInputFieldById(longitudeItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="longitudeItem.floatingLabel"
                    [group]="form"
                    [id]="longitudeItem.id.toString()"
                    [inputType]="longitudeItem.type"
                    [loadData]="longitudeItem.loadData"
                    [validators]="longitudeItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- latitudeItem -->
  <input-text-field (onchange)="updateInputFieldById(latitudeItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="latitudeItem.floatingLabel"
                    [group]="form"
                    [id]="latitudeItem.id.toString()"
                    [inputType]="latitudeItem.type"
                    [loadData]="latitudeItem.loadData"
                    [validators]="latitudeItem.validators"
                    class="input-field-layout"
  ></input-text-field>


</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>









import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {CityAPI} from "../../../../business/apis/CityAPI";
import {City} from "../../../../business/interfaces/City";
import {CityFilterData} from "../../../../business/classes/CityFilterData";
import {
  ColumnFilterType, SortDirection,
} from "../../../../components/table-view/table-view.component";
import {CityNameId} from "../../../../business/name-id/CityNameId";
import {ListCitiesResponse} from "../../../../business/interfaces/response-pagination/ListCitiesResponse";
import {OptionSelect} from "../../../../components/filters/enum/filter-type";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {FormGroup, ValidatorFn} from "@angular/forms";
import {Country} from "../../../../business/interfaces/Country";
import {DropDownListComponent} from "../../../../components/input/drop-down-list/drop-down-list.component";


@Component({
  selector: 'city-search-text-field',
  templateUrl: './city-search-text-field.component.html',
  styleUrls: ['./city-search-text-field.component.css']
})
export class CitySearchTextFieldComponent {
  @Input() errorHandling: ErrorHandling | null = null;
  @Input() validators: ValidatorFn[] = [];
  @Input() id!: string;
  @Input() group!: FormGroup;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

  selectedCountry: string = "";
  listData: City[] = [];
  selected: City | null = null;
  isLoading = false


  @ViewChild(DropDownListComponent) dropDownList?: DropDownListComponent;

  constructor(
    private cityApi: CityAPI,
  ) {

  }

  ngOnInit(): void {
    this.reloadDataSource("");
  }

  ngOnDestroy(): void {

  }

  loadListWithCountry(country: any) {
    if (country.isString()) {
      this.selectedCountry = country
      this.reloadDataSource("");
    } else if (country as Country) {
      this.selectedCountry = country.iso3letter
      this.reloadDataSource("");
    }
  }

  reset(){
    this.selectedCountry = '';
    this.selected = null
    this.listData = [];
    this.dropDownList?.reset()
  }

  loadCityData(city: any) {
    if (city.isString()) {
      this.getCityById(city)
    }
  }

  getCityById(id: string) {
    if (id.hasActualValue()) {
      this.cityApi
        .getDetails({cityId: id})
        .subscribe({
          next: (item: City) => {
            this.selected = item
            this.reloadView()
            this.onchange.emit(this.selected);
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
          },
        });

    }
  }

  reloadDataSource(search: string) {
    let countryIso = this.selectedCountry
    let columnNameInfo = {
      columId: CityNameId.NAME_EN,
      name: "",
      filterType: ColumnFilterType.noun,
    }

    let columnCountryInfo = {
      columId: CityNameId.COUNTRY_ISO3,
      name: "",
      filterType: ColumnFilterType.noun,
    }

    let sortNameField = {
      tableColumnInfo: columnNameInfo,
      direction: SortDirection.desc,
    }

    let filterNameField = {
      tableColumnInfo: columnNameInfo,
      value: search
    }

    let filterCountryField = {
      tableColumnInfo: columnCountryInfo,
      value: countryIso
    }


    let filterMetaData = {
      itemsPerPage: 0,
      currentPage: 1,
    }


    let filterList = search.hasActualValue() ? [filterNameField] : []
    if (countryIso.hasActualValue()) {
      filterList.push(filterCountryField)
    }

    let filter = new CityFilterData();
    filter.filterFieldTable = filterList;
    filter.filterMetaData = filterMetaData;
    filter.sortField = sortNameField;

    this.isLoading = true;

    this.cityApi.getList(filter).subscribe({
      next: (response: ListCitiesResponse) => {
        this.isLoading = false;
        this.listData = response.data;
        this.reloadView();
      },
      error: (error: any) => {
        // Handle error
        this.isLoading = false;
        console.error("Error:", error);
      },
    });

  }

  onInputChange(event: any): void {
    const selectedValue = event as OptionSelect;
    let value = selectedValue.value
    this.selected = value;
    this.onchange.emit(value);
  }

  onSearchChanged(event: any): void {
    // const text = (event.target as HTMLSelectElement).value;
    this.reloadDataSource(event)

  }

  private reloadView() {
    let optionSelected: OptionSelect | null = null
    let list: OptionSelect[] = this.listData.map(item => {
      return {
        value: item,
        label: item.nameEn,
      };
    });

    if (this.selected) {
      optionSelected = {
        value: this.selected,
        label: this.selected.nameEn,
      }
    }


    this.dropDownList?.updateViews([list, optionSelected])
  }

}

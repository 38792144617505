// @Injectable({
//   providedIn: 'root'
// })
import {SortFieldTable} from "../../components/table-view/table-view.component";

export class FilterMetaData {
  itemsPerPage: number | null | undefined;
  currentPage: number | null | undefined;

  static getParameterApi(filterData: FilterMetaData) {

    let params: { [key: string]: any } = {
      "pageSize": filterData.itemsPerPage,
      "page": filterData.currentPage,
    };

    return params;
  }

  static getParameterSort(sortField: SortFieldTable | null) {
    let params: { [key: string]: any } = {
      "sortBy": sortField?.tableColumnInfo.columId,
      "sortOrder": sortField?.direction,
    };
    return params
  }
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
}

export function mergeObjects<T extends object>(...objs: T[]): T {
  let params = Object.assign({}, ...objs);

  Object.keys(params).forEach(key => {
    return (params[key] === null || params[key] === undefined) && delete params[key]
  });

  return params;
}

export function isString(variable: any) {
  return typeof variable === "string" && variable !== undefined;
}


export function getEnumFromValue<T extends { [key: string]: string }>(
  enumObj: T,
  value: string
): keyof T | undefined {
  const enumValues = Object.values(enumObj);
  return enumValues.find((enumValue) => enumValue === value) as keyof T | undefined;
}


export function compare(oldItem:any, newItem :any) : any {
   if(oldItem != null &&
     oldItem != undefined &&
     newItem?.toString().hasActualValue()
     &&  oldItem === newItem) {
     return undefined;
   }
   return  newItem;
}

export function generateFilePaths(parameters :{merchantId: string,productId?: string }): {
  uploadFilePath: string;
  logoFilePath: string;
  coverPhotoFilePath: string;
  imagesFilePath: string;
  imagesProductFilePath: string;
  menuFilePath: string;
  thumbnailProductFilePath: string;
} {
  const uploadFilePath = `merchants/${parameters.merchantId}`;
  const logoFilePath = `${uploadFilePath}/logo`;
  const coverPhotoFilePath = `${uploadFilePath}/cover-photo`;
  const imagesFilePath = `${uploadFilePath}/images`;
  const menuFilePath = `${uploadFilePath}/menu`;
  const imagesProductFilePath = `${uploadFilePath}/products/${parameters.productId}/images`;
  const thumbnailProductFilePath = `${uploadFilePath}/products/${parameters.productId}/thumbnail`;
  return {
    uploadFilePath,
    logoFilePath,
    coverPhotoFilePath,
    imagesFilePath,
    imagesProductFilePath,
    thumbnailProductFilePath,
    menuFilePath
  };
}


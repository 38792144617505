<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">List Currencies</a></li>
    <li *ngIf="currencyDetails" aria-current="page" class="breadcrumb-item active">{{currencyDetails.iso3letter}}</li>
    <li *ngIf="!currencyDetails" aria-current="page" class="breadcrumb-item active">Add</li>
  </ol>
</nav>

<!-- Show this title only if myObject is null -->
<h1 *ngIf="!currencyDetails" class="h3 mb-3"><strong>Add</strong> currency</h1>

<!-- Show this title only if myObject exists (not null) -->
<h1 *ngIf="currencyDetails" class="h3 mb-3"><strong>Update</strong> currency</h1>


<div class="fields-layout">

  <!-- iso3letterItem -->
  <input-text-field (onchange)="updateInputFieldById(iso3letterItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="iso3letterItem.floatingLabel"
                    [group]="form"
                    [id]="iso3letterItem.id.toString()"
                    [inputType]="iso3letterItem.type"
                    [loadData]="iso3letterItem.loadData"
                    [validators]="iso3letterItem.validators"
                    class="input-field-layout"
  ></input-text-field>


</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="currencyDetails ? 'Update' : 'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>







import { FormGroup } from "@angular/forms";

export function isFormSubmitted(formGroup: FormGroup): boolean {
  if (formGroup.valid) {
    return true
  }
  Object.values(formGroup.controls).forEach(control => {
    control.markAsTouched();

    if (control instanceof FormGroup) {
      isFormSubmitted(control);
    }
  });

  return false
}

export function resetForm(form: FormGroup): void {
  form.reset();
  form.clearValidators();
  form.updateValueAndValidity();
}


export function appendHttps(url: string): string {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FormControl, FormGroup, ValidatorFn} from "@angular/forms";

@Component({
  selector: 'check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css']
})
export class CheckBoxComponent {
  @Input() loadData?: BehaviorSubject<any>;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>()
  @Input() validators: ValidatorFn[] = []

  @Input() label: string | undefined = ""
  @Input() id!: string
  @Input() group!: FormGroup


  inputValue: boolean = false;
  control: FormControl = new FormControl()

  constructor() {
  }

  ngOnInit(): void {
    let validators = []
    validators.push(...this.validators)
    this.control.setValidators(validators)
    this.group.addControl(this.id, this.control)


    this.loadData?.subscribe(data => {
      this.inputValue = data
      this.updateViews(data)
    })
  }


  ngOnDestroy(): void {
    this.group?.removeControl(this.id)
  }


  onInputChange(event: Event): void {
    // Get the value of the checkbox (true or false)
    const target = event.target as HTMLInputElement;
    const isChecked: boolean = target.checked;
    this.inputValue = isChecked;
    this.updateViews(isChecked)
  }


  updateViews(value:any){
    this.onchange?.emit(value)
  }
}

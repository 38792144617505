export const environment = {
  production: false,
  apiUrl: "https://test-api.aklbayti.com",
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCWKayVrBJh4unaecvXWTEz2A-AhIPL2Ys",
    authDomain: "akl-bayti-test.firebaseapp.com",
    projectId: "akl-bayti-test",
    storageBucket: "akl-bayti-test.appspot.com",
    messagingSenderId: "272892171363",
    appId: "1:272892171363:web:bcfe927553760a1d521456",
    measurementId: "G-K91H00QGJC"
  }
};


export enum CityNameId {
  ID = 'id',
  NAME_EN = 'nameEn',
  NAME_AR = 'nameAr',
  COUNTRY_ISO3 = "countryISO3letter",
  COUNTRY = 'country',
  CREATED_AT = 'createdAt',
}

export enum CityMiniObjectNameId {
  ID = 'city.id',
  NAME_EN = 'city.nameEn',
  NAME_AR = 'city_nameAr',
  COUNTRY = 'city.nameAr',
}


import { Component } from '@angular/core';
import {NavigatorTarget} from "../../services/app-navigator";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  static navigatorName: string = NavigatorTarget.dashboard.valueOf();
}


export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}






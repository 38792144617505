<div class="mb-3">
  <div class="column">
    <label class="mb-1" >{{getTitle}}</label>
    <app-file-upload
      [loadData] = "loadDataImageSource"
      (onchange) = "updateViews($event)"
      [uploadFilePath]= "imagesFilePath"
      [allowMulti]="false"
      [maxNumberOfFiles]="10"
      [maxFileSizeBytes]="5 * 1024 * 1024"
      [onlyAcceptImages]="true"
      (fileUploaded)="onImageUploaded($event)"
      (fileRemoved)="onImageRemoved($event)"
      (updateCurrentNumberOfFiles)="updateCurrentNumberOfImages($event)">
    </app-file-upload>
    <div class="text-danger">{{getErrorMessage}}</div>
  </div>
</div>

import { Component } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../components/table-view/table-view.component";
import { format } from "date-fns";
import { BehaviorSubject, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { ClaimAPI } from "../../business/apis/ClaimAPI";
import { ClaimFilterData } from "../../business/classes/ClaimFilterData";
import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { ListClaimResponse } from "../../business/interfaces/response-pagination/ListClaimResponse";
import { TableAction } from "../../components/table-view/enum/TableAction";
import { ClaimNameId } from "../../business/name-id/ClaimNameId";
import { Claim } from "../../business/interfaces/Claim";
import { MerchantStatus } from "../../business/enums/MerchantStatus";
import { MerchantMiniObjectNameId } from "../../business/name-id/ProductNameId";


@Component({
  selector: 'app-list-claims',
  templateUrl: './list-claims.component.html',
  styleUrls: ['./list-claims.component.scss']
})
export class ListClaimsComponent {
  static navigatorName: string = NavigatorTarget.claims.valueOf();

  tableConfiguration: TableConfiguration<Claim> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Claim[] = [];
  isScreenLoading = false;
  filterData: FilterTable | null = null

  constructor(
    private sanitizer: DomSanitizer,
    private claimApi: ClaimAPI,
    private appNavigator: AppNavigator
  ) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let claim = item as Claim;

    let isApproved = claim.isApproved ?? false
    let isRejected = claim.isRejected ?? false

    let approveItem = {
      title: "Approve",
      type: "btn btn-success",
      iconClass: "bi bi-check-lg",
      actionType: TableAction.APPROVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let rejectItem = {
      title: "Reject",
      type: "btn btn-danger",
      iconClass: "bi bi-x-circle",
      actionType: TableAction.REJECT,
      onTapAction: this.onTapAction.bind(this),
    };


    let actionItems: [boolean, ActionRow][] = [
      [!isApproved && !isRejected, approveItem],
      [!isApproved && !isRejected, rejectItem],
    ];

    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {

    let optionStatus = [
      {
        value: MerchantStatus.APPROVED,
        label: "Approve",
        isSelected: false,
      },
      {
        value: MerchantStatus.REJECTED,
        label: "Reject",
        isSelected: false,
      },
      {
        value: MerchantStatus.NO_REJECTED_OR_ACCEPTED,
        label: "NO Action",
        isSelected: false,
      },
    ];

    let idItem = {
      columId: ClaimNameId.ID,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: false,
    };

    // let merchantIdItem = {
    //   columId: ClaimNameId.MERCHANT_ID,
    //   name: "Merchant Id",
    //   filterType: ColumnFilterType.text,
    //   isCellSelected: true,
    // };

    let merchantId = {
      columId: MerchantMiniObjectNameId.MERCHANT_OBJECT_ID,
      name: "Merchant Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let slug = {
      columId: MerchantMiniObjectNameId.MERCHANT_SLUG,
      name: "Slug",
      filterType: ColumnFilterType.text,
    };

    let merchantNameAr = {
      columId: MerchantMiniObjectNameId.MERCHANT_NAME_Ar,
      name: "Merchant - Arabic",
      filterType: ColumnFilterType.text,
    };

    let merchantNameEn = {
      columId: MerchantMiniObjectNameId.MERCHANT_NAME_EN,
      name: "Merchant - English",
      filterType: ColumnFilterType.text,
    };



    let nameEn = {
      columId: ClaimNameId.NAME,
      name: "Name",
      filterType: ColumnFilterType.text,
    };

    let email = {
      columId: ClaimNameId.EMAIL,
      name: "Email",
      filterType: ColumnFilterType.text,
    };

    let countryCode = {
      columId: ClaimNameId.COUNTRY_CODE,
      name: "Country Code",
      filterType: ColumnFilterType.text,
    };

    let phoneNumber =
      {
        columId: ClaimNameId.PHONE_NUMBER,
        name: "Phone Number",
        filterType: ColumnFilterType.text,
      };


    let createdAtItem = {
      columId: ClaimNameId.CREATED_AT,
      name: "Created",
      filterType: ColumnFilterType.dateRang,
    };

    let statusItem = {
      columId: ClaimNameId.STATUS,
      name: "Status",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionStatus,
    };


    return [
      idItem,
      merchantId,
      slug,
      merchantNameAr,
      merchantNameEn,
      nameEn,
      email,
      countryCode,
      phoneNumber,
      createdAtItem,
      statusItem,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
   if (id == MerchantMiniObjectNameId.MERCHANT_OBJECT_ID) {
      this.onTapMerchantDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let claim = item as Claim;
    let value = claim[id as keyof Claim];
    let styleClass = null;
    if (id == MerchantMiniObjectNameId.MERCHANT_OBJECT_ID) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    } else if (id == ClaimNameId.STATUS) {
      value = this.statusForClaim(claim)
    } else if(id == MerchantMiniObjectNameId.MERCHANT_OBJECT_ID){
      value = claim.merchant.id
    }
    else if(id == MerchantMiniObjectNameId.MERCHANT_SLUG){
      value = claim.merchant.slug
    }
    else if(id == MerchantMiniObjectNameId.MERCHANT_NAME_EN){
      value = claim.merchant.nameEn
    }
    else if(id == MerchantMiniObjectNameId.MERCHANT_NAME_Ar){
      value = claim.merchant.nameAr
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
  }

  reloadPage() {
    this.reloadDataSource(this.filterData)
  }

  private reloadDataSource(filterData?: FilterTable | null) {
    let filter = new ClaimFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    this.claimApi.getList(filter).subscribe({
      next: (response: ListClaimResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private statusForClaim(claim: Claim): string {
    let isApproved = claim.isApproved ?? false;
    let isRejected = claim.isRejected  ?? false;
    let status = ""
    if (isApproved) {
      status = status + "IsApproved"
    }
    if (isRejected) {
      status = status + "Rejected"
    }
    return status
  }

  private onTapAction<T>(action: any, item: T) {
    let claimAction = action as TableAction;
    let claim = item as Claim;
    let call: Observable<any>;

    if (claimAction == TableAction.APPROVE) {
      call = this.claimApi.approve({ claim: claim });
    } else if (claimAction == TableAction.REJECT) {
      call = this.claimApi.reject({ claim: claim });
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadDataSource(null);
      },
      error: () => {
        this.isScreenLoading = false;
      },
    });
  }

  private onTapMerchantDetails<T>(item: T) {
    let claim = item as Claim;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantDetails,
      id: claim.merchantId,
    });
  }

  private reloadTable(response: ListClaimResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.filterData = filterData;
    this.reloadDataSource(filterData);
  }
}

<!-- Country -->
<drop-down-list (onSearchChanged)="onSearchChanged($event)"
                (onSelected)="onInputChange($event)"
                [allowEditing]=true
                [connectToServer]=false
                [errorHandling]="errorHandling"
                [group]="group"
                [isLoading]="isLoading"
                [loadInputData]="loadInputData"
                [validators]="validators"
                floatingLabel="Currency"
                id="{{id}}">
</drop-down-list>


<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">Merchant</a></li>
  </ol>
</nav>

<h1 class="h3 mb-3"><strong>Add</strong> Area</h1>


<div class="fields-layout">

  <!-- Country-search-text-field -->
  <country-search-text-field (onchange)="onSelectCountry($event)"
                             [errorHandling]="errorHandling"
                             [group]="form"
                             [id]="CountryNameId.ISO3_LETTER"
                             [validators]="[Validators.required]"
                             class="input-field-layout">
  </country-search-text-field>


  <!-- city-search-text-field -->
  <city-search-text-field (onchange)="onSelectCity($event)"
                          *ngIf="isCityAvailable()"
                          [errorHandling]="errorHandling"
                          [group]="form"
                          [validators]="[Validators.required]"
                          [id]="'city_id'"
                          class="input-field-layout">
  </city-search-text-field>

  <!-- area-search-text-field -->
  <area-search-text-field *ngIf="isAreaAvailable()"
                          [errorHandling]="errorHandling"
                          [group]="form"
                          [id]="AreaNameId.CITY_ID"
                          [validators]="[Validators.required]"
                          class="input-field-layout">
  </area-search-text-field>


</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>







<div  class="list-layout">
  <div class="header-action-item">
    <button class="btn btn-secondary" (click)="navigateToCreateNewItem()">
      <i class="bi bi-plus"></i>
    </button>
  </div>

  <table-view
    [isLoading] = isScreenLoading
    [tableConfiguration] = "tableConfiguration">
  </table-view>

  <div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
       role="alert">
    {{ errorHandling.errorMessage }}
  </div>
</div>



import { Component } from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../components/table-view/table-view.component";
import {Country} from "../../business/interfaces/Country";
import {BehaviorSubject, Observable} from "rxjs";
import {CountryAPI} from "../../business/apis/CountryAPI";
import {TableAction} from "../../components/table-view/enum/TableAction";
import {CountryNameId} from "../../business/name-id/CountryNameId";
import {format} from "date-fns";
import {CountryFilterData} from "../../business/classes/CountryFilterData";
import {FilterMetaData} from "../../common/classes/FilterMetaData";
import {ListCountriesResponse} from "../../business/interfaces/response-pagination/ListCountriesResponse";
import { ErrorHandling } from "../../common/classes/ErrorHandling";


@Component({
  selector: 'app-list-countries',
  templateUrl: './list-countries.component.html',
  styleUrls: ['./list-countries.component.scss']
})
export class ListCountriesComponent {
  static navigatorName: string = NavigatorTarget.countries.valueOf();

  tableConfiguration: TableConfiguration<Country> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Country[] = [];
  isScreenLoading = false;

  constructor(private countriesAPI: CountryAPI, private appNavigator: AppNavigator) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let countries = item as Country;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let detailsItem = {
      title: "Details",
      type: "btn btn-primary",
      iconClass: "bi bi-info-circle",
      actionType: TableAction.DETAILS,
      onTapAction: this.onTapAction.bind(this),
    };

    let actionItems: [boolean, ActionRow][] = [
      [true, detailsItem],
      [true, deleteItem],
    ];



    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {
    let iSO3Item = {
      columId: CountryNameId.ISO3_LETTER,
      name: "iso3letter",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };


    let iSO2Item = {
      columId: CountryNameId.ISO2_LETTER,
      name: "iso2letter",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };


    let nameEn = {
      columId: CountryNameId.NAME_EN,
      name: "Name - English",
      filterType: ColumnFilterType.text,
    };

    let nameAr = {
      columId: CountryNameId.NAME_AR,
      name: "Name - Arabic",
      filterType: ColumnFilterType.text,
    };


    let createdAtItem = {
      columId: CountryNameId.CREATED_AT,
      name: "Date",
      filterType: ColumnFilterType.dateRang,
    };

    let countryCode = {
      columId: CountryNameId.COUNTRY_CODE,
      name: "Country - Code",
      filterType: ColumnFilterType.text,
    };

    return [iSO3Item , iSO2Item , nameEn, nameAr, countryCode, createdAtItem];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == CountryNameId.ISO3_LETTER) {
      this.onTapCountryDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let countries = item as Country;
    let value = countries[id as keyof Country];
    let styleClass = null;
    if (id == CountryNameId.ISO3_LETTER) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({ target: NavigatorTarget.countryDetails });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new CountryFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;

    this.countriesAPI.getList(filter).subscribe({
      next: (response: ListCountriesResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let countriesAction = action as TableAction;
    let country = item as Country;
    let call: Observable<any>;

    if (countriesAction == TableAction.REMOVE) {
      call = this.countriesAPI.delete({ country: country });
    } else if (countriesAction == TableAction.DETAILS) {
      this.onTapCountryDetails(item)
      return;
    }else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        let filter = new CountryFilterData();
        this.reloadDataSource(null);
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        ErrorHandling.showError({message:"Can't Delete please check Data."})
      },
    });
  }

  private onTapCountryDetails<T>(item: T) {
    let country = item as Country;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.countryDetails,
      id: country.iso3letter,
    });
  }

  private reloadTable(response: ListCountriesResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CategoryAPI} from "../../../../business/apis/CategoryAPI";
import {BehaviorSubject} from "rxjs";
import {Category} from "../../../../business/interfaces/Category";
import {CategoryFilterData} from "../../../../business/classes/CategoryFilterData";
import {
  ColumnFilterType, SortDirection,
} from "../../../../components/table-view/table-view.component";
import {CategoryNameId} from "../../../../business/name-id/CategoryNameId";
import {ListCategoriesResponse} from "../../../../business/interfaces/response-pagination/ListCategoriesResponse";
import {OptionSelect} from "../../../../components/filters/enum/filter-type";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {FormGroup, ValidatorFn} from "@angular/forms";
import {isString} from "../../../../common/utilities/General";

@Component({
  selector: 'category-search-text-field',
  templateUrl: './category-search-text-field.component.html',
  styleUrls: ['./category-search-text-field.component.css']
})
export class CategorySearchTextFieldComponent {

  @Input() loadData?: BehaviorSubject<any>;
  @Input() validators: ValidatorFn[] = [];
  @Input() id!: string;
  @Input() errorHandling: ErrorHandling | null = null;
  @Input() group!: FormGroup;

  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

  loadInputData: BehaviorSubject<[any, any]>;
  listData: Category[] = [];
  selected: Category | null = null;

  isLoading = false

  constructor(
    private categoryApi: CategoryAPI,
  ) {
    this.loadInputData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    this.loadData?.subscribe((data) => {
      if (isString(data)) {
        this.getCategoryById(data)
      }
    });
    this.reloadDataSource("");
  }

  ngOnDestroy(): void {

  }

  updateCategorySelected(data:any){
    if (isString(data)) {
      this.getCategoryById(data)
    }
  }

  getCategoryById(id: string){
    if(id.hasActualValue()){
      this.categoryApi
        .getDetails({categoryId: id})
        .subscribe({
          next: (item: Category) => {
            this.selected = item
            this.reloadView()
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
          },
        });
    }
  }

  reloadDataSource(search: string) {
    let columnInfo = {
      columId: CategoryNameId.NAME_EN,
      name: "",
      filterType: ColumnFilterType.noun,
    }

    let sortField = {
      tableColumnInfo: columnInfo,
      direction: SortDirection.desc,
    }

    let filterMetaData = {
      itemsPerPage: 1000,
      currentPage: 1,
    }

    let filterFieldTable = {
      tableColumnInfo: columnInfo,
      value: search
    }

    let filter = new CategoryFilterData();
    filter.filterFieldTable = search.hasActualValue() ? [filterFieldTable] : [];
    filter.filterMetaData = filterMetaData;
    filter.sortField = sortField;

    this.isLoading = true;

    this.categoryApi.getList(filter).subscribe({
      next: (response: ListCategoriesResponse) => {
        this.isLoading = false;
        this.listData = response.data;
        this.reloadView();
      },
      error: (error: any) => {
        // Handle error
        this.isLoading = false;
        console.error("Error:", error);
      },
    });
  }

  onInputChange(event: any): void {
    const selectedValue = event as OptionSelect;
    let value = selectedValue.value
    this.selected = value
    this.onchange.emit(value);
  }

  onSearchChanged(event: any): void {
    // const text = (event.target as HTMLSelectElement).value;
    this.reloadDataSource(event)

  }

  private reloadView() {
    let optionSelected : OptionSelect | null = null
    let list: OptionSelect[] = this.listData.map(item => {
      return {
        value: item,
        label: item.nameEn,
      };
    });

    if(this.selected){
      optionSelected = {
        value: this.selected,
        label: this.selected.nameEn,

      }

      setTimeout(() => {
        this.onchange.emit(this.selected);
      }, 200);
    }
    this.loadInputData?.next([list, optionSelected]);
  }

}

export enum UserNameId {
  ID = 'id',
  NAME = 'name',
  EMAIL = 'email',
  COUNTRY_CODE = "countryCode",
  PHONE_NUMBER = "phoneNumber",
  BIRTHDATE = "birthDate",
  GENDER = "gender",
  EMAIL_VERIFIED = "emailVerified",
  PASSWORD = "password",
  IS_ADMIN = "isAdmin",
  MERCHANT_ID = 'merchantId',
  IS_VERIFIED = "isVerified",
  IS_BLOCKED = 'isBlocked',
  IS_DELETED = 'isDeleted',
  CREATED_AT = 'createdAt',

}


import { Component, ViewChild } from "@angular/core";
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { FormGroup, Validators } from "@angular/forms";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { Website } from "../../business/interfaces/Website";
import { InputField } from "../interfaces/InputField";
import { WebsiteNameId } from "../../business/name-id/WebsiteNameId";
import { ActivatedRoute } from "@angular/router";
import { MerchantAPI } from "../../business/apis/MerchantAPI";
import { getEnumFromValue } from "../../common/utilities/General";
import { isFormSubmitted } from "../../common/utilities/FormsHelper";
import { InputFieldType } from "../../components/input/enum/InputFieldType";
import { BehaviorSubject } from "rxjs";
import { UrlValidator } from "../../components/input/validators/url-validator";

@Component({
  selector: "app-merchant-website-details",
  templateUrl: "./merchant-website-details.component.html",
  styleUrls: ["./merchant-website-details.component.css"],
})
export class MerchantWebsiteDetailsComponent {
  static navigatorName: string =
    NavigatorTarget.merchantWebsiteDetails.valueOf();

  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  website: Website | null = null;
  merchantId: string = "";
  websiteId: string = "";

  websiteFields: InputField[] = [];
  urlItem!: InputField;

  // Enum
  validators = Validators;

  constructor(
    private activatedRoute: ActivatedRoute,
    private merchantApi: MerchantAPI,
    private appNavigator: AppNavigator
  ) {}

  ngOnInit(): void {
    this.websiteFields = this.getInputFields();

    this.activatedRoute.params.subscribe((parameters) => {
      this.merchantId = parameters["merchantId"] ?? "";
      this.websiteId = parameters["id"] ?? "";
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  getInputFieldById(id: WebsiteNameId): InputField | undefined {
    let item = this.websiteFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(WebsiteNameId, id) as WebsiteNameId;
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(WebsiteNameId, id) as WebsiteNameId;
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.saveMerchantWebsite();
    }
  }

  saveMerchantWebsite() {
    this.restError();
    this.isLoading = true;
    let merchantId = this.merchantId ?? "";

    let website = {
      id: this.websiteId,
      url: this.getValueFieldById(WebsiteNameId.URL)?.toString(),
    } as Website;

    this.merchantApi
      .addWebsite({
        merchantId: merchantId,
        website: website,
      })
      .subscribe({
        next: (item) => {
          this.navigateBack();
        },
        error: (error: any) => {
          this.checkError(error);
          this.isLoading = false;
        },
      });
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {}

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  private getInputFields(): InputField[] {
    this.urlItem = {
      id: WebsiteNameId.URL,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Url",
      value: "",
      validators: [Validators.required, new UrlValidator().validator],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    return [this.urlItem];
  }
}

import { Component, ViewChild } from "@angular/core";
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { FormGroup, Validators } from "@angular/forms";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { Phone } from "../../business/interfaces/Phone";
import { InputField } from "../interfaces/InputField";
import { ActivatedRoute } from "@angular/router";
import { MerchantAPI } from "../../business/apis/MerchantAPI";
import { PhoneNameId } from "../../business/name-id/PhoneNameId";
import { InputFieldType } from "../../components/input/enum/InputFieldType";
import { BehaviorSubject } from "rxjs";
import { getEnumFromValue } from "../../common/utilities/General";
import { isFormSubmitted } from "../../common/utilities/FormsHelper";
import { MobileNumberComponent } from "../../components/input/mobile-number/mobile-number.component";

@Component({
  selector: "app-merchant-phone-details",
  templateUrl: "./merchant-phone-details.component.html",
  styleUrls: ["./merchant-phone-details.component.css"],
})
export class MerchantPhoneDetailsComponent {
  static navigatorName: string = NavigatorTarget.merchantPhoneDetails.valueOf();

  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  phone: Phone | null = null;

  merchantId: string = "";
  phoneId: string = "";

  phoneFields: InputField[] = [];
  hasTelegramItem!: InputField;
  hasWhatsApp!: InputField;

  @ViewChild(MobileNumberComponent)
  mobileNumberField?: MobileNumberComponent;

  // Enum
  phoneNameId = PhoneNameId;
  Validators = Validators;

  constructor(
    private activatedRoute: ActivatedRoute,
    private merchantApi: MerchantAPI,
    private appNavigator: AppNavigator
  ) {}

  ngOnInit(): void {
    this.phoneFields = this.getInputFields();

    this.activatedRoute.params.subscribe((parameters) => {
      this.merchantId = parameters["merchantId"] ?? "";
      this.phoneId = parameters["id"] ?? "";
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setDefaultPhone();
  }

  setDefaultPhone() {
    this.mobileNumberField?.updateCountrySelected("Egy");
  }

  getInputFieldById(id: PhoneNameId): InputField | undefined {
    let item = this.phoneFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(PhoneNameId, id) as PhoneNameId;
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(PhoneNameId, id) as PhoneNameId;
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.saveMerchantPhone();
    }
  }

  saveMerchantPhone() {
    this.restError();
    this.isLoading = true;
    let merchantId = this.merchantId ?? "";
    let countryCode =
      this.mobileNumberField?.selectedCountry?.countryCode ?? "";
    let phoneNumber = this.mobileNumberField?.mobileNumber ?? "";

    let phone = {
      id: this.phoneId,
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      hasWhatsapp: this.getValueFieldById(
        PhoneNameId.HAS_WHATSAPP
      )?.toBoolNumber(),
      hasTelegram: this.getValueFieldById(
        PhoneNameId.HAS_TELEGRAM
      )?.toBoolNumber(),
    } as Phone;

    this.merchantApi
      .addPhone({
        merchantId: merchantId,
        phone: phone,
      })
      .subscribe({
        next: (item) => {
          this.navigateBack();
        },
        error: (error: any) => {
          this.checkError(error);
          this.isLoading = false;
        },
      });
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {}

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  private getInputFields(): InputField[] {
    this.hasTelegramItem = {
      id: PhoneNameId.HAS_TELEGRAM,
      inputFieldType: InputFieldType.checkBox,
      label: "Has Telegram",
      value: false,
      validators: [],
      loadData: new BehaviorSubject<any>(false),
    } as InputField;

    this.hasWhatsApp = {
      id: PhoneNameId.HAS_WHATSAPP,
      inputFieldType: InputFieldType.checkBox,
      label: "Has WhatsApp",
      value: false,
      validators: [],
      loadData: new BehaviorSubject<any>(false),
    } as InputField;

    return [this.hasTelegramItem, this.hasWhatsApp];
  }
}

import { Component } from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../components/table-view/table-view.component";
import {BehaviorSubject, Observable} from "rxjs";
import {TableAction} from "../../components/table-view/enum/TableAction";
import {format} from "date-fns";
import {CurrencyFilterData} from "../../business/classes/CurrencyFilterData";
import {FilterMetaData} from "../../common/classes/FilterMetaData";
import {Currency} from "../../business/interfaces/Currency";
import {CurrencyAPI} from "../../business/apis/CurrencyAPI";
import {CurrencyNameId} from "../../business/name-id/CurrencyNameId";
import { ErrorHandling } from "../../common/classes/ErrorHandling";

@Component({
  selector: 'app-list-currencies',
  templateUrl: './list-currencies.component.html',
  styleUrls: ['./list-currencies.component.scss']
})
export class ListCurrenciesComponent {

  static navigatorName: string = NavigatorTarget.currencies.valueOf();

  tableConfiguration: TableConfiguration<Currency> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Currency[] = [];
  isScreenLoading = false;

  constructor(private currencyAPI: CurrencyAPI, private appNavigator: AppNavigator) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {


    this.tableConfiguration = {
      // pagePagination: pagePagination,
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let currencies = item as Currency;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let actionItems: [boolean, ActionRow][] = [[true, deleteItem]];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {
    let iSO3Item = {
      columId: CurrencyNameId.ISO3_LETTER,
      name: "iso3letter",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };


    return [iSO3Item];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == CurrencyNameId.ISO3_LETTER) {
      this.onTapCurrencyDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let currencies = item as Currency;
    let value = currencies[id as keyof Currency];
    let styleClass = null;
    if (id == CurrencyNameId.ISO3_LETTER) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({ target: NavigatorTarget.currencyDetails });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new CurrencyFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;

    this.currencyAPI.getList(filter).subscribe({
      next: (response) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let currenciesAction = action as TableAction;
    let currency = item as Currency;
    let call: Observable<any>;

    if (currenciesAction == TableAction.REMOVE) {
      call = this.currencyAPI.delete({ currency: currency });
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        let filter = new CurrencyFilterData();
        this.reloadDataSource(null);
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        ErrorHandling.showError({message:"Can't Delete please check Data."})
      },
    });
  }

  private onTapCurrencyDetails<T>(item: T) {
    let currency = item as Currency;
  }

  private reloadTable(response: Currency[]) {
    this.listData = response;
    this.tableConfiguration.loadData?.next([this.listData, null]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}


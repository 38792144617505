<div  class="list-layout">

  <div class="fields-layout">

    <!-- urlItemItem -->
    <input-text-field (onchange)="updateInputFieldById(urlItem.id.toString(), $event)"
                      [errorHandling]="errorHandling"
                      [floatingLabel]="urlItem.floatingLabel"
                      [group]="form"
                      [id]="urlItem.id.toString()"
                      [inputType]="urlItem.type"
                      [loadData]="urlItem.loadData"
                      [validators]="urlItem.validators"
                      class="input-field-layout"
    ></input-text-field>


  </div>

  <div class="action-button">
    <primary-button
      (onClick)="submit()"
      [loading]="isLoading"
      [title]="'Add'">
    </primary-button>
  </div>

  <div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
       role="alert">
    {{ errorHandling.errorMessage }}
  </div>

  <table-view
    [isLoading] = isScreenLoading
    [tableConfiguration] = "tableConfiguration">
  </table-view>



</div>


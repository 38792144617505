export enum ResponseStatusCode {
  OK = 200,
  CREATED = 201,
  ACCEPTED = 202,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOTFOUND = 404,
  VALIDATION_ERROR= 422,
  INTERNAL_SERVER = 500,
  NOT_IMPLEMENTED = 501,
  SERVICE_UNAVAILABLE = 503,
  TIMEOUT = 504,
  UNKNOWN= 1000,
}


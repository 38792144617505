import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { NameValidationError } from "../validators/name-validator";
import { ErrorHandling } from "../../../common/classes/ErrorHandling";

@Component({
  selector: "text-area",
  templateUrl: "./text-area.component.html",
  styleUrls: ["./text-area.component.scss"],
})
export class TextAreaComponent {
  @Input() loadData?: BehaviorSubject<any>;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

  inputValue: string = "";

  @Input() errorHandling: ErrorHandling | null = null;
  @Input() floatingLabel?: string = "";
  @Input() id!: string;
  @Input() group!: FormGroup;
  @Input() validators: ValidatorFn[] = [];
  control: FormControl = new FormControl();
  gainedFocus: boolean = false;

  constructor() {}

  get errorMessage(): string {
    if (this.control.hasError(NameValidationError.tooShort)) {
      return `${this.floatingLabel} is too short`;
    } else if (this.control.hasError(NameValidationError.tooLong)) {
      return `${this.floatingLabel} is too long`;
    } else if (this.control.hasError(NameValidationError.invalidFormat)) {
      return `${this.floatingLabel} is invalidFormat`;
    } else if (this.control.hasError("required") && this.gainedFocus) {
      return `${this.floatingLabel} is required`;
    } else if (this.errorHandling) {
      return this.getErrorMessage();
    } else {
      return "";
    }
  }

  ngOnInit(): void {
    let validators = [];
    validators.push(...this.validators);
    this.control.setValidators(validators);
    this.group.addControl(this.id, this.control);

    this.loadData?.subscribe((data) => {
      this.inputValue = data;
      this.updateViews(data);
    });
  }

  isInputValid(): string {
    if (
      (this.control.hasError("required") ||
        this.control.hasError(NameValidationError.tooShort) ||
        this.control.hasError(NameValidationError.tooLong) ||
        this.control.hasError(NameValidationError.invalidFormat)) &&
      this.errorMessage.hasActualValue()
    ) {
      return "is-invalid";
    } else {
      return "";
    }
  }

  onFocused() {
    this.gainedFocus = true;
  }

  ngOnDestroy(): void {
    this.group?.removeControl(this.id);
  }

  onInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.updateViews(value);
  }

  updateViews(value: any) {
    this.onchange?.emit(value);
  }

  getErrorMessage() {
    let id = this.id ?? "";
    let title = this.floatingLabel ?? "";
    return this.errorHandling?.getValidationErrorMessage(id, title) ?? "";
  }
}

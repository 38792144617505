<div *ngIf="isLoading" class="overlay">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
<div class="main-table-layout">
  <div class="table-view-layOut">
    <div class="table-responsive datatable-layout">
      <table class="table table-striped">
        <thead>
        <tr>
          <th (click)="sort(column)" *ngFor="let column of listTableColumInfo">
            {{ column.name }}
            <span *ngIf="column == sortFieldTable?.tableColumnInfo &&  tableConfiguration?.hasFilter ?? true">
                  <i
                    [ngClass]="sortFieldTable?.direction == SortDirection.asc ? 'bi bi-sort-up' : 'bi bi-sort-down'"></i>
                  </span>
          </th>
        </tr>
        <tr *ngIf="tableConfiguration?.hasFilter ?? true">
          <th *ngFor="let column of listTableColumInfo">
            <ng-container *ngIf="column.filterType == ColumnFilterType.text">
              <input (keyup.enter)="applyFilter($event, column)" class="form-control" placeholder="" type="text">
            </ng-container>
            <ng-container *ngIf="column.filterType == ColumnFilterType.dateRang">
              <date-range (onSelect)="applyFilter($event, column)"></date-range>
            </ng-container>
            <ng-container *ngIf="column.filterType == ColumnFilterType.number">
              <number-input (onSelect)="applyFilter($event, column)"></number-input>
            </ng-container>

            <ng-container *ngIf="column.filterType == ColumnFilterType.select">
              <select-filter (onSelect)="applyFilter($event, column)"
                             [list]="selectStatusOption(column)">
              </select-filter>
            </ng-container>

          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of  pagedRows ; let i = index">
          <td *ngFor="let columnInfo of listTableColumInfo">
            <div (click)="handleClickCell(columnInfo,item)" [class]="getValueColumn(columnInfo, item).class"
                 [style]="getValueColumn(columnInfo, item).style">
              {{ getValueColumn(columnInfo, item).value }}
            </div>
          </td>
          <td>
            <!--          <td class="float-column">-->
            <div class="text-end" style="white-space: nowrap;">
              <ng-container *ngFor="let action of getRowAction(item) ">
                <button (click)="handleClick(action, item)" [class]="action.type"
                        class="action-row"
                        style="margin-right: 10px; width: 40px; padding: 5px;" type="button">
                  <i [class]="action.iconClass"></i>
                  <div class="action-title">{{action.title}}</div>
                </button>
              </ng-container>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="tableConfiguration?.hasFilter ?? true" class="pagination-layout">
    <div *ngIf="tableConfiguration.pagePagination?.show" class="pagination-number">
      <label for="itemsPerPageSelect">Items per page:</label>
      <select
        (ngModelChange)="onItemsPerPageChange($event)"
        [(ngModel)]="itemsPerPage"
        class="custom-select"
        id="itemsPerPageSelect"
        style="height: 32px;"
      >
        <option *ngFor="let option of this.tableConfiguration?.pagePagination?.list ?? []"
                [value]="option">{{ option }}</option>
        <!--        <option value="all">All</option>-->
      </select>
    </div>


    <div *ngIf="pages.length > 1">
      <nav>
        <ul class="pagination">
          <li class="page-item"><a (click)="goToPreviousPage()" class="page-link">Previous</a></li>
          <ng-container *ngFor="let page of pages">
            <li [class.active]="currentPage === page" class="page-item">
              <a (click)="goToPage(page)" class="page-link">{{ page }}</a>
            </li>
          </ng-container>
          <li class="page-item"><a (click)="goToNextPage()" class="page-link">Next</a></li>
        </ul>
      </nav>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import {NavigationItem} from "../sidebar/sidebar.component";

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.css']
})
export class MasterComponent {
  selectedItem: NavigationItem= NavigationItem.DASHBOARD;
}

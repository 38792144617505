export class FileUpload {
  id: string = "";
  key: string = "";
  public name: string = '';
  public localURL: string | ArrayBuffer | null = null
  public remoteURL: string = '';
  file?: File | null ;
  public uploading: boolean;

  constructor(file: File | null) {
    this.file = file;
    this.uploading = false;
  }


}

<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">List Categorys</a></li>
    <li *ngIf="categoryDetails" aria-current="page" class="breadcrumb-item active">{{categoryDetails.nameEn}}</li>
    <li *ngIf="!categoryDetails" aria-current="page" class="breadcrumb-item active">Add</li>
  </ol>
</nav>

<!-- Show this title only if myObject is null -->
<h1 *ngIf="!categoryDetails" class="h3 mb-3"><strong>Add</strong> Category</h1>

<!-- Show this title only if myObject exists (not null) -->
<h1 *ngIf="categoryDetails" class="h3 mb-3"><strong>Update</strong> Category</h1>


<div class="fields-layout">

  <!-- nameEnglishItem -->
  <input-text-field (onchange)="updateInputFieldById(nameEnglishItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameEnglishItem.floatingLabel"
                    [group]="form"
                    [id]="nameEnglishItem.id.toString()"
                    [inputType]="nameEnglishItem.type"
                    [loadData]="nameEnglishItem.loadData"
                    [validators]="nameEnglishItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- nameArabicItem -->
  <input-text-field (onchange)="updateInputFieldById(nameArabicItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameArabicItem.floatingLabel"
                    [group]="form"
                    [id]="nameArabicItem.id.toString()"
                    [inputType]="nameArabicItem.type"
                    [loadData]="nameArabicItem.loadData"
                    [validators]="nameArabicItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- descriptionEnglishItem -->
  <text-area (onchange)="updateInputFieldById(descriptionEnglishItem.id.toString(), $event)"
             [errorHandling]="errorHandling"
             [floatingLabel]="descriptionEnglishItem.floatingLabel"
             [group]="form"
             [id]="descriptionEnglishItem.id.toString()"
             [loadData]="descriptionEnglishItem.loadData"
             [validators]="descriptionEnglishItem.validators"
             class="input-field-layout"
  ></text-area>

  <!-- descriptionArabicItem -->
  <text-area (onchange)="updateInputFieldById(descriptionArabicItem.id.toString(), $event)"
             [errorHandling]="errorHandling"
             [floatingLabel]="descriptionArabicItem.floatingLabel"
             [group]="form"
             [id]="descriptionArabicItem.id.toString()"
             [loadData]="descriptionArabicItem.loadData"
             [validators]="descriptionArabicItem.validators"
             class="input-field-layout"
  ></text-area>

</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="categoryDetails ? 'Update' : 'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>







<!-- City -->
<drop-down-list (onSelected)="onInputChange($event)"
                (onSearchChanged)="onSearchChanged($event)"
                [errorHandling]="errorHandling"
                floatingLabel="City"
                [group]="group"
                id= "{{id}}"
                [isLoading]="isLoading"
                [validators]="validators"
                [allowEditing] = true
                [connectToServer] = true>
</drop-down-list>


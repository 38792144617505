import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FileUpload} from "../../business/interfaces/FileUpload";
import {FirestoreService} from "../../services/firestore.service";


@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {

  @Input() loadData?: BehaviorSubject<any>;
  @Input() loadMultiImages?: BehaviorSubject<any>;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>()


  @ViewChild("fileDropRef", {static: false}) fileDropEl!: ElementRef;
  @Input() allowMulti!: boolean
  @Input() onlyAcceptImages!: boolean
  @Input() onlyAcceptPdf!: boolean
  @Input() maxNumberOfFiles!: number
  @Input() maxFileSizeBytes!: number
  @Input() uploadFilePath!: string

  @Output() fileUploaded = new EventEmitter<any>();
  @Output() fileRemoved = new EventEmitter<any>();
  @Output() updateCurrentNumberOfFiles = new EventEmitter<number>();


  percentage = 0;
  errorMessage: string = "";
  fileUploads: FileUpload[] = [];


  constructor(private uploadService: FirestoreService) {


  }

  ngOnInit(): void {
    this.loadData?.subscribe(data => {
      let items: FileUpload[] = [];
      if (data instanceof Array && data.every(upload => upload instanceof String)) {
        items = this.mapToFileUpLoadList(data)
      } else if (typeof data === 'string') {
        items = this.mapToFileUpLoadList([data])
      }
      this.updateViews(items)
      this.fileUploads = items
    })

    this.loadMultiImages?.subscribe(data => {
      this.fileUploads = data
    })

  }

  mapToFileUpLoadList(list: string[]): FileUpload[] {
    let valueReturn = list.map(item => this.mapToFileUpload(item));
    return valueReturn;
  }

  mapToFileUpload(url: string): FileUpload {
    let file = new FileUpload(null);
    file.uploading = false
    file.remoteURL = url
    file.localURL = url
    return file;
  }


  fileSelected(event: any) {
    console.log("fileSelected")
    console.log(event)
    if (event.target != null) {
      console.log(event.target)
      if (event.target.files != null && event.target.files.length > 0) {
        console.log(event.target.files);
        console.log(event.target.files[0].name);

        this.uploadFile(event.target.files[0]);
      }
    }
  }

  uploadFile(file: any): void {
    console.log("Uploading file");
    console.log(file)

    if (this.allowMulti && this.fileUploads.length >= this.maxNumberOfFiles) {
      this.errorMessage = "Maximum number of file uploads reached."
      return
    }

    if (file) {
      if (file.size > this.maxFileSizeBytes) {
        this.errorMessage = "Files size exceeds maximum file size allowed of " + (this.maxFileSizeBytes / 1024 / 1024) + " MB"
        return
      }

      if (this.onlyAcceptImages && !file.type.startsWith('image/')) {
        this.errorMessage = "Please upload images only."
        return
      }

      this.errorMessage = ""

      const fileUpload = new FileUpload(file)
      console.log(fileUpload.file)
      fileUpload.name = fileUpload.file?.name ?? "";
      console.log("File Name = " + fileUpload.file?.name)
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = (_event) => {
        fileUpload.localURL = reader.result;
      }

      console.log("url = " + fileUpload.localURL)
      fileUpload.uploading = true

      if (!this.allowMulti) {
        this.deleteFile(0)
      }

      this.updateCurrentNumberOfFiles.emit(this.fileUploads.length)

      this.fileUploads.push(fileUpload)
      fileUpload.remoteURL = ""
      this.updateViews([fileUpload])
      fileUpload.uploading = false;
      console.log("completed")

      if (this.allowMulti) {
        this.fileUploaded.emit(fileUpload);
      } else {
        this.fileUploaded.emit(fileUpload);
      }


      // this.uploadService.pushFileToStorage(fileUpload, this.uploadFilePath).subscribe({
      //   next: percentage => {
      //     console.log("percentage = ")
      //     console.log(percentage)
      //     if (typeof percentage === 'number') {
      //       fileUpload.uploading = true;
      //       this.percentage = Math.round(percentage ? percentage : 0);
      //     } else {
      //       fileUpload.remoteURL = percentage
      //       this.updateViews([fileUpload])
      //
      //       fileUpload.uploading = false;
      //       console.log("completed")
      //
      //       if (fileUpload.remoteURL !== "") {
      //         console.log("file upload remote URL")
      //         console.log(fileUpload.remoteURL)
      //         if (this.allowMulti) {
      //           this.fileUploaded.emit(fileUpload);
      //         } else {
      //           this.fileUploaded.emit(fileUpload.remoteURL);
      //         }
      //       } else {
      //         // TODO: There was an error uploading the file.
      //       }
      //     }
      //   },
      //   error: () => {
      //     fileUpload.uploading = false;
      //     let index = this.fileUploads.indexOf(fileUpload)
      //     this.fileUploads.splice(index, 1)
      //     this.updateCurrentNumberOfFiles.emit(this.fileUploads.length)
      //   },
      //   complete: () => {
      //     // Not being called now anymore, for some reason. Code moved to the "next" callback
      //   }
      // });
    }
  }

  // deleteFile(index: number): void {
  //   console.log("Delete Image.. " + index);
  //   console.log("Full array before " + this.fileUploads)
  //   if (this.allowMulti) {
  //     let fileUploads = this.fileUploads[index];
  //     this.fileRemoved.emit(fileUploads)
  //     this.uploadService.deleteFileFromStorage(fileUploads, this.uploadFilePath).subscribe();
  //     // this.fileUploads = this.fileUploads.filter(item => item !== fileUpload);
  //     this.fileUploads.splice(index, 1)
  //     this.updateCurrentNumberOfFiles.emit(this.fileUploads.length)
  //     console.log("Full array after " + this.fileUploads)
  //   } else {
  //     for (var val of this.fileUploads) {
  //       this.fileRemoved.emit(val.remoteURL)
  //
  //       this.uploadService.deleteFileFromStorage(val, this.uploadFilePath).subscribe();
  //     }
  //     this.fileUploads = []
  //   }
  //
  //   this.updateViews(this.fileUploads);
  //   this.errorMessage = ""
  //   // this.url = null;
  // }

  deleteFile(index: number): void {
    console.log("Delete Image.. " + index);
    console.log("Full array before " + this.fileUploads)
    if (this.allowMulti) {
      let fileUploads = this.fileUploads[index];
      this.fileRemoved.emit(fileUploads)
      // this.uploadService.deleteFileFromStorage(fileUploads, this.uploadFilePath).subscribe();
      // this.fileUploads = this.fileUploads.filter(item => item !== fileUpload);
      this.fileUploads.splice(index, 1)
      this.updateCurrentNumberOfFiles.emit(this.fileUploads.length)
      console.log("Full array after " + this.fileUploads)
    } else {
      for (var val of this.fileUploads) {
        this.fileRemoved.emit(val.remoteURL)

        // this.uploadService.deleteFileFromStorage(val, this.uploadFilePath).subscribe();
      }
      this.fileUploads = []
    }

    this.updateViews(this.fileUploads);
    this.errorMessage = ""
    // this.url = null;
  }

  getImageURL(fileUpload: FileUpload) {
    let url =  fileUpload.localURL;
    if(fileUpload.remoteURL.hasActualValue()){
      url =  fileUpload.remoteURL;
    }

    // if(fileUpload.uploading == false){
    //   url =  fileUpload.remoteURL;
    // }
    if(this.isPDF(url)){
      return "assets/img/svg/file-pdf.svg"
    }
    return url
  }

  openURLInNewTab(fileUpload: FileUpload): void {
    let url = fileUpload.remoteURL ?? ""
    if(url.hasActualValue()){
      if(url.isString()){
        window.open(url as string, '_blank');
      }
    }
  }


  isPDF(url:any) {
    if(this.isString(url)){
     return url.includes('.pdf')
    }
    return false
  }

  isString(url:any) {
    return typeof url === 'string';
  }



  onInputChange(event: Event): void {
    // const value = (event.target as HTMLInputElement).value;
    // this.updateViews(value)
  }

  updateViews(value: any) {
    // if (value instanceof Array && value.every(upload => upload instanceof FileUpload)) {
    //   // Assuming FileUpload has a property remoteURL
    //   let listValue: string[] = value.map(upload => upload.remoteURL);
    //
    //   if (this.allowMulti) {
    //     // Emit array of URLs if multi-selection is allowed
    //     this.onchange?.emit(listValue);
    //   } else {
    //     // Emit single URL (first element) if multi-selection is not allowed
    //     let singleValue: string = listValue.length > 0 ? listValue[0] : "";
    //     this.onchange?.emit(singleValue);
    //   }
    // }

    if (value instanceof Array && value.every(upload => upload instanceof FileUpload)) {
      // // Assuming FileUpload has a property remoteURL
      // let listValue: string[] = value.map(upload => upload.remoteURL);

      if (this.allowMulti) {
        // Emit array of URLs if multi-selection is allowed
        this.onchange?.emit(value);
      } else {
        // Emit single URL (first element) if multi-selection is not allowed
        if( value.length > 0){
          this.onchange?.emit(value[0]);
        }else{
          this.onchange?.emit("");
        }
        // let singleValue: string = value.length > 0 ? listValue[0] : "";

      }
    }

  }

}

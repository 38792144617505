export enum SocialMediaPlatformEnum {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  YOUTUBE = "YOUTUBE",
  LINKEDIN = "LINKEDIN",
  TIKTOK = "TIKTOK",
  PINTEREST = "PINTEREST",
  TELEGRAM = "TELEGRAM",
  X = "X"
}


export namespace SocialMediaPlatformHelper {
  export function localize(platform: SocialMediaPlatformEnum): string {
    switch (platform) {
      case SocialMediaPlatformEnum.FACEBOOK:
        return 'Facebook';
      case SocialMediaPlatformEnum.INSTAGRAM:
        return 'Instagram';
      case SocialMediaPlatformEnum.YOUTUBE:
        return 'YouTube';
      case SocialMediaPlatformEnum.LINKEDIN:
        return 'LinkedIn';
      case SocialMediaPlatformEnum.TIKTOK:
        return 'TikTok';
      case SocialMediaPlatformEnum.PINTEREST:
        return 'Pinterest';
      case SocialMediaPlatformEnum.TELEGRAM:
        return 'Telegram';
      case SocialMediaPlatformEnum.X:
        return 'X';
    }
  }
}






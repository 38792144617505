import { Component, Input } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";


export enum NavigationItem {
  DASHBOARD = "dashboard",
  MERCHANTS = "merchants",
  CUSTOMERS = "customers",
  USERS = "users",
  PROFILE = "profile",
  CATEGORIES = "categories",
  CITIES = "cities",
  COUNTRIES = "countries",
  AREAS = "area",
  CURRENCIES = "currencies",
  PRODUCTS = "products",
  CLAIMS = "claims",
  productsRates = "productsRates",
  merchantRates = "merchantRates",

}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})


export class SidebarComponent {
  @Input() selectedItem: NavigationItem | null = NavigationItem.MERCHANTS;
  protected readonly NavigationItem = NavigationItem;


  constructor(private appNavigator: AppNavigator) {
  }

  ngOnInit(): void {
    console.log('Component initialized');
    const cleanedUrl = this.appNavigator.url().replace(/^\/+/, '');
    this.selectedItem = this.getNavigationItem(cleanedUrl);
  }

  getNavigationItem(target: string): NavigationItem {
    const foundItem = Object.values(NavigationItem).find(item => item === target);
    let item = foundItem as NavigationItem ?? this.selectedItem;
    return item;
  }

  navigateTo(item: NavigationItem) {
    this.selectedItem = item;
    if (item == NavigationItem.MERCHANTS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.merchants })
    } else if (item == NavigationItem.PROFILE) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.profile })
    } else if (item == NavigationItem.DASHBOARD) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.dashboard })
    } else if (item == NavigationItem.CATEGORIES) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.categories })
    } else if (item == NavigationItem.CITIES) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.cities })
    } else if (item == NavigationItem.COUNTRIES) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.countries })
    } else if (item == NavigationItem.AREAS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.areas })
    } else if (item == NavigationItem.CURRENCIES) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.currencies })
    } else if (item == NavigationItem.PRODUCTS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.products })
    } else if (item == NavigationItem.USERS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.users })
    } else if (item == NavigationItem.CUSTOMERS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.customers })
    } else if (item == NavigationItem.CLAIMS) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.claims })
    } else if (item == NavigationItem.productsRates) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listProductsRates })
    } else if (item == NavigationItem.merchantRates) {
      this.appNavigator.navigateTo({ target: NavigatorTarget.listMerchantRates })
    }
  }
}

<!-- Show this title only if myObject exists (not null) -->
<h1 *ngIf="userDetails" class="h3 mb-3"><strong>Update</strong> Profile</h1>



<div class="fields-layout">

  <!-- nameItem -->
  <input-text-field (onchange)="updateInputFieldById(nameItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameItem.floatingLabel"
                    [group]="form"
                    [id]="nameItem.id.toString()"
                    [inputType]="nameItem.type"
                    [loadData]="nameItem.loadData"
                    [validators]="nameItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- emailItem -->
  <input-text-field (onchange)="updateInputFieldById(emailItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="emailItem.floatingLabel"
                    [group]="form"
                    [id]="emailItem.id.toString()"
                    [inputType]="emailItem.type"
                    [isReadOnly] = true
                    [loadData]="emailItem.loadData"
                    [validators]="emailItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- mobile-number -->

  <mobile-number [countryCodeId]="userNameId.COUNTRY_CODE"
                 [errorHandling]="errorHandling"
                 [group]="form"
                 [mobileNumberId]="userNameId.PHONE_NUMBER"
                 [validators]=[Validators.required]
                 class="input-field-layout">
  </mobile-number>


  <!-- birthDateItem -->
  <input-date-text-field (onchange)="updateInputFieldById(birthDateItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="birthDateItem.floatingLabel"
                    [group]="form"
                    [id]="birthDateItem.id.toString()"
                    [loadData]="birthDateItem.loadData"
                    [validators]="birthDateItem.validators"
                    class="input-field-layout"
  ></input-date-text-field>


<!--  &lt;!&ndash; passwordItem &ndash;&gt;-->
<!--  <input-text-field (onchange)="updateInputFieldById(passwordItem.id.toString(), $event)"-->
<!--                    *ngIf="!userDetails"-->
<!--                    [errorHandling]="errorHandling"-->
<!--                    [floatingLabel]="passwordItem.floatingLabel"-->
<!--                    [group]="form"-->
<!--                    [id]="passwordItem.id.toString()"-->
<!--                    [inputType]="passwordItem.type"-->
<!--                    [loadData]="passwordItem.loadData"-->
<!--                    [validators]="passwordItem.validators"-->
<!--                    class="input-field-layout"-->
<!--  ></input-text-field>-->


  <!-- genderItem -->
  <drop-down-list (onSelected)="updateInputFieldById(genderItem.id.toString(), $event)"
                  [dataList]=getListProductState()
                  [errorHandling]="errorHandling"
                  [floatingLabel]=genderItem.floatingLabel
                  [group]="form"
                  [id]=genderItem.id
                  [loadSelected]=genderItem.loadData
                  [validators]=genderItem.validators
                  class="input-field-layout"></drop-down-list>

<!--  &lt;!&ndash; merchantIdItem &ndash;&gt;-->
<!--  <input-text-field (onchange)="updateInputFieldById(merchantIdItem.id.toString(), $event)"-->
<!--                    [errorHandling]="errorHandling"-->
<!--                    [floatingLabel]="merchantIdItem.floatingLabel"-->
<!--                    [group]="form"-->
<!--                    [id]="merchantIdItem.id.toString()"-->
<!--                    [inputType]="merchantIdItem.type"-->
<!--                    [loadData]="merchantIdItem.loadData"-->
<!--                    [validators]="merchantIdItem.validators"-->
<!--                    class="input-field-layout"-->
<!--  ></input-text-field>-->

<!--  &lt;!&ndash; isAdminItem &ndash;&gt;-->
<!--  <check-box-->
<!--    (onchange)="updateInputFieldById(isAdminItem.id.toString(), $event)"-->
<!--    [group]="form"-->
<!--    [id]="isAdminItem.id.toString()"-->
<!--    [label]="isAdminItem.label"-->
<!--    [loadData]="isAdminItem.loadData"-->
<!--    [validators]="isAdminItem.validators"></check-box>-->

</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="userDetails ? 'Update' : 'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>







import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { ColumnFilterType, FilterFieldTable, SortFieldTable } from "../../components/table-view/table-view.component";
import { DateRange } from "../../components/filters/date-range-filter.component";
import { mergeObjects } from "../../common/utilities/General";
import { MerchantNameId } from "../interfaces/Merchant";
import { MerchantStatus } from "../enums/MerchantStatus";

export class MerchantFilterData {
  filterMetaData: FilterMetaData | null = null
  filterFieldTable: FilterFieldTable[] = []
  sortField: SortFieldTable | null = null

  static getParameterApi(merchantFilterData: MerchantFilterData,) {
    let metaData = FilterMetaData.getParameterApi(merchantFilterData.filterMetaData!)
    let sortField = FilterMetaData.getParameterSort(merchantFilterData.sortField)
    let filterFields = this.mapFilterFieldsList(merchantFilterData.filterFieldTable)

    const params = mergeObjects(metaData, filterFields,sortField);

    Object.keys(params).forEach(key => {
      return (params[key] === null || params[key] === undefined) && delete params[key]
    });

    return params;
  }

  static mapFilterFieldsList(filterFields: FilterFieldTable[]): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const filterField of filterFields) {
      if (filterField.tableColumnInfo.filterType === ColumnFilterType.dateRang) {
        const dateRange = filterField.value as DateRange;
        const fromKey = `filters[from${filterField.tableColumnInfo.columId}]`;
        const toKey = `filters[to${filterField.tableColumnInfo.columId}]`;
        let dateStart = dateRange.start;
        let dateEnd = dateRange.end;
        result[fromKey] = dateStart.toISOString();
        result[toKey] = dateEnd.toISOString();
      }

     else if (filterField.tableColumnInfo.columId == MerchantNameId.status) {
       let value =  filterField.value as MerchantStatus;
       if(value == MerchantStatus.APPROVED){
         const key = `filters[${MerchantNameId.isApproved}]`;
         result[key] = true;
       }else if(value == MerchantStatus.REJECTED){
         const key = `filters[${MerchantNameId.isRejected}]`;
         result[key] = true;
       }else if(value == MerchantStatus.NO_REJECTED_OR_ACCEPTED){
         const isRejectedKey = `filters[${MerchantNameId.isRejected}]`;
         const isApprovedKey = `filters[${MerchantNameId.isApproved}]`;
         result[isRejectedKey] = false;
         result[isApprovedKey] = false;
       }
      }

      else {
        const key = `filters[${filterField.tableColumnInfo.columId}]`;
        result[key] = filterField.value;
      }
    }

    return result;
  }

}

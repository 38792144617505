<div class="input-text-field-container">
  <div class="form-floating mb-3">

    <input id="{{ id }}"
           type="{{ inputType }}"
           class="form-control"
           [ngClass]="isInputValid()"
           placeholder="{{floatingLabel}}"
           [formControl]="control"
           [(ngModel)]="inputValue"
           (input)="onInputChange($event)"
           [readonly]="isReadOnly"
           [appNumericOnly]="enableNumericOnly"
    >

    <div *ngIf="inputType==='password' || inputType === 'visiblePassword'"
         (click)="changePasswordState()"
         class="password-visibility">
      <i class="far" id="togglePassword" [ngClass]="visibilityIcon()"></i>
    </div>

    <label for="{{ id }}">{{ floatingLabel }}</label>
    <div class="text-danger">{{ errorMessage }}</div>
    <small *ngIf="showEmailHint" class="text-muted">
      emailInputReceivingMessage
<!--      {{locale.emailInputReceivingMessage | tr}}-->
    </small>
  </div>
</div>


export enum AddressNameId {
  ID = 'id',
  ADDRESS_EN = 'addressEn',
  ADDRESS_AR = 'addressAr',
  STREET_EN = "streetEn",
  STREET_AR = "streetAr",
  BUILDING_EN = "buildingEn",
  BUILDING_AR = "buildingAr",
  FLOOR = "floor",
  POST_CODE = "postCode",
  CITY = "city",
  CITY_ID = "cityId",
  CREATED_AT = 'createdAt',
}



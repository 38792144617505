<ng-container>
  <h1 class="h3 mb-3"><strong>List</strong> Categories</h1>
</ng-container>
<div  class="merchant-list-layout">
  <div class="header-action-item">
    <button class="btn btn-secondary" (click)="navigateToCreateNewItem()">
      <i class="bi bi-plus"></i>
    </button>
    <button class="btn btn-primary" onclick="location.reload();">
      <i class="bi bi-arrow-clockwise"></i>
    </button>
  </div>
  <table-view
    [isLoading] = isScreenLoading
    [tableConfiguration] = "tableConfiguration">
  </table-view>
</div>


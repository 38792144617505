<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">Merchant</a></li>
  </ol>
</nav>

<h1 class="h3 mb-3"><strong>Add</strong> Phone</h1>


<div class="fields-layout">

  <!-- mobile-number -->

  <mobile-number [countryCodeId]="phoneNameId.COUNTRY_CODE"
                 [errorHandling]="errorHandling"
                 [group]="form"
                 [mobileNumberId]="phoneNameId.PHONE_NUMBER"
                 [validators]=[Validators.required]
                 class="input-field-layout">
  </mobile-number>


  <!-- hasWhatsApp -->
  <check-box
    (onchange)="updateInputFieldById(hasWhatsApp.id.toString(), $event)"
    [group]="form"
    [id]="hasWhatsApp.id.toString()"
    [label]="hasWhatsApp.label"
    [loadData]="hasWhatsApp.loadData"
    [validators]="hasWhatsApp.validators"></check-box>

  <!-- hasTelegramItem -->
  <check-box
    (onchange)="updateInputFieldById(hasTelegramItem.id.toString(), $event)"
    [group]="form"
    [id]="hasTelegramItem.id.toString()"
    [label]="hasTelegramItem.label"
    [loadData]="hasTelegramItem.loadData"
    [validators]="hasTelegramItem.validators"
  ></check-box>


</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>








import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  private sidebarCollapsed = false;

  toggleSidebar(): void {
    this.sidebarCollapsed = !this.sidebarCollapsed;
    document.getElementById('sidebar')?.classList.toggle('collapsed', this.sidebarCollapsed);
  }
}

import { Component } from '@angular/core';


declare global {
  interface Object {
    toStringValue(): string;

    toBoolNumber(): string;
    hasValue():boolean;
    isString():boolean;
  }
}

// Add the toStringValue method to the Object prototype
Object.prototype.toStringValue = function (this: any | null | undefined): string {
  return this != null ? this.toString() : '';
};

Object.prototype.toBoolNumber = function (this: any): string {
  let value = this;
 let  returnValue = false;
  if (typeof value === 'boolean') {
    // If the value is already a boolean, return it as is
    returnValue = value;
  } else if (typeof value === 'string') {
    // If the value is a string, convert it to boolean
    returnValue = value.toLowerCase() === 'true';
  } else if (typeof value === 'number') {
    // If the value is a number, return true if it's not zero
    returnValue = value !== 0;
  } else {
    // For any other type, return false
    returnValue = false;
  }

  return returnValue ? "1" : "0"
};


Object.prototype.isString = function (this: any): boolean {
  let value = this;
  let  returnValue = false;
  if (typeof value === 'string') {
    // If the value is a string, convert it to boolean
   returnValue =  true;
  }
  return returnValue;
};

Object.prototype.hasValue = function (this: any): boolean {
  let item = this;

  if (typeof item === 'string' && item.trim() === '') {
    // The item is an empty string
    console.log('Item is an empty string');
    item = false;
  } else if (typeof item === 'object' && Object.keys(item).length === 0) {
    // The item is an empty object
    console.log('Item is an empty object');
    item = false;
  } else {
    // The item is neither an empty string nor an empty object
    item = true;
    console.log('Item has a value');
  }
  return item;

};




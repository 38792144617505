import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ErrorHandling} from "../../../common/classes/ErrorHandling";
import {FormControl, FormGroup, ValidatorFn} from "@angular/forms";
import {NameValidationError} from "../validators/name-validator";
import {OptionSelect} from "../../filters/enum/filter-type";
import {SocialMediaPlatformEnum, SocialMediaPlatformHelper} from "../../../business/enums/SocialMediaPlatformEnum";
import {getEnumFromValue} from "../../../common/utilities/General";

@Component({
  selector: 'drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.css']
})
export class DropDownListComponent {
  @Input() loadInputData?: BehaviorSubject<[any,any]>;
  @Input() loadSelected?: BehaviorSubject<any>;
  @Input() dataList: OptionSelect[] = []

  @Input() isLoading: boolean = false
  @Input() allowEditing: boolean = false;
  @Input() connectToServer: boolean = false;
  @Input() errorHandling: ErrorHandling | null = null;
  @Input() floatingLabel?: string = "";
  @Input() id!: string;
  @Input() group!: FormGroup;
  @Input() validators: ValidatorFn[] = [];

  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchChanged: EventEmitter<any> = new EventEmitter<any>();

  list: OptionSelect[] = []
  control: FormControl = new FormControl();
  loadingTimeout: any;
  selectedOption: OptionSelect | null = null; // Hold the selected option
  searchText: string = '';
  dropdownOpen: boolean = false;
  selectClicked: boolean = false;

  startEditing: boolean = false

  constructor() {

  }


  ngOnInit(): void {
    let validators = [];
    validators.push(...this.validators ?? []);
    this.control.setValidators(validators);
    this.group.addControl(this.id, this.control);

    this.loadInputData?.subscribe((data) => {
      this.updateViews(data);
    });

    this.loadSelected?.subscribe((selected) => {
      this.updateViews([this.list, selected]);
    });



    this.list.push(...this.dataList ?? []);

  }




  get filteredOptions() : OptionSelect[]{
     let list = this.list ?? [];
    if (this.allowEditing && !this.connectToServer && this.startEditing) {
      list  = list.filter(option =>
        option.label.toLowerCase().includes(this.searchText.toLowerCase())
      );
    }
   return  list
  }


  get errorMessage(): string {
    if (this.control.touched) {
      if (this.control.hasError(NameValidationError.invalidFormat)) {
        return `${this.floatingLabel} is invalidFormat`;
      } else if (this.control.hasError("required")) {
        return `${this.floatingLabel} is required`;
      }
    }
    if (this.errorHandling) {
      return this.getErrorMessage();
    }
    return "";
  }



  isInputValid(): string {
    // console.log("is touched == " + this.id + " " + this.control.touched);
    if (this.control.touched) {
      if (
        this.control.hasError("required") ||
        this.control.hasError(NameValidationError.invalidFormat)
      ) {
        return "is-invalid";
      }
    }
    if (this.errorMessage.hasActualValue()) {
      return "is-invalid";
    }
    return "";
  }


  ngOnDestroy(): void {
    this.group?.removeControl(this.id);
  }

  onSearchChange(event: any) {
    this.dropdownOpen = true;
    const value = (event.target as HTMLInputElement).value;
    this.startEditing = true;
    if (this.connectToServer == false) {
      return;
    }

    clearTimeout(this.loadingTimeout); // Clear any existing timeout
    // Show loading spinner after 500 milliseconds
    this.loadingTimeout = setTimeout(() => {// Show loading indicator
      this.onSearchChanged.emit(value);
    }, 1000);
  }



  onSelectOption(option: any): void {
    let selected = option as OptionSelect
    this.selectedOption = selected;
    this.searchText = selected.label
    this.startEditing = false;
    this.onSelected.emit(option);
    this.dropdownOpen = false;

  }


  updateViews(value: any) {
    const [list, selected] = value;

    if(this.selectedOption == null && !this.searchText.hasActualValue() ){
      this.searchText = selected?.label ?? "" ;
    }

    this.list = list;
    this.selectedOption = selected;


    if(selected != null){
      this.onSelected?.emit(this.selectedOption);
    }
  }

  reset(){
    this.searchText = '';
    this.selectedOption = null;
    this.list = [];
  }

  getErrorMessage() {
    let id = this.id ?? "";
    let title = this.floatingLabel ?? "";
    return this.errorHandling?.getValidationErrorMessage(id, title) ?? "";
  }


  toggleSelect(isOpen: boolean) {
    if (isOpen == false){
      this.searchText =  this.selectedOption?.label ?? ""
      this.startEditing = false;
    }
    this.dropdownOpen = isOpen;
  }

  onInputBlur() {
    // Delay hiding the select to allow for a click event on the select
    setTimeout(() => {
      if (!this.selectClicked) {
        this.toggleSelect(false);
      }
    }, 200);
  }

  onSelectBlur() {
    // Flag indicating that the select was clicked
    this.selectClicked = false;
  }

  // Method to toggle the dropdown menu
  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }


  onKeyDown(event: KeyboardEvent) {
    if (!this.allowEditing) {
      event.preventDefault();
    }
  }

  focusInputField(inputField: HTMLInputElement): void {
    inputField.focus();
  }
}


export enum TableAction {
  DETAILS,
  REMOVE,
  EDIT,
  APPROVE,
  REJECT,
  BLOCK,
  UNBLOCK,
  addCommit,
  removeCommit,
}

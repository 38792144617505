import {FilterMetaData} from "../../common/classes/FilterMetaData";
import {ColumnFilterType, FilterFieldTable, SortFieldTable} from "../../components/table-view/table-view.component";
import {DateRange} from "../../components/filters/date-range-filter.component";
import {mergeObjects} from "../../common/utilities/General";
import {Currency} from "../interfaces/Currency";

export class CurrencyFilterData {
  filterMetaData: FilterMetaData | null = null
  filterFieldTable: FilterFieldTable[] = []
  sortField: SortFieldTable | null = null

  static getParameterApi(filter: CurrencyFilterData,) {
    let metaData = FilterMetaData.getParameterApi(filter.filterMetaData!)
    let sortField = FilterMetaData.getParameterSort(filter.sortField)
    let filterFields = this.mapFilterFieldsToList(filter.filterFieldTable)
    const params = mergeObjects(metaData, filterFields, sortField);
    return params;

  }

  static mapFilterFieldsToList(filterFields: FilterFieldTable[]): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const filterField of filterFields) {
      if (filterField.tableColumnInfo.filterType === ColumnFilterType.dateRang) {
        const dateRange = filterField.value as DateRange;
        const fromKey = `filters[from${filterField.tableColumnInfo.columId}]`;
        const toKey = `filters[to${filterField.tableColumnInfo.columId}]`;
        let dateStart = dateRange.start;
        let dateEnd = dateRange.end;
        result[fromKey] = dateStart.toISOString();
        result[toKey] = dateEnd.toISOString();
      } else {
        const key = `filters[${filterField.tableColumnInfo.columId}]`;
        result[key] = filterField.value;
      }
    }
    return result;
  }

  static mapToCurrencies(list: string[]): Currency[] {
    return list.map((item) => {
      return {
        iso3letter: item
      }
    });

  }


}

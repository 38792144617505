<div class="input-text-field-container">
  <div class="form-floating mb-3">
    <textarea id="{{id}}"
              class="form-control"
              style="height: 100px;"
              placeholder="{{floatingLabel}}"
              [formControl]="control"
              [(ngModel)]="inputValue"
              (input)="onInputChange($event)" >
      </textarea>
      <!-- Display validation message as plain text -->
      <!--      <div *ngIf="control.invalid && (control.dirty || control.touched)">-->
      <!--        Textarea is required.-->
      <!--      </div>-->

    <label for="{{id}}">{{floatingLabel}}</label>
    <div class="text-danger">{{errorMessage}}</div>
  </div>
</div>


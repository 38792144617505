import { Component, ElementRef, EventEmitter, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: 'comment-rate',
  templateUrl: './comment-rate.component.html',
  styleUrls: ['./comment-rate.component.scss']
})
export class CommentRateComponent {
  @ViewChild('mobileNumberVerificationModal') modal!: ElementRef;
  form: FormGroup = new FormGroup({});
  @Output() onSuccess: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private renderer: Renderer2,
  ) {
  }



  ngOnInit() {
  }


  showScreen(parameters:{text: string | undefined}) {
    this.updateControlValue("commentId", parameters.text);
    this.openModal()
  }

  submitMobileNumberVerificationForm() {
  }


  openModal() {
    this.renderer.addClass(this.modal.nativeElement, 'show');
    this.renderer.setStyle(this.modal.nativeElement, 'display', 'block');
    this.renderer.setStyle(document.body, 'overflow', 'hidden');
  }

  closeModal() {
    this.renderer.removeClass(this.modal.nativeElement, 'show');
    this.renderer.setStyle(this.modal.nativeElement, 'display', 'none');
    this.renderer.removeStyle(document.body, 'overflow');
  }

  onContainerClicked(event: MouseEvent): void {
    if ((event.target as Element).classList.contains('modal')) {
      this.closeModal();
    }
  }

  onTextChange(text:any){


  }


  submit(){
    this.closeModal();
  }

  updateControlValue(controlName: string, newValue: any): void {
    const control = this.form.get(controlName);
    if (control) {
      (control as AbstractControl).patchValue(newValue);
    } else {
      console.error(
        `Control with name '${controlName}' does not exist in the form.`
      );
    }
  }

}

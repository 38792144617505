import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Params,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {map, mergeMap, Observable, of, tap} from 'rxjs';
import {AppNavigator, NavigatorTarget} from "../services/app-navigator";
import {UserDatabase} from "../business/database/user-database.service";


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate, CanActivateChild {

  constructor(
    private appNavigator: AppNavigator,
    private userDatabase: UserDatabase,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const queryParams = route.queryParams

    return this.handle({queryParams: queryParams, state: state,});
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const queryParams = childRoute.queryParams

    return this.handle({queryParams: queryParams, state: state,});
  }

  private handle(parameters: {
    queryParams: Params,
    state: RouterStateSnapshot,
  }) {
    let queryParams = parameters.queryParams;
    let state = parameters.state;
    let user = this.userDatabase.getCurrentUser();
    if (user == null) {
      this.appNavigator.navigateTo({target: NavigatorTarget.login, queryParams: queryParams});
      return false
    } else {
      return true;
    }
  }
}

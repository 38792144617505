import { Component, AfterViewInit } from "@angular/core";
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { BehaviorSubject, map } from "rxjs";
import { Merchant } from "../../business/interfaces/Merchant";
import { ActivatedRoute, Router } from "@angular/router";
import { MerchantAPI } from "../../business/apis/MerchantAPI";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { TableAction } from "../../components/table-view/enum/TableAction";


@Component({
  selector: "app-merchant",
  templateUrl: "./merchant.component.html",
  styleUrls: ["./merchant.component.scss"],
})
export class MerchantComponent implements AfterViewInit {
  static navigatorName: string = NavigatorTarget.merchantDetails.valueOf();
  loadBasicInfoData: BehaviorSubject<any>;
  merchantDetails: Merchant | null = null;


  merchantId: string = "";
  errorHandling: ErrorHandling | null = null;
  isLoading = false


  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI,
    private router: Router

  ) {
    this.loadBasicInfoData = new BehaviorSubject<any>([]);
  }


  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(map((parameters) => parameters["id"]))
      .subscribe((id) => {
        this.merchantId = id;
        this.reloadDataSource(
          () => {},
          () => {}
        );
      });
  }

  ngAfterViewInit(): void {}


  getMerchant(id:string) {
    this.merchantId = id;
    this.reloadDataSource(
      () => {},
      () => {}
    );
  }

  reloadDataSource(completionHandler: () => void, errorHandler: () => void) {
    if (this.merchantId?.hasActualValue()) {
      this.merchantApi
        .getDetails({ merchantId: this.merchantId })
        .subscribe({
          next: (item: Merchant) => {
            // Handle successful data retrieval
            this.merchantDetails = item;
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
        });
    } else {
      this.reloadDataViews();
      completionHandler(); // Call the completion handler
    }
  }

  navigateToListMerchant(): void {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchants,
    });
  }

  private reloadDataViews() {
    this.loadBasicInfoData.next(this.merchantDetails)
  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  addNewOne() {
    this.appNavigator.reloadCurrentRoute()
    if(this.merchantId.hasActualValue()){
      this.appNavigator.navigateTo({ target: NavigatorTarget.merchantDetails });
    }  else{
      this.appNavigator.reloadCurrentRoute()
    }
  }

  accept() {
    let call = this.merchantApi.approve({ merchant: this.merchantDetails! });

    this.isLoading = true;
    call.subscribe({
      next: () => {
        this.isLoading = false;
        this.reloadDataSource(
          () => {},
          () => {}
        );
      },
      error: (error: any) => {
        // Handle error
        this.isLoading = false;
        this.checkError(error);
      },
    });

  }


  reject(){
   let call = this.merchantApi.reject({ merchant:this.merchantDetails! });

    this.isLoading = true;
    call.subscribe({
      next: () => {
        this.isLoading = false;
        this.reloadDataSource(
          () => {},
          () => {}
        );
      },
      error: (error: any) => {
        // Handle error
        this.isLoading = false;
        this.checkError(error);
      },
    });
  }

  showAction():boolean{
    if(this.merchantDetails!){
      let isApproved = this.merchantDetails!.isApproved ?? false;
      let isRejected = this.merchantDetails!.isRejected ?? false;
      let isDeleted = this.merchantDetails!.isDeleted ?? false;
      return !isApproved && !isRejected && !isDeleted
    }
    return false
  }
}


export enum Entity {
  ADDRESS = "address",
  AREA = "area",
  CATEGORY = "category",
  CITY = "city",
  COUNTRY = "country",
  CURRENCY = "currency",
  CUSTOMER_EMAIL = "CustomerEmail",
  FORGOT_PASSWORD = "ForgotPassword",
  MERCHANT = "Merchant",
  MERCHANT_GEOLOCATION = "MerchantGeolocation",
  MERCHANT_IMAGE = "MerchantImage",
  MERCHANT_PHONE = "MerchantPhone",
  MERCHANT_RATING = "MerchantRating",
  MERCHANT_SOCIAL_MEDIA_PLATFORM = "MerchantSocialMediaPlatform",
  MERCHANT_WEBSITE = "MerchantWebsite",
  PRODUCT = "Product",
  PRODUCT_IMAGE = "ProductImage",
  PRODUCT_RATING = "ProductRating",
  USER = "User",
  NONE = "None",
  UNKNOWN = "Unknown"
}

<div class="fields-layout">

  <div class="mb-3">
    <div class="column">
      <app-file-upload
        (fileRemoved)="onImageRemoved($event)"
        (fileUploaded)="onImageUploaded($event)"
        (onchange)="updateViews($event)"
        (updateCurrentNumberOfFiles)="updateCurrentNumberOfImages($event)"
        [allowMulti]="true"
        [loadMultiImages]="loadDataImageSource"
        [maxFileSizeBytes]="5 * 1024 * 1024"
        [maxNumberOfFiles]="10"
        [onlyAcceptImages]="false"
        [uploadFilePath]="imagesFilePath"
        class="input-field-layout"
      >
      </app-file-upload>
    </div>
  </div>

</div>


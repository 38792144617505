import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, finalize, last, startWith, switchMap } from 'rxjs/operators';
import { AccountAPI } from "../business/apis/AccountAPI";
import { FileUpload } from "../business/interfaces/FileUpload";

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  private basePath = '/uploads';

  constructor(
    private storage: AngularFireStorage,
    private afAuth: AngularFireAuth,
    private accountAPI: AccountAPI
  ) {}

  pushFileToStorage(fileUpload: FileUpload, path: string = ''): Observable<number | string> {
    return this.isSignedIn().pipe(
      switchMap(signedIn => {
        if (signedIn) {
          return this.uploadFile(fileUpload, path);
        } else {
          return this.accountAPI.getFirebaseToken().pipe(
            switchMap(token => {
              console.log("Firebase Token is " + token)
              return this.signInWithCustomToken(token).pipe(
                switchMap(() => this.uploadFile(fileUpload, path))
              );
            }),
            catchError(error => {
              console.error('Failed to fetch or use custom token:', error);
              return of(`Failed to upload: ${error.message}`);
            })
          );
        }
      })
    );
  }

  private isSignedIn(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      switchMap(user => of(!!user))
    );
  }

  private signInWithCustomToken(token: string): Observable<void> {
    return new Observable<void>(observer => {
      this.afAuth.signInWithCustomToken(token)
        .then(() => {
          observer.next();
          observer.complete();
        })
        .catch(error => {
          console.error('Error signing in with custom token:', error);
          observer.error(error);
        });
    });
  }

  private uploadFile(fileUpload: FileUpload, path: string): Observable<number | string> {
    const filePath = `${this.basePath}/${path}/${fileUpload.file?.name}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    let downloadURL$ = uploadTask.snapshotChanges().pipe(
      finalize(() => storageRef.getDownloadURL()),
      last(), // Ensure it only emits after upload is complete
      concatMap(() => storageRef.getDownloadURL())
    );

    return uploadTask.percentageChanges().pipe(
      startWith(0), // Start with 0 progress
      concatMap(progress => {
        const safeProgress = progress !== undefined ? progress : 0;
        return safeProgress < 100 ? of(safeProgress) : downloadURL$;
      })
    );
  }

  deleteFileFromStorage(fileUpload: FileUpload, path: string): Observable<void> {
    return this.isSignedIn().pipe(
      switchMap(signedIn => {
        if (signedIn) {
          return this.performDeleteFile(fileUpload, path);
        } else {
          return this.accountAPI.getFirebaseToken().pipe(
            switchMap(token => {
              console.log("Firebase Token is " + token);
              return this.signInWithCustomToken(token).pipe(
                switchMap(() => this.performDeleteFile(fileUpload, path))
              );
            }),
            catchError(error => {
              console.error('Failed to fetch or use custom token:', error);
              throw error; // Propagate the error further
            })
          );
        }
      })
    );
  }

  private performDeleteFile(fileUpload: FileUpload, path: string): Observable<void> {
    console.log("deleteFileFromStorage");
    console.log("fileUpload === ")
    console.log(fileUpload)
    let fileName = this.extractFileNameFromUrl(fileUpload.remoteURL)
    console.log(fileName)
    const filePath = `${this.basePath}/${path}`;
    const storageRef = this.storage.ref(filePath);
    const fileRef = storageRef.child(fileName);

    return new Observable<void>(observer => {
      fileRef.delete()
        .subscribe({
          next: () => {
            console.log("File deleted successfully");
            observer.next();
            observer.complete();
          },
          error: (err) => {
            console.error('Error deleting file:', err);
            observer.error(err);
          }
        });
    });
  }


  private extractFileNameFromUrl(remoteURL: string): string {
    // Decode the URL to handle encoded characters
    const decodedUrl = decodeURIComponent(remoteURL);

    // Split the decoded URL by '/' and get the last part (which contains the file name)
    const parts = decodedUrl.split('/');
    const fileNameWithParams = parts[parts.length - 1];

    // Split the file name to remove any query parameters
    return fileNameWithParams.split('?')[0];
  }
}

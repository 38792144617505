import { Injectable } from "@angular/core";
import { APIClientService } from "../../services/apiclient.service";
import { catchError, Observable, tap, throwError } from "rxjs";
import { Claim } from "../interfaces/Claim";
import { APIType } from "../../common/enums/APIType";
import { ListClaimResponse } from "../interfaces/response-pagination/ListClaimResponse";
import { ClaimFilterData } from "../classes/ClaimFilterData";
import { RequestType } from "../../common/enums/RequestType";


@Injectable({ providedIn: 'root' })
export class ClaimAPI {
  constructor(private readonly apiClient: APIClientService) {

  }


  getList(filterData: ClaimFilterData): Observable<ListClaimResponse> {
    let parameter = ClaimFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListClaimResponse>({
      apiType: APIType.GET_ALL_MERCHANT_ClAIM,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }


  approve(parameters: {
    claim: Claim,
  }): Observable<void> {
    let dataUrl = parameters.claim.id
    return this.apiClient.call<any, void>({
      apiType: APIType.APPROVE_MERCHANT_Claim,
      requestType: RequestType.POST,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  reject(parameters: {
    claim: Claim,
  }): Observable<void> {
    let dataUrl = parameters.claim.id
    return this.apiClient.call<any, void>({
      apiType: APIType.REJECT_MERCHANT_Claim,
      requestType: RequestType.POST,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

}

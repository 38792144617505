<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">Merchant</a></li>
  </ol>
</nav>

<h1 class="h3 mb-3"><strong>Add</strong> Url</h1>


<div class="fields-layout">

  <!-- urlItemItem -->
  <input-text-field (onchange)="updateInputFieldById(urlItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]= urlItem.floatingLabel
                    [group]="form"
                    [id]= urlItem.id
                    [inputType]= urlItem.type
                    [loadData]="urlItem.loadData"
                    [validators]="urlItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- PlatForm -->
  <drop-down-list (onSelected)="updateInputFieldById(platFormItem.id.toString(), $event)"
                  [errorHandling]="errorHandling"
                  [floatingLabel]= platFormItem.floatingLabel
                  [group]="form"
                  [id]= platFormItem.id
                  [validators]=[Validators.required]
                  [dataList] = getPlatFormList()
                  [connectToServer] = true
                  class="input-field-layout"
                  >
  </drop-down-list>


</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>








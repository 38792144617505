
<!-- Country -->
<drop-down-list (onSelected)="onInputChange($event)"
                (onSearchChanged)="onSearchChanged($event)"
                [errorHandling]="errorHandling"
                floatingLabel="Category"
                [group]="group"
                id= "{{id}}"
                [isLoading]="isLoading"
                [loadInputData]="loadInputData"
                [validators]="validators"
                [allowEditing] = true
                [connectToServer] = false>
</drop-down-list>


import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Merchant} from "../../../../business/interfaces/Merchant";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {
  ActionRow,
  FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import {ActivatedRoute} from "@angular/router";
import {AppNavigator, NavigatorTarget} from "../../../../services/app-navigator";
import {MerchantAPI} from "../../../../business/apis/MerchantAPI";
import {TableAction} from "../../../../components/table-view/enum/TableAction";
import {AddressNameId} from "../../../../business/name-id/AddressNameId";
import {Address} from "../../../../business/interfaces/Address";

@Component({
  selector: 'merchant-address',
  templateUrl: './merchant-address.component.html',
  styleUrls: ['./merchant-address.component.css']
})
export class MerchantAddressComponent {
  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() reloadData = new EventEmitter<string>();

  merchantDetails: Merchant | null = null;
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<Address> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {
  }

  ngOnInit(): void {
    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

    this.loadData!.subscribe((data) => {
      this.merchantDetails = data;
      this.reloadDataViews();
      this.isScreenLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }


  reloadDataViews() {
    let list = this.merchantDetails?.addresses ?? [];
    this.tableConfiguration.loadData?.next([list, null]);
  }


  getActionRowTable<T>(item: T): ActionRow[] {
    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };
    return [deleteItem]
  }

  getColumTableInfo(): TableColumnInfo[] {
    //

    let id = {
      columId: AddressNameId.ID,
      name: "Id",
    };


    let addressEn = {
      columId: AddressNameId.ADDRESS_EN,
      name: "Address - English",
    };

    let addressAr = {
      columId: AddressNameId.ADDRESS_AR,
      name: "Address - Arabic",
    };


    let city = {
      columId: AddressNameId.CITY,
      name: "City",
    };

    let streetEn = {
      columId: AddressNameId.STREET_EN,
      name: "streetEn",
    };

    let streetAr = {
      columId: AddressNameId.STREET_AR,
      name: "streetAr",
    };

    let buildingEn = {
      columId: AddressNameId.BUILDING_EN,
      name: "buildingEn",
    };

    let buildingAr = {
      columId: AddressNameId.BUILDING_AR,
      name: "buildingAr",
    };

    let floor = {
      columId: AddressNameId.FLOOR,
      name: "floor",
    };

    let postCode = {
      columId: AddressNameId.POST_CODE,
      name: "postCode",
    };

    let createdAt = {
      columId: AddressNameId.CREATED_AT,
      name: "createdAt",
    };


    return [
      id,
      addressEn,
      addressAr,
      city,
      streetEn,
      streetAr,
      buildingEn,
      buildingAr,
      floor,
      postCode,
      createdAt,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == AddressNameId.ID) {
      this.onTapAddressDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let address = item as Address;
    let value = address[id as keyof Address];
    let styleClass = null;

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantAddressDetails,
      id: this.merchantDetails?.id,
    });
  }


  private onTapAction<T>(action: any, item: T) {
    let AddressAction = action as TableAction;
    let Address = item as Address;
    let call: Observable<any>;

    if (AddressAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      let AddressId = Address.id
      call = this.merchantApi.deleteAddress({merchantId: merchantId, addressId: AddressId});
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.emit(this.merchantDetails?.id);
      },
      error: (error: any) => {
      },
    });
  }

  private onTapAddressDetails<T>(item: T) {
    let Address = item as Address;
    // this.appNavigator.navigateTo({
    //   target: NavigatorTarget.AddressDetails,
    //   id: Address.id,
    // });
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}

import {Injectable} from "@angular/core";
import {APIClientService} from "../../services/apiclient.service";
import {catchError, map, Observable, tap, throwError} from "rxjs";
import {APIType} from "../../common/enums/APIType";
import {RequestType} from "../../common/enums/RequestType";
import {Currency} from "../interfaces/Currency";
import {CurrencyFilterData} from "../classes/CurrencyFilterData";


@Injectable({providedIn: 'root'})
export class CurrencyAPI {
  constructor(private readonly apiClient: APIClientService) {

  }




  save(parameters: {
    id:string
    currency: Currency,
  }): Observable<string> {
    let currency = parameters.currency;
    let id = parameters.id;
    let dataUrl = id.hasActualValue() ? `/${id}` : "";
    let request = {
      iso3letter: currency.iso3letter,
    }


    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_CURRENCY,
      requestType:  RequestType.POST,
      body: request,
      dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  getList(filterData: CurrencyFilterData): Observable<Currency[]> {
    let parameter = CurrencyFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, string[]>({
      apiType: APIType.GET_LIST_CURRENCIES,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      map((response) => {
        return CurrencyFilterData.mapToCurrencies(response);
      }),
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }




  getDetails(parameters: {
    currencyId: string,
  }): Observable<Currency> {
    let dataUrl = parameters.currencyId;
    return this.apiClient.call<any, Currency>({
      apiType: APIType.GET_CURRENCY_DETAILS,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }



  delete(parameters: {
    currency: Currency,
  }): Observable<void> {
    let dataUrl = parameters.currency.iso3letter
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_CURRENCY,
      requestType: RequestType.DELETE,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'collapsible-header',
  templateUrl: './collapsible-header.component.html',
  styleUrls: ['./collapsible-header.component.css']
})
export class CollapsibleHeaderComponent {
  @Input() title: string = 'Header Title';
  @Input() isOpen: boolean = false;
   id: string = "";
  ngOnInit(): void {
    this.id = this.title.replace(/\s+/g, '');
  }

  toggleCollapse(): void {
    this.isOpen = !this.isOpen; // Update isOpen property
  }

}

import { Component, Input } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { OptionSelect } from "../filters/enum/filter-type";
import { MetaData } from "../../common/interfaces/MetaData";
import { FilterMetaData } from "../../common/classes/FilterMetaData";


export enum ColumnFilterType {
  noun = "",
  text = "text",
  date = "date",
  dateRang = "dateRang",
  number = "number",
  select = "select",
  multiSelect = "multiSelect",
}

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}


export interface FilterFieldTable {
  tableColumnInfo: TableColumnInfo;
  value: any
}

export interface SortFieldTable {
  tableColumnInfo: TableColumnInfo;
  direction: SortDirection
}


export interface FilterTable {
  filterMetaData: FilterMetaData;
  filterFieldTable: FilterFieldTable[];
  sortFieldTable?: SortFieldTable;
}

export interface PagePagination {
  show?: boolean;
  list?: number[] | undefined;
  selectedItemsPerPage: number | null | undefined;
}


export interface TableColumnInfo {
  columId: string
  name: string
  filterType?: ColumnFilterType,
  listSelectedOption?: any[],
  isCellSelected?: boolean
}


export interface TableConfiguration<T> {
  hasFilter?: boolean,
  pagePagination?: PagePagination,
  tableColumnInfo?: TableColumnInfo[];
  actionsRow?: (item: T) => ActionRow[];
  getRowValue?: (column: TableColumnInfo, item: T) => RowValue;
  onCellTapped?: <T>(column: TableColumnInfo, item: T) => void;
  loadData?: BehaviorSubject<[any, any]>;
  reloadDataTable?: (filterData: FilterTable) => void;
}


export interface ActionRow {
  title: string
  type: string
  iconClass: string
  actionType: any
  onTapAction?: <T>(actionType: any, item: T) => void;
}


export interface RowValue {
  value?: string,
  style?: string,
  class?: string,
}


@Component({
  selector: 'table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.css']
})

export class TableViewComponent {
  @Input() tableConfiguration: TableConfiguration<any> = {};
  @Input() isLoading: boolean = false;


  listTableColumInfo: TableColumnInfo[] = []
  filterDataSource: [] = []

  pagination: MetaData | null = null;

  currentPage: number = 1;
  itemsPerPage: number | string | null | undefined = null;
  filterMetaData: FilterMetaData = new FilterMetaData();
  filterFieldTable: FilterFieldTable[] = []
  sortFieldTable?: SortFieldTable;
  //enum to define in html
  ColumnFilterType = ColumnFilterType;
  SortDirection = SortDirection

  constructor() {
  }

  get getDataSource(): [] {
    return this.filterDataSource;
  }

  get pagedRows() {
    return this.getDataSource;
  }

  // Generate an array of page numbers
  get pages() {
    const totalPages = this.pagination?.last_page ?? 1;
    return Array(totalPages).fill(0).map((x, i) => i + 1);
  }

  ngOnInit(): void {
    // Initially display unsorted users
    this.listTableColumInfo = this.tableConfiguration.tableColumnInfo ?? [];
    this.tableConfiguration.loadData!.subscribe(data => {
      const [list, pagination] = data;
      this.filterDataSource = list;
      this.pagination = pagination;
    })

    this.itemsPerPage = this.tableConfiguration.pagePagination?.selectedItemsPerPage;
    this.filterMetaData.itemsPerPage = this.itemsPerPage;

    this.observeDataSource();
  }

  getValueColumn(column: TableColumnInfo, item: any): RowValue {
    let getRowValue = this.tableConfiguration.getRowValue;
    return getRowValue ? getRowValue(column, item) : {}
  }

  getRowAction(item: any): ActionRow[] {
    let actionsRow = this.tableConfiguration.actionsRow;
    return actionsRow ? actionsRow(item) : []
  }

  // Update currentPage when page changes
  onPageChange(pageNumber: number) {
    this.currentPage = pageNumber;
    this.updateCurrentPage(this.currentPage)
  }

  // Navigate to previous page
  goToPreviousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
    this.updateCurrentPage(this.currentPage)
  }

  updateCurrentPage(current: number) {
    this.filterMetaData.currentPage = current
    this.observeDataSource();
  }

  observeDataSource() {
    let filterTable = {
      filterFieldTable: this.filterFieldTable,
      filterMetaData: this.filterMetaData,
      sortFieldTable: this.sortFieldTable,
    }

    this.tableConfiguration?.reloadDataTable!(filterTable)
  }

  // Navigate to next page
  goToNextPage() {
    let totalPages = this.pagination?.last_page ?? 1;
    if (this.currentPage < totalPages) {
      this.currentPage++;
    }

    this.updateCurrentPage(this.currentPage)
  }

  // Go to a specific page
  goToPage(page: number) {
    this.currentPage = page;
    this.updateCurrentPage(this.currentPage)
  }

  // Handle items per page change

  onItemsPerPageChange(event: any) {
    const selectedValue = event
    if (selectedValue === 'all') {
      this.filterMetaData.itemsPerPage = null
      this.itemsPerPage = 'all';
    } else {
      this.itemsPerPage = Number(selectedValue)
      this.filterMetaData.itemsPerPage = this.itemsPerPage
    }
    this.currentPage = 1
    this.filterMetaData.currentPage = null

    this.observeDataSource()
  }


  sort(column: TableColumnInfo) {
    let sortDirection: SortDirection;
    if (this.sortFieldTable?.tableColumnInfo.columId == column.columId) {
      sortDirection = this.sortFieldTable?.direction === SortDirection.asc ? SortDirection.desc : SortDirection.asc;
    } else {
      sortDirection = SortDirection.asc;
    }

    this.sortFieldTable = {
      tableColumnInfo: column,
      direction: sortDirection,
    }
    this.observeDataSource()

    // Implement your sorting logic based on the clicked column and sortDirection
    // console.log('Sorting by column:', column, 'Direction:', this.sortDirection);
  }


  handleClick(action: ActionRow, item: any): void {
    // Perform actions based on the clicked button
    action.onTapAction!(action.actionType, item)
  }

  handleClickCell(column: TableColumnInfo, item: any): void {
    // Perform actions based on the clicked button
    if (column.isCellSelected === true) {
      this.tableConfiguration.onCellTapped!(column, item)
    }
  }


  applyFilter(event: Event, column: TableColumnInfo) {
    this.removeItemById(column.columId);
    if (column.filterType == ColumnFilterType.text) {
      const target = event.target as HTMLInputElement;
      const value = target ? target.value : '';
      const searchTerm = value.toLowerCase().trim();
      let newItem = { tableColumnInfo: column, value: searchTerm }
      this.filterFieldTable.push(newItem);
    }

    if (column.filterType == ColumnFilterType.dateRang) {
      const value = event;
      if (value) {
        let newItem = { tableColumnInfo: column, value: value }
        this.filterFieldTable.push(newItem);
      }
    } else if (column.filterType == ColumnFilterType.number) {
      const value = event;
      if (value) {
        let newItem = { tableColumnInfo: column, value: value }
        this.filterFieldTable.push(newItem);
      }
    } else if (column.filterType == ColumnFilterType.select) {
      const value = event; // Capture the value from the event
      // Check if the value is not null or undefined
      if (event != null) {
        // Create a new object with table column info and the captured value
        const newItem = { tableColumnInfo: column, value: value };
        // Push the new item into the filterFieldTable array
        this.filterFieldTable.push(newItem);
      }
    }

    this.observeDataSource()
  }

  removeItemById(id: string): void {
    this.filterFieldTable = this.filterFieldTable.filter(item => item.tableColumnInfo.columId !== id);
  }


  selectStatusOption(column: TableColumnInfo): OptionSelect[] {
    let list: OptionSelect[] = column.listSelectedOption ?? []
    return list;
  }

  onSelectOption(selected: OptionSelect) {
    console.log(selected);
  }

}

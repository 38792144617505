import { Component } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../components/table-view/table-view.component";
import { Customer } from "../../business/interfaces/Customer";
import { BehaviorSubject } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { CustomerNameId } from "../../business/name-id/CustomerNameId";
import { format } from "date-fns";
import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { ListCustomerResponse } from "../../business/interfaces/response-pagination/ListCustomerResponse";
import { CustomerSubscriptionFilterData } from "../../business/classes/CustomerSubscriptionFilterData";
import { CustomerAPI } from "../../business/apis/CustomerAPI";

@Component({
  selector: 'app-list-customer-subscription',
  templateUrl: './list-customer-subscription.component.html',
  styleUrls: ['./list-customer-subscription.component.css']
})
export class ListCustomerSubscriptionComponent {
  static navigatorName: string = NavigatorTarget.customers.valueOf();

  tableConfiguration: TableConfiguration<Customer> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Customer[] = [];
  isScreenLoading = false;

  constructor(
    private sanitizer: DomSanitizer,
    private customerApi: CustomerAPI,
    private appNavigator: AppNavigator
  ) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    return []
  }

  getColumTableInfo(): TableColumnInfo[] {

    let optionBool = [
      {
        value: true,
        label: "true",
        isSelected: false,
      },
      {
        value: false,
        label: "false",
        isSelected: false,
      },
    ];

    let idItem = {
      columId: CustomerNameId.ID,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };


    let email = {
      columId: CustomerNameId.EMAIL,
      name: "Email",
      filterType: ColumnFilterType.text,
    };

    let emailVerified = {
      columId: CustomerNameId.EMAIL_VERIFIED,
      name: "Email Verified",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };


    let isDeletedItem = {
      columId: CustomerNameId.IS_DELETED,
      name: "Is Deleted",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let createdAtItem = {
      columId: CustomerNameId.CREATED_AT,
      name: "Created",
      filterType: ColumnFilterType.dateRang,
    };


    return [
      idItem,
      email,
      emailVerified,
      isDeletedItem,
      createdAtItem,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == CustomerNameId.ID) {
      this.onTapCustomerDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let customer = item as Customer;
    let value = customer[id as keyof Customer];
    let styleClass = null;
    if (id == CustomerNameId.ID) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    // this.appNavigator.navigateTo({ target: NavigatorTarget.customerDetails });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new CustomerSubscriptionFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    this.customerApi.getListSubscription(filter).subscribe({
      next: (response: ListCustomerResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }


  private onTapCustomerDetails<T>(item: T) {
    let customer = item as Customer;
    // this.appNavigator.navigateTo({
    //   target: NavigatorTarget.customerDetails,
    //   id: customer.id,
    // });
  }

  private reloadTable(response: ListCustomerResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}

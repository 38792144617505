import { Component, EventEmitter, Output } from "@angular/core";

export interface NumberOperation {
  number: string;
  operation: string;
}


@Component({
  selector: 'number-input',
  styleUrls: ['./style/number-filter-style.css'], /// Specify the URL of your styles file
  template: `
    <div class="input-group number-input">
      <input type="number" class="form-control" (keyup.enter)="onSelectChange($event)" [(ngModel)]="comparison"
            >
      <div class="operator">
        <select class="form-select select"  [(ngModel)]="operation" (change)="onSelectChange($event)">
          <option value=">">></option>
          <option value="<"><</option>
          <option value=">=">>=</option>
          <option value="<="><=</option>
          <option value="=">=</option>
        </select>
      </div>
    </div>
  `

})
export class NumberFilter {

  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>()

  operation: string = '=';
  comparison: string = "";

  constructor() {
  }

  onSelectChange(event: Event) {
    let value = {
      number: this.comparison,
      operation: this.getOperation()
    } as NumberOperation;
    this.onSelect?.emit(value);
  }

  getOperation(): string {
    let operation = ""
    if (this.operation === '>') {
      operation = 'gt';
    } else if (this.operation === '<') {
      operation = 'lt';
      // Perform action for < comparison
    } else if (this.operation === '>=') {
      operation = 'gteq';
      // Perform action for >= comparison
    } else if (this.operation === '<=') {
      operation = 'lteq';
    } else if (this.operation === '=') {
    }
    return operation;
  }

}

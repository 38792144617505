import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { ColumnFilterType, FilterFieldTable, SortFieldTable } from "../../components/table-view/table-view.component";
import { DateRange } from "../../components/filters/date-range-filter.component";
import { mergeObjects } from "../../common/utilities/General";
import { NumberOperation } from "../../components/filters/number-filter.component";
import { ProductNameId } from "../name-id/ProductNameId";

export class ProductFilterData {
  filterMetaData: FilterMetaData | null = null
  filterFieldTable: FilterFieldTable[] = []
  sortField: SortFieldTable | null = null

  static getParameterApi(productFilterData: ProductFilterData,) {
    let metaData = FilterMetaData.getParameterApi(productFilterData.filterMetaData!)
    let sortField = FilterMetaData.getParameterSort(productFilterData.sortField)
    let filterFields = this.mapFilterFieldsList(productFilterData.filterFieldTable)

    const params = mergeObjects(metaData, filterFields, sortField);

    Object.keys(params).forEach(key => {
      return (params[key] === null || params[key] === undefined) && delete params[key]
    });

    return params;
  }

  static mapFilterFieldsList(filterFields: FilterFieldTable[]): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const filterField of filterFields) {
      if (filterField.tableColumnInfo.filterType === ColumnFilterType.dateRang) {
        const dateRange = filterField.value as DateRange;
        const fromKey = `filters[from${filterField.tableColumnInfo.columId}]`;
        const toKey = `filters[to${filterField.tableColumnInfo.columId}]`;
        let dateStart = dateRange.start;
        let dateEnd = dateRange.end;
        result[fromKey] = dateStart.toISOString();
        result[toKey] = dateEnd.toISOString();
      } else if (filterField.tableColumnInfo.filterType === ColumnFilterType.number) {
        const numberOperation = filterField.value as NumberOperation;
        const fromKey = `filters[${numberOperation.operation}${filterField.tableColumnInfo.columId}]`;
        let value = numberOperation.number;

        if (filterField.tableColumnInfo.columId === ProductNameId.PRICE) {
          value = value.toString().toCents()
        }
        result[fromKey] = value;
      }
      else {
        const key = `filters[${filterField.tableColumnInfo.columId}]`;
        result[key] = filterField.value;
      }
    }

    return result;
  }

}

import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";


export class UrlValidator {
  get validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let value: string = control.value;
      if (UrlValidator.invalidUrl({text: value})) {
        return {invalid: true}
      }
      return null;
    }
  }

  static isValid(text: string): boolean {
    let isvalidItem = true;
    let value: string = text;
    if (UrlValidator.invalidUrl({text: value})) {
      isvalidItem = false
    }
    return isvalidItem;
  }

  private static invalidUrl(data: { text: string }): boolean {
    const urlPattern = /^(?:http|https):\/\/[\w.-]+(?:\.[\w\.-]+)*[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=]+$/;
    const regexp = new RegExp(urlPattern);
    return !(regexp.test(data.text));
  }
}


import { Component } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { User } from "../../business/interfaces/User";
import { FormGroup, Validators } from "@angular/forms";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { isFormSubmitted } from "../../common/utilities/FormsHelper";
import { InputType } from "../../components/input/enum/InputType";
import { OptionSelect } from "../../components/filters/enum/filter-type";
import { GenderHelper } from "../../business/enums/Gender";
import { AccountAPI } from "../../business/apis/AccountAPI";

@Component({
  selector: 'app-user-details',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
  static navigatorName: string = NavigatorTarget.changePassword.valueOf();
  userDetails: User | null = null;
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;
  Validators = Validators
  InputType = InputType
  oldPassWord: string = ""
  newPassWord: string = ""

  constructor(
    private accountAPI: AccountAPI,
    private appNavigator: AppNavigator,
  ) {
  }

  ngOnInit(): void {

  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }


  updateOldPassword(value:any) {
    this.oldPassWord =  value.toString()
  }

  updateNewPassword(value:any) {
    this.newPassWord = value.toString()
  }


  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateUser();
    }
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  getListProductState(): OptionSelect[] {
    return GenderHelper.getListOption();
  }


  private updateUser() {

    this.restError();
    this.isLoading = true;
    this.accountAPI.updatePassword({
      oldPassword: this.oldPassWord,
      newPassword: this.newPassWord,
    }).subscribe({
      next: (item) => {
        console.log(item);
        this.navigateBack()
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }


  private checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  private restError() {
    this.errorHandling = null;
  }

}

export enum ClaimNameId {
  ID = 'id',
  NAME = 'name',
  EMAIL = 'email',
  COUNTRY_CODE = "countryCode",
  PHONE_NUMBER = "phoneNumber",
  MERCHANT_ID = 'merchantId',
  IS_APPROVED = 'isApproved',
  IS_REJECTED = 'isRejected',
  CREATED_AT = 'createdAt',
  STATUS = 'status',
}

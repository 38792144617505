import { OptionSelect } from "../../components/filters/enum/filter-type";

export enum Gender {
  MALE = "MALE",
  FEMALE = "FEMALE",
}


export namespace GenderHelper {
  export function localize(platform: Gender): string {
    switch (platform) {
      case Gender.MALE:
        return 'Male';
      case Gender.FEMALE:
        return 'Female';
    }
  }

  export function getListOption(): OptionSelect[] {
    const optionSelect: OptionSelect[] = [];
    Object.values(Gender).forEach(value => {
      let enumValue = value as Gender;
      const localizedName = GenderHelper.localize(enumValue);
      const item: OptionSelect = { value, label: localizedName };
      optionSelect.push(item);
    });
    return optionSelect;
  }

  export function getOption(value: string): OptionSelect | null {
    for (const item of Object.values(Gender)) {
      if (value === item) {
        const localizedName = GenderHelper.localize(item as Gender);

        let valueItem = { value: item, label: localizedName } as OptionSelect;
        return valueItem
      }
    }
    return null;
  }

}






export enum Field {
  AREA_ID = "areaId",
  NAME = "name",
  COUNTRY_CODE= "countryCode",
  EMAIL = "email",
  PASSWORD = "password",
  PHONE_NUMBER = "phoneNumber",
  PRICE = "price",
  GENDER = "gender",
  BIRTHDATE = "birthDate",
  IS_ADMIN = "isAdmin",
  MERCHANT_ID = "merchantId",
  RATING = "rating",
  COMMENT = "comment",
  URL = "url",
  CURRENCY = "currency",
  CATEGORY_ID = "categoryId",
  STATE = "state", // RAW, COOKED, HOT
  READY_WITHIN = "readyWithin",
  DESCRIPTION = "description",
  THUMBNAIL_URL = "thumbnailURL",
  ADDRESS = "address",
  STREET = "street",
  BUILDING = "building",
  FLOOR = "floor",
  POST_CODE = "postCode",
  CITY_ID = "cityId",
  LONGITUDE = "longitude",
  LATITUDE = "latitude",
  HAS_WHATSAPP = "hasWhatsapp",
  HAS_TELEGRAM = "hasTelegram",
  PROFILE_PICTURE_URL = "profilePictureUrl",
  COVER_PHOTO_URL = "coverPhotoUrl",
  BRIEF = "brief",
  TAX_IDENTIFICATION_NUMBER = "taxIdentificationNumber",
  DELIVERY_AVAILABLE = "deliveryAvailable",
  PICKUP_AVAILABLE = "pickupAvailable",
  PLATFORM = "platform",
  ISO_3LETTER = "iso3letter",
  ISO_2LETTER = "iso2letter",
  COUNTRY_ISO_3LETTER = "countryISO3letter",
  MERCHANT_PROFILE_PICTURE_URL = "merchantProfilePictureURL",
  MERCHANT_COVER_PHOTO_URL = "merchantCoverPhotoURL",
  MERCHANT_NAME = "merchantName",
  MERCHANT_BRIEF = "merchantBrief",
  MERCHANT_TAX_IDENTIFICATION_NUMBER = "merchantTaxIdentificationNumber",
  MERCHANT_DELIVERY_AVAILABLE = "merchantDeliveryAvailable",
  MERCHANT_PICKUP_AVAILABLE = "merchantPickupAvailable",
  OLD_PASSWORD = "oldPassword",
  NONE = "none",
  UNKNOWN = "unkown",
}





import { ActivatedRoute, Params, Router } from "@angular/router";
import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { ListProductsRatesComponent } from "../pages/list-products-rates/list-products-rates.component";


export enum NavigatorTarget {
  home = '',
  login = 'login',
  dashboard = 'dashboard',
  profile = 'profile',
  merchants = 'merchants',
  merchantDetails = "merchant",
  categories = 'categories',
  categoryDetails = 'category',
  cities = 'cities',
  cityDetails = 'city',
  countries = 'countries',
  countryDetails = 'country',
  currencies = 'currencies',
  currencyDetails = 'currency',
  areas = 'areas',
  areaDetails = 'area',
  merchantAreaDetails = 'merchantArea',
  merchantAddressDetails = 'merchantAddress',
  merchantPhoneDetails = 'merchantPhoneDetails',
  merchantWebsiteDetails = 'merchantWebsiteDetails',
  merchantSocialMediaPlatformDetails = 'merchantSocialMediaPlatformDetails',
  merchantGeoLocationDetails = 'merchantGeoLocationDetails',
  products = 'products',
  productDetails = "product",
  users = "users",
  userDetails = "user",
  customers = "customers",
  claims = "claims",
  editProfile = "editProfile",
  changePassword = "changePassword",
  rateDetails = "rateDetails",
 listProductsRates =  "ListProductsRates",
 listMerchantRates =  "listMerchantRates"
}


@Injectable({ providedIn: 'root' })
export class AppNavigator {

  constructor(
    private router: Router,
    private location: Location,
   private route: ActivatedRoute,
) {

  }


  navigateTo(parameters: { target: NavigatorTarget, id?: string, id2?: string, parameter?: any, queryParams?: Params }) {
    const name = parameters.target.valueOf()

    let path = `/${name}`
    if (parameters.id !== undefined) {
      path = `${path}/${parameters.id}`
    }
    if (parameters.id2 !== undefined) {
      path = `${path}/${parameters.id2}`
    }

    if (parameters.parameter !== null && parameters.parameter !== undefined) {
      this.router.navigate([path, parameters.parameter]).then();
    } else {
      this.router.navigate([path]).then();
    }
  }



  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
    });

  }


  back() {
    this.location.back()
  }


  url(): string {
    return this.router.url
  }

  queryParams(): Params {
    const queryParams = this.router.routerState.snapshot.root.queryParams;
    return queryParams;
  }
}

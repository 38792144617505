import {Component} from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {FormGroup, Validators} from "@angular/forms";
import {ErrorHandling} from "../../common/classes/ErrorHandling";
import {SocialMediaPlatform} from "../../business/interfaces/SocialMediaPlatform";
import {InputField} from "../interfaces/InputField";
import {ActivatedRoute} from "@angular/router";
import {MerchantAPI} from "../../business/apis/MerchantAPI";
import {SocialMediaPlatformNameId} from "../../business/name-id/SocialMediaPlatformNameId";
import {getEnumFromValue} from "../../common/utilities/General";
import {isFormSubmitted} from "../../common/utilities/FormsHelper";
import {InputFieldType} from "../../components/input/enum/InputFieldType";
import {UrlValidator} from "../../components/input/validators/url-validator";
import {BehaviorSubject, of} from "rxjs";
import {SocialMediaPlatformEnum, SocialMediaPlatformHelper} from "../../business/enums/SocialMediaPlatformEnum";
import {OptionSelect} from "../../components/filters/enum/filter-type";

@Component({
  selector: 'app-merchant-social-media-platform-details',
  templateUrl: './merchant-social-media-platform-details.component.html',
  styleUrls: ['./merchant-social-media-platform-details.component.css']
})
export class MerchantSocialMediaPlatformDetailsComponent {
  static navigatorName: string =
    NavigatorTarget.merchantSocialMediaPlatformDetails.valueOf();

  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  socialMediaPlatform: SocialMediaPlatform | null = null;
  merchantId: string = "";
  socialMediaPlatformId: string = "";

  socialMediaPlatformFields: InputField[] = [];
  urlItem!: InputField;
  platFormItem!: InputField;

  // Enum
  Validators = Validators;
  SocialMediaPlatformNameId = SocialMediaPlatformNameId;

  constructor(
    private activatedRoute: ActivatedRoute,
    private merchantApi: MerchantAPI,
    private appNavigator: AppNavigator
  ) {
  }

  ngOnInit(): void {
    this.socialMediaPlatformFields = this.getInputFields();

    this.activatedRoute.params.subscribe((parameters) => {
      this.merchantId = parameters["merchantId"] ?? "";
      this.socialMediaPlatformId = parameters["id"] ?? "";
    });
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
  }


  private getInputFields(): InputField[] {

    this.urlItem = {
      id: SocialMediaPlatformNameId.URL,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Url",
      value: "",
      validators: [Validators.required, new UrlValidator().validator],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    this.platFormItem = {
      id: SocialMediaPlatformNameId.PLATForm,
      floatingLabel: "PLatForm",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>(null),
    } as InputField;

    return [this.urlItem , this.platFormItem ];
  }

  getPlatFormList(): OptionSelect[] {
    let optionSelect: OptionSelect[] = []
    for (const platformKey of Object.keys(SocialMediaPlatformEnum)) {
      let platformEnum = getEnumFromValue(SocialMediaPlatformEnum, platformKey) as SocialMediaPlatformEnum;
      const localizedName = SocialMediaPlatformHelper.localize(platformEnum);
      let item = {value: platformEnum, label: localizedName}
      optionSelect.push(item)
    }
    return optionSelect;
  }

  getInputFieldById(id: SocialMediaPlatformNameId): InputField | undefined {
    let item = this.socialMediaPlatformFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(SocialMediaPlatformNameId, id) as SocialMediaPlatformNameId;
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(SocialMediaPlatformNameId, id) as SocialMediaPlatformNameId;
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.saveMerchantSocialMediaPlatform();
    }
  }

  saveMerchantSocialMediaPlatform() {
    this.restError();
    this.isLoading = true;
    let merchantId = this.merchantId ?? "";

    const platformSelectedValue = this.getValueFieldById(SocialMediaPlatformNameId.PLATForm)  as OptionSelect;
    let platform = platformSelectedValue.value

    let socialMediaPlatform = {
      id: this.socialMediaPlatformId,
      url: this.getValueFieldById(SocialMediaPlatformNameId.URL)?.toString(),
      platform: platform,
    } as SocialMediaPlatform;

    this.merchantApi
      .addSocialMediaPlatform({
        merchantId: merchantId,
        socialMediaPlatform: socialMediaPlatform,
      })
      .subscribe({
        next: (item) => {
          this.navigateBack();
        },
        error: (error: any) => {
          this.checkError(error);
          this.isLoading = false;
        },
      });
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {
  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }




}


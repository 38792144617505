<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateBack()" class="link-primary">List Countries</a></li>
    <li *ngIf="countryDetails" aria-current="page" class="breadcrumb-item active">{{countryDetails.nameEn}}</li>
    <li *ngIf="!countryDetails" aria-current="page" class="breadcrumb-item active">Add</li>
  </ol>
</nav>

<!-- Show this title only if myObject is null -->
<h1 *ngIf="!countryDetails" class="h3 mb-3"><strong>Add</strong> country</h1>

<!-- Show this title only if myObject exists (not null) -->
<h1 *ngIf="countryDetails" class="h3 mb-3"><strong>Update</strong> country</h1>


<div class="fields-layout">

  <!-- nameEnglishItem -->
  <input-text-field (onchange)="updateInputFieldById(nameEnglishItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameEnglishItem.floatingLabel"
                    [group]="form"
                    [id]="nameEnglishItem.id.toString()"
                    [inputType]="nameEnglishItem.type"
                    [loadData]="nameEnglishItem.loadData"
                    [validators]="nameEnglishItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- nameArabicItem -->
  <input-text-field (onchange)="updateInputFieldById(nameArabicItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameArabicItem.floatingLabel"
                    [group]="form"
                    [id]="nameArabicItem.id.toString()"
                    [inputType]="nameArabicItem.type"
                    [loadData]="nameArabicItem.loadData"
                    [validators]="nameArabicItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- iso2letterItem -->
  <input-text-field (onchange)="updateInputFieldById(iso2letterItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="iso2letterItem.floatingLabel"
                    [group]="form"
                    [id]="iso2letterItem.id.toString()"
                    [inputType]="iso2letterItem.type"
                    [loadData]="iso2letterItem.loadData"
                    [validators]="iso2letterItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- iso3letterItem -->
  <input-text-field (onchange)="updateInputFieldById(iso3letterItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="iso3letterItem.floatingLabel"
                    [group]="form"
                    [id]="iso3letterItem.id.toString()"
                    [inputType]="iso3letterItem.type"
                    [loadData]="iso3letterItem.loadData"
                    [validators]="iso3letterItem.validators"
                    class="input-field-layout"
  ></input-text-field>


  <!-- iso3letterItem -->
  <input-text-field (onchange)="updateInputFieldById(countryCodeItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="countryCodeItem.floatingLabel"
                    [group]="form"
                    [id]="countryCodeItem.id.toString()"
                    [inputType]="countryCodeItem.type"
                    [loadData]="countryCodeItem.loadData"
                    [validators]="countryCodeItem.validators"
                    [appNumericOnly] = true
                    class="input-field-layout"
  ></input-text-field>

</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="countryDetails ? 'Update' : 'Add'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>







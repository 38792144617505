import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./pages/login/login.component";
import { MasterComponent } from "./template/master/master.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { MerchantComponent } from "./pages/merchant/merchant.component";
import { MerchantsComponent } from "./pages/merchants/merchants.component";
import { LoginGuard } from "./guards/login.guard";
import { ListCategoriesComponent } from "./pages/list-categories/list-categories.component";
import { CategoryDetailsComponent } from "./pages/category-details/category-details.component";
import { ListCitiesComponent } from "./pages/list-cities/list-cities.component";
import { CityDetailsComponent } from "./pages/city-details/city-details.component";
import { ListCountriesComponent } from "./pages/list-countries/list-countries.component";
import { CountryDetailsComponent } from "./pages/country-details/country-details.component";
import { ListAreasComponent } from "./pages/list-areas/list-areas.component";
import { AreaDetailsComponent } from "./pages/area-details/area-details.component";
import { MerchantAreaDetailsComponent } from "./pages/merchant-area-details/merchant-area-details.component";
import { MerchantAddressDetailsComponent } from "./pages/merchant-address-details/merchant-address-details.component";
import { MerchantPhoneDetailsComponent } from "./pages/merchant-phone-details/merchant-phone-details.component";
import { MerchantWebsiteDetailsComponent } from "./pages/merchant-website-details/merchant-website-details.component";
import {
  MerchantSocialMediaPlatformDetailsComponent
} from "./pages/merchant-social-media-platform-details/merchant-social-media-platform-details.component";
import {
  MerchantGeoLocationDetailsComponent
} from "./pages/merchant-geo-location-details/merchant-geo-location-details.component";
import { ListCurrenciesComponent } from "./pages/list-currencies/list-currencies.component";
import { CurrencyDetailsComponent } from "./pages/currency-details/currency-details.component";
import { ListProductsComponent } from "./pages/list-products/list-products.component";
import { ProductDetailsComponent } from "./pages/product-details/product-details.component";
import { ListUsersComponent } from "./pages/list-users/list-users.component";
import { UserDetailsComponent } from "./pages/user-details/user-details.component";
import {
  ListCustomerSubscriptionComponent
} from "./pages/list-customer-subscription/list-customer-subscription.component";
import { ListClaimsComponent } from "./pages/list-claims/list-claims.component";
import { EditProfileComponent } from "./pages/edit-profile/edit-profile.component";
import { ChangePasswordComponent } from "./pages/change-password/change-password.component";
import { ListMerchantsRatesComponent } from "./pages/list-merchants-rates/list-merchants-rates.component";
import { ListProductsRatesComponent } from "./pages/list-products-rates/list-products-rates.component";

const routes: Routes = [
  {
    path: LoginComponent.navigatorName,
    component: LoginComponent,
  },
  {
    path: LoginComponent.navigatorName + "/:JWTToken",
    component: LoginComponent,
  },
  {
    path: '',
    component: MasterComponent,
    children: [
      { path: '', component: MerchantsComponent },
      {
        path: MerchantsComponent.navigatorName,
        component: MerchantsComponent,
      },
      {
        path: DashboardComponent.navigatorName,
        component: DashboardComponent,
      },
      {
        path: MerchantComponent.navigatorName + "/:id",
        component: MerchantComponent,
      },
      {
        path: MerchantComponent.navigatorName,
        component: MerchantComponent,
      },
      {
        path: ListCategoriesComponent.navigatorName,
        component: ListCategoriesComponent,
      },
      {
        path: CategoryDetailsComponent.navigatorName + "/:id",
        component: CategoryDetailsComponent,
      },
      {
        path: CategoryDetailsComponent.navigatorName,
        component: CategoryDetailsComponent,
      },


      {
        path: ListCitiesComponent.navigatorName,
        component: ListCitiesComponent,
      },
      {
        path: CityDetailsComponent.navigatorName + "/:id",
        component: CityDetailsComponent,
      },
      {
        path: CityDetailsComponent.navigatorName,
        component: CityDetailsComponent,
      },

      {
        path: ListCountriesComponent.navigatorName,
        component: ListCountriesComponent,
      },
      {
        path: CountryDetailsComponent.navigatorName + "/:id",
        component: CountryDetailsComponent,
      },
      {
        path: CountryDetailsComponent.navigatorName,
        component: CountryDetailsComponent,
      },

      {
        path: ListCurrenciesComponent.navigatorName,
        component: ListCurrenciesComponent,
      },
      {
        path: CurrencyDetailsComponent.navigatorName + "/:id",
        component: CurrencyDetailsComponent,
      },
      {
        path: CurrencyDetailsComponent.navigatorName,
        component: CurrencyDetailsComponent,
      },

      {
        path: ListAreasComponent.navigatorName,
        component: ListAreasComponent,
      },
      {
        path: AreaDetailsComponent.navigatorName,
        component: AreaDetailsComponent,
      },
      {
        path: AreaDetailsComponent.navigatorName + "/:id",
        component: AreaDetailsComponent,
      },

      {
        path: MerchantAreaDetailsComponent.navigatorName,
        component: MerchantAreaDetailsComponent,
      },
      {
        path: MerchantAreaDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantAreaDetailsComponent,
      },

      {
        path: MerchantAddressDetailsComponent.navigatorName,
        component: MerchantAddressDetailsComponent,
      },
      {
        path: MerchantAddressDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantAddressDetailsComponent,
      },
      {
        path: MerchantAddressDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantAddressDetailsComponent,
      },

      {
        path: MerchantPhoneDetailsComponent.navigatorName,
        component: MerchantPhoneDetailsComponent,
      },
      {
        path: MerchantPhoneDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantPhoneDetailsComponent,
      },
      {
        path: MerchantPhoneDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantPhoneDetailsComponent,
      },


      {
        path: MerchantWebsiteDetailsComponent.navigatorName,
        component: MerchantWebsiteDetailsComponent,
      },
      {
        path: MerchantWebsiteDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantWebsiteDetailsComponent,
      },
      {
        path: MerchantWebsiteDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantWebsiteDetailsComponent,
      },


      {
        path: MerchantSocialMediaPlatformDetailsComponent.navigatorName,
        component: MerchantSocialMediaPlatformDetailsComponent,
      },
      {
        path: MerchantSocialMediaPlatformDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantSocialMediaPlatformDetailsComponent,
      },
      {
        path: MerchantWebsiteDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantSocialMediaPlatformDetailsComponent,
      },

      {
        path: MerchantGeoLocationDetailsComponent.navigatorName,
        component: MerchantGeoLocationDetailsComponent,
      },
      {
        path: MerchantGeoLocationDetailsComponent.navigatorName + "/:merchantId",
        component: MerchantGeoLocationDetailsComponent,
      },
      {
        path: MerchantGeoLocationDetailsComponent.navigatorName + "/:merchantId/:id",
        component: MerchantGeoLocationDetailsComponent,
      },


      {
        path: ListProductsComponent.navigatorName,
        component: ListProductsComponent,
      },
      {
        path: ProductDetailsComponent.navigatorName + "/:merchantId",
        component: ProductDetailsComponent,
      },
      {
        path: ProductDetailsComponent.navigatorName + "/:merchantId/:id",
        component: ProductDetailsComponent,
      },

      {
        path: ListUsersComponent.navigatorName,
        component: ListUsersComponent,
      },
      {
        path: UserDetailsComponent.navigatorName + "/:id",
        component: UserDetailsComponent,
      },

      {
        path: UserDetailsComponent.navigatorName,
        component: UserDetailsComponent,
      },


      {
        path: ListCustomerSubscriptionComponent.navigatorName,
        component: ListCustomerSubscriptionComponent,
      },
      {
        path: ListClaimsComponent.navigatorName,
        component: ListClaimsComponent,
      },
      {
        path: EditProfileComponent.navigatorName,
        component: EditProfileComponent,
      },  {
        path: ChangePasswordComponent.navigatorName,
        component: ChangePasswordComponent,
      },
      {
        path: ListMerchantsRatesComponent.navigatorName,
        component: ListMerchantsRatesComponent,
      },
      {
        path: ListProductsRatesComponent.navigatorName,
        component: ListProductsRatesComponent,
      },



      // {
      //   path: MerchantAreaDetailsComponent.navigatorName + "/:merchantId/:id",
      //   component: MerchantAreaDetailsComponent,
      // },
      //

      // { path: 'merchant/:id', component: MerchantComponent },
      // { path: 'merchant', component: MerchantsComponent }
    ],
    canActivate: [LoginGuard],
    runGuardsAndResolvers: 'always'
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule],
  providers: [
    LoginGuard,
  ]
})

export class AppRoutingModule {
}

import { Component } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../components/table-view/table-view.component";
import { Product } from "../../business/interfaces/Product";
import { BehaviorSubject, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { ProductAPI } from "../../business/apis/ProductAPI";
import { format } from "date-fns";
import { ProductFilterData } from "../../business/classes/ProductFilterData";
import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { ListProductResponse } from "../../business/interfaces/response-pagination/ListProductResponse";
import {
  CategoryMiniObjectNameId,
  MerchantMiniObjectNameId, ProductMiniObjectNameId,
  ProductNameId
} from "../../business/name-id/ProductNameId";
import { TableAction } from "../../components/table-view/enum/TableAction";
import { Currency } from "../../business/interfaces/Currency";
import { CountryNameId } from "../../business/name-id/CountryNameId";

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss']
})
export class ListProductsComponent {
  static navigatorName: string = NavigatorTarget.products.valueOf();

  tableConfiguration: TableConfiguration<Product> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Product[] = [];
  isScreenLoading = false;

  constructor(
    private sanitizer: DomSanitizer,
    private productApi: ProductAPI,
    private appNavigator: AppNavigator
  ) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let product = item as Product;

    let isDeleted = product.isDeleted ?? false;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let productDetailsItem = {
      title: "Details",
      type: "btn btn-primary",
      iconClass: "bi bi-info-circle",
      actionType: TableAction.DETAILS,
      onTapAction: this.onTapAction.bind(this),
    };


    let actionItems: [boolean, ActionRow][] = [
      [true, productDetailsItem],
      [!isDeleted, deleteItem],
      [!isDeleted, deleteItem],
    ];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {

    let idItem = {
      columId: ProductNameId.ID,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let productSlugItem = {
      columId: ProductNameId.SLUG,
      name: "Product - Slug",
      filterType: ColumnFilterType.text,
    };

    let nameEn = {
      columId: ProductNameId.NAME_EN,
      name: "Name - English",
      filterType: ColumnFilterType.text,
    };

    let nameAr = {
      columId: ProductNameId.NAME_AR,
      name: "Name - Arabic",
      filterType: ColumnFilterType.text,
    };

    let descriptionEn = {
      columId: ProductNameId.DESCRIPTION_EN,
      name: "descriptionEn",
      filterType: ColumnFilterType.text,
    };

    let descriptionAr = {
      columId: ProductNameId.DESCRIPTION_AR,
      name: "descriptionEn",
      filterType: ColumnFilterType.text,
    };


    let merchantId = {
      columId: MerchantMiniObjectNameId.MERCHANT_OBJECT_ID,
      name: "Merchant Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let merchantSlug = {
      columId: MerchantMiniObjectNameId.MERCHANT_SLUG,
      name: "Merchant-Slug",
      filterType: ColumnFilterType.text,
    };

    let merchantNameAr = {
      columId: MerchantMiniObjectNameId.MERCHANT_NAME_Ar,
      name: "Merchant - Arabic",
      filterType: ColumnFilterType.text,
    };

    let merchantNameEn = {
      columId: MerchantMiniObjectNameId.MERCHANT_NAME_EN,
      name: "Merchant - English",
      filterType: ColumnFilterType.text,
    };

    let categoryId = {
      columId: CategoryMiniObjectNameId.CATEGORY_OBJECT_ID,
      name: "Category Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };



    let categoryNameAr = {
      columId: CategoryMiniObjectNameId.CATEGORY_NAME_Ar,
      name: "Category - Arabic",
      filterType: ColumnFilterType.text,
    };

    let CategoryNameEn = {
      columId: CategoryMiniObjectNameId.CATEGORY_NAME_EN,
      name: "Category - English",
      filterType: ColumnFilterType.text,
    };



    let state = {
      columId: ProductNameId.STATE,
      name: "State",
      filterType: ColumnFilterType.text,
    };

    let readyWithin = {
      columId: ProductNameId.READY_WITHIN,
      name: "Ready Within",
      filterType: ColumnFilterType.text,
    };

    let price = {
      columId: ProductNameId.PRICE,
      name: "Price",
      filterType: ColumnFilterType.number,
    };

    let currency = {
      columId: ProductNameId.CURRENCY,
      name: "Currency",
      filterType: ColumnFilterType.text,
    };

    let createdAtItem = {
      columId: CountryNameId.CREATED_AT,
      name: "Date",
      filterType: ColumnFilterType.dateRang,
    };


    return [
      idItem,
      merchantId,
      merchantSlug,
      merchantNameEn,
      merchantNameAr,
      categoryId,
      categoryNameAr,
      CategoryNameEn,
      productSlugItem,
      nameEn,
      nameAr,
      descriptionEn,
      descriptionAr,
      price,
      currency,
      state,
      readyWithin,

      createdAtItem,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == ProductNameId.ID) {
      this.onTapProductDetails(item);
    } else if (id == MerchantMiniObjectNameId.MERCHANT_OBJECT_ID) {
      this.onTapMerchantDetails(item);
    } else if (id == CategoryMiniObjectNameId.CATEGORY_OBJECT_ID) {
      this.onTapCategoryDetails(item);
    }

  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let product = item as Product;
    let value = product[id as keyof Product];
    let styleClass = null;
    if (id == ProductNameId.ID
      || id == MerchantMiniObjectNameId.MERCHANT_OBJECT_ID
      || id == CategoryMiniObjectNameId.CATEGORY_OBJECT_ID) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }else if(id == ProductNameId.PRICE){
      value = value?.toString().toDollars()
    }
    else if(id == MerchantMiniObjectNameId.MERCHANT_OBJECT_ID){
      value = product.merchant.id
    }
    else if(id == MerchantMiniObjectNameId.MERCHANT_SLUG){
      value = product.merchant.slug
    }
    else if(id == MerchantMiniObjectNameId.MERCHANT_NAME_EN){
      value = product.merchant.nameEn
    }
    else if(id == MerchantMiniObjectNameId.MERCHANT_NAME_Ar){
      value = product.merchant.nameAr
    }
    else if(id == CategoryMiniObjectNameId.CATEGORY_OBJECT_ID){
      value = product.category.id
    }
    else if(id == CategoryMiniObjectNameId.CATEGORY_NAME_EN){
      value = product.category.nameEn
    }
    else if(id == CategoryMiniObjectNameId.CATEGORY_NAME_Ar){
      value = product.category.nameAr
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new ProductFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    this.productApi.getList(filter).subscribe({
      next: (response: ListProductResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let productAction = action as TableAction;
    let product = item as Product;
    let call: Observable<any>;

    if (productAction == TableAction.REMOVE) {
      call = this.productApi.delete({ product: product });
    } else if (productAction == TableAction.DETAILS) {
      this.onTapProductDetails(item)
      return;
    }else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        let filter = new ProductFilterData();
        this.reloadDataSource(null);
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
      },
    });
  }

  private onTapProductDetails<T>(item: T) {
    let product = item as Product;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.productDetails,
      id: product.merchant.id,
      id2: product.id,
    });
  }

  private onTapMerchantDetails<T>(item: T) {
    let product = item as Product;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantDetails,
      id: product.merchant.id,
    });
  }

  private onTapCategoryDetails<T>(item: T) {
    let product = item as Product;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.categoryDetails,
      id: product.category.id,
    });
  }


  private reloadTable(response: ListProductResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}


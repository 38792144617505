import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Merchant} from "../../../../business/interfaces/Merchant";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {
  ActionRow,
  FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import {SocialMediaPlatform} from "../../../../business/interfaces/SocialMediaPlatform";
import {ActivatedRoute} from "@angular/router";
import {AppNavigator, NavigatorTarget} from "../../../../services/app-navigator";
import {MerchantAPI} from "../../../../business/apis/MerchantAPI";
import {TableAction} from "../../../../components/table-view/enum/TableAction";
import {SocialMediaPlatformNameId} from "../../../../business/name-id/SocialMediaPlatformNameId";

@Component({
  selector: 'merchant-social-media-platforms',
  templateUrl: './merchant-social-media-platforms.component.html',
  styleUrls: ['./merchant-social-media-platforms.component.css']
})
export class MerchantSocialMediaPlatformsComponent {

  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() reloadData = new EventEmitter<string>();

  merchantDetails: Merchant | null = null;
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<SocialMediaPlatform> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {
  }

  ngOnInit(): void {
    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

    this.loadData!.subscribe((data) => {
      this.merchantDetails = data;
      this.reloadDataViews();
      this.isScreenLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }


  reloadDataViews() {
    let list = this.merchantDetails?.socialMediaPlatforms ?? [];
    this.tableConfiguration.loadData?.next([list, null]);
  }


  getActionRowTable<T>(item: T): ActionRow[] {
    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };
    return [deleteItem]
  }


  getColumTableInfo(): TableColumnInfo[] {


    let URL = {
      columId: SocialMediaPlatformNameId.URL,
      name: "Url",
    };

    let platform = {
      columId: SocialMediaPlatformNameId.PLATForm,
      name: "Platform",
    };


    return [
      URL,
      platform,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == SocialMediaPlatformNameId.ID) {
      this.onTapSocialMediaPlatformDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let socialMediaPlatform = item as SocialMediaPlatform;
    let value = socialMediaPlatform[id as keyof SocialMediaPlatform];
    let styleClass = null;

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantSocialMediaPlatformDetails,
      id: this.merchantDetails?.id,
    });
  }


  private onTapAction<T>(action: any, item: T) {
    let SocialMediaPlatformAction = action as TableAction;
    let SocialMediaPlatform = item as SocialMediaPlatform;
    let call: Observable<any>;

    if (SocialMediaPlatformAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      let socialMediaPlatformId = SocialMediaPlatform.id
      call = this.merchantApi.deleteSocialMediaPlatform({
        merchantId: merchantId,
        socialMediaPlatformId: socialMediaPlatformId
      });
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.emit(this.merchantDetails?.id);
      },
      error: (error: any) => {
      },
    });
  }

  private onTapSocialMediaPlatformDetails<T>(item: T) {
    let SocialMediaPlatform = item as SocialMediaPlatform;
    // this.appNavigator.navigateTo({
    //   target: NavigatorTarget.SocialMediaPlatformDetails,
    //   id: SocialMediaPlatform.id,
    // });
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}


import { Injectable } from "@angular/core";
import { APIClientService } from "../../services/apiclient.service";
import { catchError, Observable, tap, throwError } from "rxjs";
import { User } from "../interfaces/User";
import { APIType } from "../../common/enums/APIType";
import { ListUserResponse } from "../interfaces/response-pagination/ListUserResponse";
import { UserFilterData } from "../classes/UserFilterData";
import { RequestType } from "../../common/enums/RequestType";
import { compare } from "../../common/utilities/General";

@Injectable({ providedIn: 'root' })
export class UserAPI {
  constructor(private readonly apiClient: APIClientService) {

  }


  save(parameters: {
    oldUser: User | null,
    user: User,
  }): Observable<string> {

    let user = parameters.user;
    let oldUser = parameters.oldUser;
    let dataUrl = parameters.user.id ?? "";
    dataUrl = dataUrl.hasActualValue() ? `/${dataUrl}` : "";

    let merchantId = compare(oldUser?.merchantId ?? "",user.merchantId) ?? "";
    let request = {
      password:user.password,
      name: user.name,
      email:user.email,
      countryCode:user.countryCode,
      phoneNumber:user.phoneNumber,
      gender:user.gender,
      isAdmin:user.isAdmin,
      birthDate:user.birthDate,
      merchantId:merchantId.hasValue() ? merchantId : undefined,
    }

    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_USER,
      requestType: RequestType.POST,
      body: request,
      dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  getList(filterData: UserFilterData): Observable<ListUserResponse> {
    let parameter = UserFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListUserResponse>({
      apiType: APIType.GET_ALL_USER,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }


  getDetails(parameters: {
    userId: string,
  }): Observable<User> {
    let dataUrl = parameters.userId;
    return this.apiClient.call<any, User>({
      apiType: APIType.GET_USER_DETAILS,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  delete(parameters: {
    user: User,
  }): Observable<void> {
    let dataUrl = parameters.user.id
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_USER,
      requestType: RequestType.DELETE,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  block(parameters: {
    user: User,
  }): Observable<void> {
    let dataUrl = parameters.user.id
    return this.apiClient.call<any, void>({
      apiType: APIType.BLOCK_USER,
      requestType: RequestType.POST,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  unblock(parameters: {
    user: User,
  }): Observable<void> {
    let dataUrl = parameters.user.id
    return this.apiClient.call<any, void>({
      apiType: APIType.UNBLOCK_USER,
      requestType: RequestType.POST,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  getListForMerchant(parameters: {
    filterData: UserFilterData,
    merchantId: string,
  }): Observable<ListUserResponse> {
    let filterData = parameters.filterData
    let parameter = UserFilterData.getParameterApi(filterData);

    let dataUrl = [parameters.merchantId]
    return this.apiClient.call<any, ListUserResponse>({
      apiType: APIType.Get_MERCHANT_Users,
      requestType: RequestType.GET,
      listDataUrl: dataUrl,
      body: parameter,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }
}

import { OptionSelect } from "../../components/filters/enum/filter-type";

export enum ProductReadyWithin {
  THIRTY_MINUTES = "30MINUTES",
  ONE_HOUR = "1HOUR",
  TWO_HOURS = "2HOURS",
  NEXT_DAY = "NEXT_DAY",
  TWO_DAYS = "2DAYS",
}


export namespace ProductReadyWithinStateHelper {
  export function localize(platform: ProductReadyWithin): string {
    switch (platform) {
      case ProductReadyWithin.THIRTY_MINUTES:
        return '30 MINUTES';
      case ProductReadyWithin.ONE_HOUR:
        return '1 Hour';
      case ProductReadyWithin.TWO_HOURS:
        return '2 Hours';
      case ProductReadyWithin.NEXT_DAY:
        return 'Next Day';
      case ProductReadyWithin.TWO_DAYS:
        return '2 DAYS';
    }
  }

  export function getListOption(): OptionSelect[] {
    const optionSelect: OptionSelect[] = [];
    Object.values(ProductReadyWithin).forEach(value => {
      let enumValue = value as ProductReadyWithin;
      const localizedName = ProductReadyWithinStateHelper.localize(enumValue);
      const item: OptionSelect = { value, label: localizedName };
      optionSelect.push(item);
    });
    return optionSelect;
  }


  export function getOption(value: string): OptionSelect | null {
    for (const item of Object.values(ProductReadyWithin)) {
      if (value === item) {
        const localizedName = ProductReadyWithinStateHelper.localize(item as ProductReadyWithin);
        return { value: item, label: localizedName };
      }
    }
    return null;
  }

}






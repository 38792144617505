import { Component, ViewChild } from "@angular/core";
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { FormGroup, Validators } from "@angular/forms";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { Address } from "../../business/interfaces/Address";
import { City } from "../../business/interfaces/City";

import { CitySearchTextFieldComponent } from "../city-details/component/city-search-text-field/city-search-text-field.component";
import { ActivatedRoute } from "@angular/router";
import { MerchantAPI } from "../../business/apis/MerchantAPI";
import { isFormSubmitted } from "../../common/utilities/FormsHelper";
import { CityNameId } from "../../business/name-id/CityNameId";
import { AddressNameId } from "../../business/name-id/AddressNameId";
import { InputField } from "../interfaces/InputField";
import { getEnumFromValue } from "../../common/utilities/General";
import { InputFieldType } from "../../components/input/enum/InputFieldType";
import { InputType } from "../../components/input/enum/InputType";
import { BehaviorSubject } from "rxjs";
import { CountrySearchTextFieldComponent } from "../country-details/component/country-search-text-field/country-search-text-field.component";
import { CountryNameId } from "../../business/name-id/CountryNameId";

@Component({
  selector: "app-merchant-address-details",
  templateUrl: "./merchant-address-details.component.html",
  styleUrls: ["./merchant-address-details.component.css"],
})
export class MerchantAddressDetailsComponent {
  static navigatorName: string =
    NavigatorTarget.merchantAddressDetails.valueOf();

  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  address: Address | null = null;
  city: City | null = null;

  merchantId: string = "";
  addressId: string = "";

  addressFields: InputField[] = [];

  addressEn!: InputField;
  addressAr!: InputField;
  streetEn!: InputField;
  streetAr!: InputField;
  buildingEn!: InputField;
  buildingAr!: InputField;
  floor!: InputField;
  postCode!: InputField;

  @ViewChild(CountrySearchTextFieldComponent)
  countrySearchTextField?: CountrySearchTextFieldComponent;
  @ViewChild(CitySearchTextFieldComponent)
  citySearchTextField?: CitySearchTextFieldComponent;

  // Enum
  CountryNameId = CountryNameId;
  CityNameId = CityNameId;
  Validators = Validators;

  constructor(
    private activatedRoute: ActivatedRoute,
    private merchantApi: MerchantAPI,
    private appNavigator: AppNavigator
  ) {}

  ngOnInit(): void {
    this.addressFields = this.getInputFields();

    this.activatedRoute.params.subscribe((parameters) => {
      this.merchantId = parameters["merchantId"] ?? "";
      this.addressId = parameters["id"] ?? "";
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setDefaultAddress();
  }

  private getInputFields(): InputField[] {
    this.addressEn = {
      id: AddressNameId.ADDRESS_EN,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Address En",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.addressAr = {
      id: AddressNameId.ADDRESS_AR,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "address Arabic",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.streetEn = {
      id: AddressNameId.STREET_EN,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Street English",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.streetAr = {
      id: AddressNameId.STREET_AR,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Street Arabic",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.buildingEn = {
      id: AddressNameId.BUILDING_EN,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Building English",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.buildingAr = {
      id: AddressNameId.BUILDING_AR,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Building Arabic",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.floor = {
      id: AddressNameId.FLOOR,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Floor",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.postCode = {
      id: AddressNameId.POST_CODE,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Post Code",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    return [
      this.addressEn,
      this.addressAr,
      this.streetEn,
      this.streetAr,
      this.buildingEn,
      this.buildingAr,
      this.floor,
      this.postCode,
    ];
  }

  setDefaultAddress() {
    this.countrySearchTextField?.updateCountrySelected("Egy");
  }

  onSelectCountry(address: Address) {
    this.citySearchTextField?.reset();
    this.citySearchTextField?.loadListWithCountry(address);
  }

  onSelectCity(city: City) {}

  getInputFieldById(id: AddressNameId): InputField | undefined {
    let item = this.addressFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(AddressNameId, id) as AddressNameId;
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(AddressNameId, id) as AddressNameId;
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.saveMerchantAddress();
    }
  }

  saveMerchantAddress() {
    this.restError();
    this.isLoading = true;
    let merchantId = this.merchantId ?? "";

    let address = {
      id: this.addressId,
      addressEn:
        this.getValueFieldById(AddressNameId.ADDRESS_EN)?.toStringValue() ?? "",
      addressAr:
        this.getValueFieldById(AddressNameId.ADDRESS_AR)?.toStringValue() ?? "",
      streetEn:
        this.getValueFieldById(AddressNameId.STREET_EN)?.toStringValue() ?? "",
      streetAr:
        this.getValueFieldById(AddressNameId.STREET_AR)?.toStringValue() ?? "",
      buildingEn:
        this.getValueFieldById(AddressNameId.BUILDING_EN)?.toStringValue() ??
        "",
      buildingAr:
        this.getValueFieldById(AddressNameId.BUILDING_AR)?.toStringValue() ??
        "",
      floor: this.getValueFieldById(AddressNameId.FLOOR)?.toStringValue() ?? "",
      postCode:
        this.getValueFieldById(AddressNameId.POST_CODE)?.toStringValue() ?? "",
      cityId: this.citySearchTextField?.selected?.id ?? "",
    } as Address;

    this.merchantApi
      .addAddress({
        merchantId: merchantId,
        address: address,
      })
      .subscribe({
        next: (item) => {
          this.navigateBack();
        },
        error: (error: any) => {
          this.checkError(error);
          this.isLoading = false;
        },
      });
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {}

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  isCityAvailable(): boolean {
    return this.countrySearchTextField?.selected != null;
  }

  isAreaAvailable(): boolean {
    return this.citySearchTextField?.selected != null;
  }
}

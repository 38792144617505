import {Injectable} from "@angular/core";
import {APIClientService} from "../../services/apiclient.service";
import {catchError, Observable, tap, throwError} from "rxjs";
import {APIType} from "../../common/enums/APIType";
import {RequestType} from "../../common/enums/RequestType";
import {CountryFilterData} from "../classes/CountryFilterData";
import {Country} from "../interfaces/Country";
import {ListCountriesResponse} from "../interfaces/response-pagination/ListCountriesResponse";
import {compare} from "../../common/utilities/General";
import { CustomerSubscriptionFilterData } from "../classes/CustomerSubscriptionFilterData";
import { ListCustomerResponse } from "../interfaces/response-pagination/ListCustomerResponse";


@Injectable({providedIn: 'root'})
export class CustomerAPI {
  constructor(private readonly apiClient: APIClientService) {

  }


  getListSubscription(filterData: CustomerSubscriptionFilterData): Observable<ListCustomerResponse> {
    let parameter = CustomerSubscriptionFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListCustomerResponse>({
      apiType: APIType.GET_ALL_CUSTOMER_SUBSCRIPTION,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }

}

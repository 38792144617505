import {Injectable} from "@angular/core";
import {APIClientService} from "../../services/apiclient.service";
import {catchError, Observable, tap, throwError} from "rxjs";
import {APIType} from "../../common/enums/APIType";
import {RequestType} from "../../common/enums/RequestType";
import {AreaFilterData} from "../classes/AreaFilterData";
import {Area} from "../interfaces/Area";
import {ListAreasResponse} from "../interfaces/response-pagination/ListAreasResponse";


@Injectable({providedIn: 'root'})
export class AreaAPI {
  constructor(private readonly apiClient: APIClientService) {

  }

  save(parameters: {
    area: Area,
  }): Observable<string> {

    let area = parameters.area;
    let id = parameters.area.id ?? "";
    let dataUrl = id.hasActualValue() ? `/${id}` : "";
    let request = {
      nameEn: area.nameEn,
      nameAr: area.nameAr,
      cityId: area.city.id,
    }


    return this.apiClient.call<any, string>({
      apiType: APIType.ADD_AREA,
      requestType: RequestType.POST,
      body: request,
      dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  getList(filterData: AreaFilterData): Observable<ListAreasResponse> {
    let parameter = AreaFilterData.getParameterApi(filterData);
    return this.apiClient.call<any, ListAreasResponse>({
      apiType: APIType.GET_LIST_AREAS,
      body: parameter,
      requestType: RequestType.GET,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error);
      })
    );
  }


  getDetails(parameters: {
    id: string,
  }): Observable<Area> {
    let dataUrl = parameters.id;
    return this.apiClient.call<any, Area>({
      apiType: APIType.GET_AREA_DETAILS,
      requestType: RequestType.GET,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


  delete(parameters: {
    area: Area,
  }): Observable<void> {
    let dataUrl = parameters.area.id
    return this.apiClient.call<any, void>({
      apiType: APIType.DELETE_AREA,
      requestType: RequestType.DELETE,
      dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

}

import { Component } from "@angular/core";
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType,
  FilterTable,
  TableColumnInfo,
  TableConfiguration,
} from "../../components/table-view/table-view.component";
import { BehaviorSubject, Observable } from "rxjs";
import { CategoryFilterData } from "../../business/classes/CategoryFilterData";
import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { CategoryAPI } from "../../business/apis/CategoryAPI";
import { TableAction } from "../../components/table-view/enum/TableAction";
import { MerchantNameId } from "../../business/interfaces/Merchant";
import { Category } from "../../business/interfaces/Category";
import { ListCategoriesResponse } from "../../business/interfaces/response-pagination/ListCategoriesResponse";
import { CategoryNameId } from "../../business/name-id/CategoryNameId";
import { format } from "date-fns";
import { ErrorHandling } from "../../common/classes/ErrorHandling";

@Component({
  selector: "app-list-categories",
  templateUrl: "./list-categories.component.html",
  styleUrls: ["./list-categories.component.scss"],
})
export class ListCategoriesComponent {
  static navigatorName: string = NavigatorTarget.categories.valueOf();

  tableConfiguration: TableConfiguration<Category> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Category[] = [];
  isScreenLoading = false;

  constructor(
    private categoryAPI: CategoryAPI,
    private appNavigator: AppNavigator
  ) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let category = item as Category;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let detailsItem = {
      title: "Details",
      type: "btn btn-primary",
      iconClass: "bi bi-info-circle",
      actionType: TableAction.DETAILS,
      onTapAction: this.onTapAction.bind(this),
    };


    let actionItems: [boolean, ActionRow][] = [
      [true, detailsItem],
      [true, deleteItem],
    ];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {
    let idItem = {
      columId: CategoryNameId.ID,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let nameEn = {
      columId: CategoryNameId.NAME_EN,
      name: "Name - English",
      filterType: ColumnFilterType.text,
    };

    let nameAr = {
      columId: CategoryNameId.NAME_AR,
      name: "Name - Arabic",
      filterType: ColumnFilterType.text,
    };

    let createdAtItem = {
      columId: MerchantNameId.createdAt,
      name: "Date",
      filterType: ColumnFilterType.dateRang,
    };

    return [idItem, createdAtItem, nameEn, nameAr];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == CategoryNameId.ID) {
      this.onTapCategoryDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let category = item as Category;
    let value = category[id as keyof Category];
    let styleClass = null;
    if (id == CategoryNameId.ID) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({ target: NavigatorTarget.categoryDetails });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new CategoryFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    this.categoryAPI.getList(filter).subscribe({
      next: (response: ListCategoriesResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let categoryAction = action as TableAction;
    let category = item as Category;
    let call: Observable<any>;

    if (categoryAction == TableAction.REMOVE) {
      call = this.categoryAPI.delete({ category: category });
    }else if (categoryAction == TableAction.DETAILS) {
      this.onTapCategoryDetails(item)
      return;
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        let filter = new CategoryFilterData();
        this.reloadDataSource(null);
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        ErrorHandling.showError({message:"Can't Delete please check Data."})
      },
    });
  }

  private onTapCategoryDetails<T>(item: T) {
    let category = item as Category;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.categoryDetails,
      id: category.id,
    });
  }

  private reloadTable(response: ListCategoriesResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}

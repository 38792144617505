import { Component } from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../components/table-view/table-view.component";
import {Area} from "../../business/interfaces/Area";
import {BehaviorSubject, Observable} from "rxjs";
import {AreaAPI} from "../../business/apis/AreaAPI";
import {TableAction} from "../../components/table-view/enum/TableAction";
import {AreaNameId} from "../../business/name-id/AreaNameId";
import {MerchantNameId} from "../../business/interfaces/Merchant";
import {format} from "date-fns";
import {AreaFilterData} from "../../business/classes/AreaFilterData";
import {FilterMetaData} from "../../common/classes/FilterMetaData";
import {ListAreasResponse} from "../../business/interfaces/response-pagination/ListAreasResponse";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { CityMiniObjectNameId } from "../../business/name-id/CityNameId";
import { MerchantMiniObjectNameId } from "../../business/name-id/ProductNameId";

@Component({
  selector: 'app-list-areas',
  templateUrl: './list-areas.component.html',
  styleUrls: ['./list-areas.component.scss']
})
export class ListAreasComponent {
  static navigatorName: string = NavigatorTarget.areas.valueOf();

  tableConfiguration: TableConfiguration<Area> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Area[] = [];
  isScreenLoading = false;

  constructor(private areaAPI: AreaAPI, private appNavigator: AppNavigator) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let area = item as Area;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let detailsItem = {
      title: "Details",
      type: "btn btn-primary",
      iconClass: "bi bi-info-circle",
      actionType: TableAction.DETAILS,
      onTapAction: this.onTapAction.bind(this),
    };


    let actionItems: [boolean, ActionRow][] = [
      [true, detailsItem],
      [true, deleteItem],
     ];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {
    let idItem = {
      columId: AreaNameId.ID,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };


    let cityId = {
      columId: CityMiniObjectNameId.ID,
      name: "City - Id",
      filterType: ColumnFilterType.text,
    };

    let cityNameEn = {
      columId: CityMiniObjectNameId.NAME_EN,
      name: "City - English",
      filterType: ColumnFilterType.text,
    };

    let cityNameAr = {
      columId: CityMiniObjectNameId.NAME_AR,
      name: "City - Arabic",
      filterType: ColumnFilterType.text,
    };

    let country = {
      columId: CityMiniObjectNameId.COUNTRY,
      name: "Country",
      filterType: ColumnFilterType.text,
    };

    let nameEn = {
      columId: AreaNameId.NAME_EN,
      name: "Name - English",
      filterType: ColumnFilterType.text,
    };

    let nameAr = {
      columId: AreaNameId.NAME_AR,
      name: "Name - Arabic",
      filterType: ColumnFilterType.text,
    };




    let createdAtItem = {
      columId: MerchantNameId.createdAt,
      name: "Date",
      filterType: ColumnFilterType.dateRang,
    };

    return [idItem,
      country,
      cityId,
      cityNameAr,
      cityNameEn,
      nameEn,
      nameAr,
      createdAtItem,];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == AreaNameId.ID) {
      this.onTapAreaDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let area = item as Area;
    let value = area[id as keyof Area];
    let styleClass = null;
    if (id == AreaNameId.ID) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }

    else if(id == CityMiniObjectNameId.COUNTRY){
      value = area.city.country
    }
    else if(id == CityMiniObjectNameId.ID){
      value = area.city.id
    }
    else if(id == CityMiniObjectNameId.NAME_EN){
      value = area.city.nameEn
    }
    else if(id == CityMiniObjectNameId.NAME_AR){
      value = area.city.nameAr
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({ target: NavigatorTarget.areaDetails });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new AreaFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;

    this.areaAPI.getList(filter).subscribe({
      next: (response: ListAreasResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let areaAction = action as TableAction;
    let area = item as Area;
    let call: Observable<any>;

    if (areaAction == TableAction.REMOVE) {
      call = this.areaAPI.delete({ area: area });
    } else if (areaAction == TableAction.DETAILS) {
      this.onTapAreaDetails(item)
      return;
    }
    else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        let filter = new AreaFilterData();
        this.reloadDataSource(null);
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        ErrorHandling.showError({message:"Can't Delete please check Data."})
      },
    });
  }

  private onTapAreaDetails<T>(item: T) {
    let area = item as Area;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.areaDetails,
      id: area.id,
    });
  }

  private reloadTable(response: ListAreasResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }
}

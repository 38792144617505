import { OptionSelect } from "../../components/filters/enum/filter-type";

export enum ProductState {
  RAW = "RAW",
  COOKED = "COOKED",
  HOT = "HOT",
}


export namespace ProductStateHelper {
  export function localize(platform: ProductState): string {
    switch (platform) {
      case ProductState.RAW:
        return 'Raw';
      case ProductState.COOKED:
        return 'Cooked';
      case ProductState.HOT:
        return 'Hot';
    }
  }

  export function getListOption(): OptionSelect[] {
    const optionSelect: OptionSelect[] = [];
    Object.values(ProductState).forEach(value => {
      let enumValue = value as ProductState;
      const localizedName = ProductStateHelper.localize(enumValue);
      const item: OptionSelect = { value, label: localizedName };
      optionSelect.push(item);
    });
    return optionSelect;
  }

  export function getOption(value: string): OptionSelect | null {
    for (const item of Object.values(ProductState)) {
      if (value === item) {
        const localizedName = ProductStateHelper.localize(item as ProductState);
        return { value: item, label: localizedName };
      }
    }
    return null;
  }

}






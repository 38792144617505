import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { Product } from "../../../../business/interfaces/Product";
import { ActivatedRoute } from "@angular/router";
import { AppNavigator } from "../../../../services/app-navigator";
import { ProductAPI } from "../../../../business/apis/ProductAPI";
import { FileUpload } from "../../../../business/interfaces/FileUpload";
import { Image } from "../../../../business/interfaces/Image";
import { generateFilePaths } from "../../../../common/utilities/General";
import { MediaType } from "../../../../business/enums/MediaType";

@Component({
  selector: 'product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.css']
})
export class ProductImagesComponent {
  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>()

  productDetails: Product | null = null;
  loadDataImageSource?: BehaviorSubject<any>;
  public currentNumberOfImages: number = 0
  public imagesFilePath: string = ""

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private productApi: ProductAPI
  ) {
    this.loadDataImageSource = new BehaviorSubject<any>(null);
  }

  get imageURLs() {
    return '';
  }

  ngOnInit(): void {
    this.loadData!.subscribe((data) => {
      this.productDetails = data;
      let images = this.productDetails?.images ?? [];
      let files = this.mapToFileUpLoadList(images);
      this.loadDataImageSource?.next(files)
      let filePaths = generateFilePaths({
        merchantId:this.productDetails?.merchantId ?? "",
        productId: this.productDetails?.id ?? ""
      });

      this.imagesFilePath = filePaths.imagesProductFilePath ?? "";

    });
  }

  ngOnDestroy(): void {
    this.loadData?.unsubscribe();
    this.loadDataImageSource?.unsubscribe()
  }

  ngAfterViewInit(): void {

  }

  updateViews(value: any) {
    this.onchange?.emit(value)
  }

  onImageUploaded(file: any) {
    let fileUploaded = file as FileUpload;
      let productId = this.productDetails?.id ?? ""
      this.productApi.addImage({productId: productId,
        type:MediaType.IMAGE,
        fileUpload: fileUploaded}).subscribe({
        next: (item) => {
          if (item.hasValue()) {
            fileUploaded.id = item;
          }
        },
        error: (_: any) => {
        },
      });
  }

  onImageRemoved(file: FileUpload) {
    this.productApi.deleteImage({
      productId: this.productDetails?.id ?? "",
      imageId: file.id}).subscribe({
      next: (item) => {
        console.log(item);
      },
      error: (_: any) => {
      },
    });
  }

  updateCurrentNumberOfImages(numberOfImages: number) {
    this.currentNumberOfImages = numberOfImages
  }

  mapToFileUpLoadList(list: Image[]): FileUpload[] {
    const valueReturn: FileUpload[] = list
      .filter(item => item.type === MediaType.IMAGE)
      .map(item => this.mapToFileUpload(item));
    return valueReturn;
  }

  mapToFileUpload(item: Image): FileUpload {
    let file = new FileUpload(null);
    file.uploading = false
    file.id = item.id
    file.remoteURL = item.url
    file.localURL = item.url
    return file;
  }
}

import {Component, EventEmitter, Input, Output} from "@angular/core";
import {OptionSelect} from "./enum/filter-type";

@Component({
  selector: 'select-filter',
  styleUrls: ['./style/number-filter-style.css'], // Specify the URL of your styles file
  template: `
    <div class ="select-filter">
      <select class="form-select form-select-sm" (change)="onSelectChange($event)">
        <option  >All</option>
        <option *ngFor="let option of list" [value]="option.value" [selected]="option.isSelected">{{ option.label }}</option>
      </select>
    </div>
  `
})
export class SelectFilter {
  @Input() list!: OptionSelect[]
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>()

  onSelectChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    console.log('Selected value:', selectedValue);
    if(selectedValue == "All"){
      this.onSelect.emit(null);
    }else {
      this.onSelect.emit(selectedValue);
    }
  }
}

import { ValidationError } from "../../business/interfaces/ValidationError";
import { ValidationErrorType } from "../enums/ValidationErrorType";

export class ErrorHandling {
  list: ValidationError[] = []
  listField: string [] = []
  errorMessage: string = ""

  static configurationError(error: any): ErrorHandling {
    let errorHandling = new ErrorHandling()
    if (Array.isArray(error)) {
      errorHandling.list = error;
    } else if (error.isString()) {
      errorHandling.errorMessage = error;
    } else {
      // errorHandling.errorMessage = errorHandling.getErrorMessageResponse();
      errorHandling.errorMessage = error.toString();
    }
    return errorHandling
  }

  static showError(parameters:{message: string}) {
    alert(parameters.message);
  }


  isFieldsExist(): boolean {
    const stringList = this.list.filter(item => item.fieldName !== null).map(item => item.fieldName);
    const containsCommonItem = this.listField.some(item => stringList.includes(item));
    return containsCommonItem;
  }

  getValidationError(id: string, label: string): ValidationError | null {
    const item = this.list.find(item => item.fieldName === id);
    return item ?? null
  }

  getValidationErrorMessage(id: string, label: string): string {
    const item = this.getValidationError(id, label);
    let message = '';
    if (item) {
      message = this.getErrorField(id, label, item)
    }
    return message;
  }

  getErrorField(id: string, label: string, item: ValidationError) {
    let name = label;
    let errorType = item.errorType;
    let message = ""
    if (errorType == ValidationErrorType.INVALID_FORMAT) {
      message = `Invalid ${name} format.`;
    } else if (errorType == ValidationErrorType.REQUIRED) {
      message = `${name} is required.`
    } else if (errorType == ValidationErrorType.DUPLICATE) {
      message = `${name} is already exist.`
    } else {
      item.uriError;
    }
    return message
  }

  getErrorMessageResponse(): string {
    const stringList = this.list.filter(item => item.fieldName !== null).map(item => item.fieldName);
    const commonItems = this.listField.filter(item => stringList.includes(item));
    const differentItems = this.listField.filter(item => !stringList.includes(item));

    let message = ""
    if (differentItems?.isNotEmpty()) {
      let item = differentItems!.first();
      message = item?.toString() ?? "";
    } else {

    }
    return message
  }
}

<!--<nav id="sidebar" class="sidebar js-sidebar">-->
<!--  <div class="sidebar-content js-simplebar">-->
<!--    <a class="sidebar-brand" href="index.html">-->
<!--      <span class="align-middle">AdminKit</span>-->
<!--    </a>-->

<!--    <ul class="sidebar-nav">-->
<!--      <li class="sidebar-header">-->
<!--        SAMYYYYYYYY PAGE-->
<!--      </li>-->

<!--      <li *ngFor="let item of items" class="sidebar-item" [class.active]="item === selectedItem">-->

<!--        <a class="sidebar-link" (click)="navigateTo(item)">-->
<!--          <i class="align-middle" [attr.data-feather]="icon(item)"></i> <span class="align-middle">{{title(item)}}</span>-->
<!--        </a>-->
<!--      </li>-->


<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="index.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Dashboard</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item active">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="pages-profile.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="user"></i> <span class="align-middle">Profile</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="pages-sign-in.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="log-in"></i> <span class="align-middle">Sign In</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="pages-sign-up.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="user-plus"></i> <span class="align-middle">Sign Up</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="pages-blank.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="book"></i> <span class="align-middle">Blank</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-header">&ndash;&gt;-->
<!--&lt;!&ndash;        Tools & Components&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="ui-buttons.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="square"></i> <span class="align-middle">Buttons</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="ui-forms.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="check-square"></i> <span class="align-middle">Forms</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="ui-cards.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="grid"></i> <span class="align-middle">Cards</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="ui-typography.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="align-left"></i> <span class="align-middle">Typography</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="icons-feather.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="coffee"></i> <span class="align-middle">Icons</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-header">&ndash;&gt;-->
<!--&lt;!&ndash;        Plugins & Addons&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="charts-chartjs.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="bar-chart-2"></i> <span class="align-middle">Charts</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->

<!--&lt;!&ndash;      <li class="sidebar-item">&ndash;&gt;-->
<!--&lt;!&ndash;        <a class="sidebar-link" href="maps-google.html">&ndash;&gt;-->
<!--&lt;!&ndash;          <i class="align-middle" data-feather="map"></i> <span class="align-middle">Maps</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--&lt;!&ndash;      </li>&ndash;&gt;-->
<!--    </ul>-->

<!--&lt;!&ndash;    <div class="sidebar-cta">&ndash;&gt;-->
<!--&lt;!&ndash;      <div class="sidebar-cta-content">&ndash;&gt;-->
<!--&lt;!&ndash;        <strong class="d-inline-block mb-2">Upgrade to Pro</strong>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="mb-3 text-sm">&ndash;&gt;-->
<!--&lt;!&ndash;          Are you looking for more components? Check out our premium version.&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;        <div class="d-grid">&ndash;&gt;-->
<!--&lt;!&ndash;          <a href="upgrade-to-pro.html" class="btn btn-primary">Upgrade to Pro</a>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--&lt;!&ndash;      </div>&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--  </div>-->
<!--</nav>-->






<!--    <div id="sidebar" class="sidebar js-sidebar">-->
<!--      <div class="sidebar-content js-simplebar">-->
<!--        test-->
<!--     </div>-->
<!--    </div>-->

<div>
  <a class="sidebar-brand" href="index.html">
    <span class="align-middle">Akl Bayti</span>
  </a>

  <ul class="sidebar-nav">

    <li class="sidebar-item" [class.active]="NavigationItem.DASHBOARD === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.DASHBOARD)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Dashboard</span>
      </a>
    </li>

    <li class="sidebar-header">
      Pages
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.MERCHANTS === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.MERCHANTS)">
        <i class="align-middle" data-feather="settings"></i> <span class="align-middle">Merchants</span>
      </a>
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.PRODUCTS === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.PRODUCTS)">
        <i class="align-middle" data-feather="settings"></i> <span class="align-middle">Products</span>
      </a>
    </li>


    <li class="sidebar-item" [class.active]="NavigationItem.CUSTOMERS === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.CUSTOMERS)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Customers</span>
      </a>
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.USERS === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.USERS)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Users</span>
      </a>
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.CLAIMS === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.CLAIMS)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Claims</span>
      </a>
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.merchantRates === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.merchantRates)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Merchant Rates</span>
      </a>
    </li>


    <li class="sidebar-item" [class.active]="NavigationItem.productsRates === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.productsRates)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Products Rates</span>
      </a>
    </li>

    <li class="sidebar-header">
      Configurations
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.AREAS === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.AREAS)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Areas</span>
      </a>
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.CURRENCIES === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.CURRENCIES)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Currencies</span>
      </a>
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.COUNTRIES === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.COUNTRIES)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Countries</span>
      </a>
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.CITIES === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.CITIES)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Cities</span>
      </a>
    </li>

    <li class="sidebar-item" [class.active]="NavigationItem.CATEGORIES === selectedItem">
      <a class="sidebar-link" (click)="navigateTo(NavigationItem.CATEGORIES)">
        <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Categories</span>
      </a>
    </li>
  </ul>
</div>


<div class="modal fade modal-background" id="mobileNumberVerificationModal" #mobileNumberVerificationModal tabindex="-1" role="dialog" data-bs-backdrop="static" aria-labelledby="modalLabel" aria-hidden="true" (click)="onContainerClicked($event)">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <form class="login-form">
        <div class="modal-header">
          <h2>Rate</h2>
          <button type="button" class="modal-close-button" (click)="closeModal()">
            <i class="bi bi-x-lg"></i>
          </button>
        </div>

        <div class="modal-body">
          <div>
            <div class="full-width">

              <text-area (onchange)="onTextChange($event)"
                         [floatingLabel]="'Comment'"
                         [group]="form"
                         [id]="'commentId'"
                         class="input-field-layout"
              ></text-area>

            </div>
          </div>
        </div>


        <div class="verify-button-modal-div">
          <div  class="action-button">
            <primary-button
              (onClick)="submit()"
              [title]="'Update'">
            </primary-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>







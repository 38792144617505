import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Merchant} from "../../../../business/interfaces/Merchant";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {
  ActionRow,
  FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import {Phone} from "../../../../business/interfaces/Phone";
import {ActivatedRoute} from "@angular/router";
import {AppNavigator, NavigatorTarget} from "../../../../services/app-navigator";
import {MerchantAPI} from "../../../../business/apis/MerchantAPI";
import {TableAction} from "../../../../components/table-view/enum/TableAction";
import {PhoneNameId} from "../../../../business/name-id/PhoneNameId";

@Component({
  selector: 'merchant-phones',
  templateUrl: './merchant-phones.component.html',
  styleUrls: ['./merchant-phones.component.css']
})
export class MerchantPhonesComponent {

  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() reloadData = new EventEmitter<string>();

  merchantDetails: Merchant | null = null;
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<Phone> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {
  }

  ngOnInit(): void {
    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

    this.loadData!.subscribe((data) => {
      this.merchantDetails = data;
      this.reloadDataViews();
      this.isScreenLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }


  reloadDataViews() {
    let list = this.merchantDetails?.phones ?? [];
    this.tableConfiguration.loadData?.next([list, null]);
  }


  getActionRowTable<T>(item: T): ActionRow[] {
    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };
    return [deleteItem]
  }


  getColumTableInfo(): TableColumnInfo[] {

    let id = {
      columId: PhoneNameId.ID,
      name: "Id",
    };


    let countryCode = {
      columId: PhoneNameId.COUNTRY_CODE,
      name: "Country Code",
    };

    let phoneNumber = {
      columId: PhoneNameId.PHONE_NUMBER,
      name: "Phone Number",
    };


    let hasWhatsapp = {
      columId: PhoneNameId.HAS_WHATSAPP,
      name: "Has Whatsapp",
    };

    let hasTelegram = {
      columId: PhoneNameId.HAS_TELEGRAM,
      name: "Has Telegram",
    };


    return [
      id,
      countryCode,
      phoneNumber,
      hasWhatsapp,
      hasTelegram,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == PhoneNameId.ID) {
      this.onTapPhoneDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let phone = item as Phone;
    let value = phone[id as keyof Phone];
    let styleClass = null;

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantPhoneDetails,
      id: this.merchantDetails?.id,
    });
  }


  private onTapAction<T>(action: any, item: T) {
    let PhoneAction = action as TableAction;
    let Phone = item as Phone;
    let call: Observable<any>;

    if (PhoneAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      let PhoneId = Phone.id
      call = this.merchantApi.deletePhone({merchantId: merchantId, phoneId: PhoneId});
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.emit(this.merchantDetails?.id);
      },
      error: (error: any) => {
      },
    });
  }

  private onTapPhoneDetails<T>(item: T) {
    let Phone = item as Phone;
    // this.appNavigator.navigateTo({
    //   target: NavigatorTarget.PhoneDetails,
    //   id: Phone.id,
    // });
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}


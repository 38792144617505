<div class="custom-input-container">
  <div class="input-text-field-container">
    <div class="form-floating">
      <!-- Search input field -->
      <input
        #myInput
        (blur)="onInputBlur()"
        (focus)="toggleSelect(true)"
        (input)="onSearchChange($event)"
        (keydown)="onKeyDown($event)"
        [(ngModel)]="searchText"
        [ngClass]="isInputValid()"
        [formControl]="control"
        class="form-control"
        id="{{id}}"
        placeholder="{{floatingLabel}}"
        type="text"
      >

      <label for="{{id}}">{{ floatingLabel }}</label>
      <div class="text-danger">{{ errorMessage }}</div>

      <div [style.display]="dropdownOpen ? 'block' : 'none'" class="custom-dropdown-options">
        <div *ngIf="isLoading" class="spinner-container">
          <div class="spinner-border text-primary" role="status">
          </div>
        </div>

        <div *ngIf="!isLoading && filteredOptions.isEmpty()" class="spinner-container">
         NO value
        </div>

        <div (click)="onSelectOption(option)" *ngFor="let option of filteredOptions" class="custom-dropdown-option">
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>


  <div (click)="focusInputField(myInput)" class="trailing-icon">
    <i class="bi bi-caret-down-fill"></i>
  </div>
</div>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {FormControl, FormGroup, ValidatorFn} from "@angular/forms";
import {ErrorHandling} from "../../../common/classes/ErrorHandling";

@Component({
  selector: 'input-picture',
  templateUrl: './input-picture.component.html',
  styleUrls: ['./input-picture.component.scss']
})
export class InputPictureComponent {
  @Input() loadData?: BehaviorSubject<any>;
  @Input() errorHandling: ErrorHandling | null = null
  @Input() title: string | null | undefined = ""
  @Input() id!: string
  @Input() group!: FormGroup
  @Input() validators: ValidatorFn[] = []
  @Input() imagesFilePath: string = ""
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>()

  control: FormControl = new FormControl()
  loadDataImageSource?: BehaviorSubject<any>;


  public currentNumberOfImages: number = 0
  private uploadFilePath: string = ""



  constructor() {
    this.loadDataImageSource = new BehaviorSubject<any>(null);
  }

  ngOnInit(): void {
    let validators = []
    validators.push(...this.validators)
    this.control.setValidators(validators)
    this.group.addControl(this.id, this.control)

    this.loadData?.subscribe(data => {
     this.loadDataImageSource?.next(data)
    })
  }

  ngOnDestroy(): void {
    this.group?.removeControl(this.id)
  }

  onInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.updateViews(value)
  }

  updateViews(value: any) {
    this.onchange?.emit(value)
  }

  onImageUploaded(url: string)
  {
    console.log("Image has been added == " + url)
  }

  onImageRemoved(url: string)
  {
  }

  updateCurrentNumberOfImages(numberOfImages: number)
  {
    this.currentNumberOfImages = numberOfImages
  }

  get imageURLs() {
    return '';
    // return this.moreInfoForm.get('imageURLs') as FormArray;
  }


  get getTitle() {
    return `${this.title}`
  }

  get getErrorMessage() {
    let id = this.id ?? "";
    let title = this.title ?? "";
   return  this.errorHandling?.getValidationErrorMessage(id,title) ?? "";
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CurrencyAPI} from "../../../../business/apis/CurrencyAPI";
import {BehaviorSubject} from "rxjs";
import {Currency} from "../../../../business/interfaces/Currency";
import {CurrencyFilterData} from "../../../../business/classes/CurrencyFilterData";
import {
  ColumnFilterType, SortDirection,
} from "../../../../components/table-view/table-view.component";
import {CurrencyNameId} from "../../../../business/name-id/CurrencyNameId";
import {OptionSelect} from "../../../../components/filters/enum/filter-type";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {FormGroup, ValidatorFn} from "@angular/forms";
import {isString} from "../../../../common/utilities/General";

@Component({
  selector: 'currency-search-text-field',
  templateUrl: './currency-search-text-field.component.html',
  styleUrls: ['./currency-search-text-field.component.css']
})
export class CurrencySearchTextFieldComponent {

  @Input() loadData?: BehaviorSubject<any>;
  @Input() validators: ValidatorFn[] = [];
  @Input() id!: string;
  @Input() errorHandling: ErrorHandling | null = null;
  @Input() group!: FormGroup;

  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

  loadInputData: BehaviorSubject<[any, any]>;
  listData: Currency[] = [];
  selected: Currency | null = null;

  isLoading = false

  constructor(
    private currencyApi: CurrencyAPI,
  ) {
    this.loadInputData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    this.loadData?.subscribe((data) => {
      if(data==null){return}
      if (isString(data)) {
        this.getCurrencyById(data)
      }
    });
    this.reloadDataSource("");
  }

  ngOnDestroy(): void {

  }

  updateCurrencySelected(data:any){
    if (isString(data)) {
      this.getCurrencyById(data)
    }
  }

  getCurrencyById(id: string){
    if(id.hasActualValue()){
      let item = {iso3letter:id}
      this.selected = item
      this.reloadView()
    }
  }

  reloadDataSource(search: string) {
    search = search ?? ""
    let columnInfo = {
      columId: CurrencyNameId.ISO3_LETTER,
      name: "",
      filterType: ColumnFilterType.noun,
    }

    let sortField = {
      tableColumnInfo: columnInfo,
      direction: SortDirection.desc,
    }

    let filterMetaData = {
      itemsPerPage: 1000,
      currentPage: 1,
    }

    let filterFieldTable = {
      tableColumnInfo: columnInfo,
      value: search
    }

    let filter = new CurrencyFilterData();
    filter.filterFieldTable = search?.hasActualValue() ? [filterFieldTable] : [];
    filter.filterMetaData = filterMetaData;
    filter.sortField = sortField;

    this.isLoading = true;

    this.currencyApi.getList(filter).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.listData = response;
        this.reloadView();
      },
      error: (error: any) => {
        // Handle error
        this.isLoading = false;
        console.error("Error:", error);
      },
    });
  }

  onInputChange(event: any): void {
    const selectedValue = event as OptionSelect;
    let value = selectedValue.value
    this.selected = value
    this.onchange.emit(value);
  }

  onSearchChanged(event: any): void {
    // const text = (event.target as HTMLSelectElement).value;
    this.reloadDataSource(event)

  }

  private reloadView() {
    let optionSelected : OptionSelect | null = null
    let list: OptionSelect[] = this.listData.map(item => {
      return {
        value: item,
        label: item.iso3letter,
      };
    });

    if(this.selected){
      optionSelected = {
        value: this.selected,
        label: this.selected.iso3letter,

      }

      setTimeout(() => {
        this.onchange.emit(this.selected);
      }, 200);
    }
    this.loadInputData?.next([list, optionSelected]);
  }

}

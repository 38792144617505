import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";
import {FormGroup, ValidatorFn} from "@angular/forms";
import {Area} from "../../../../business/interfaces/Area";
import {AreaAPI} from "../../../../business/apis/AreaAPI";
import {City} from "../../../../business/interfaces/City";
import {AreaNameId} from "../../../../business/name-id/AreaNameId";
import {ColumnFilterType, SortDirection} from "../../../../components/table-view/table-view.component";
import {AreaFilterData} from "../../../../business/classes/AreaFilterData";
import {DropDownListComponent} from "../../../../components/input/drop-down-list/drop-down-list.component";
import {ListAreasResponse} from "../../../../business/interfaces/response-pagination/ListAreasResponse";
import {OptionSelect} from "../../../../components/filters/enum/filter-type";

@Component({
  selector: 'area-search-text-field',
  templateUrl: './area-search-text-field.component.html',
  styleUrls: ['./area-search-text-field.component.css']
})
export class AreaSearchTextFieldComponent {

  @Input() errorHandling: ErrorHandling | null = null;
  @Input() validators: ValidatorFn[] = [];
  @Input() id!: string;
  @Input() group!: FormGroup;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

  selectedArea: string = "";
  listData: Area[] = [];
  selected: Area | null = null;
  isLoading = false


  @ViewChild(DropDownListComponent) dropDownList?: DropDownListComponent;

  constructor(
    private areaApi: AreaAPI,
  ) {

  }

  ngOnInit(): void {
    this.reloadDataSource("");
  }

  ngOnDestroy(): void {

  }

  loadListWithCity(area: any) {
    if (area.isString()) {
      this.selectedArea = area
      this.reloadDataSource("");
    } else if (area as City) {
      this.selectedArea = area.id
      this.reloadDataSource("");
    }
  }

  reset(){
    this.selectedArea = '';
    this.selected = null;
    this.listData = [];
    this.dropDownList?.reset()
  }


  loadAreaData(area: any) {
    if (area.isString()) {
      this.getAreaById(area)
    }
  }



  getAreaById(id: string) {
    if (id.hasActualValue()) {
      this.areaApi
        .getDetails({id: id})
        .subscribe({
          next: (item: Area) => {
            this.selected = item
            this.reloadView()
            this.onchange.emit(this.selected);
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
          },
        });

    }
  }

  reloadDataSource(search: string) {
    let cityId = this.selectedArea
    let columnNameInfo = {
      columId: AreaNameId.NAME_EN,
      name: "",
      filterType: ColumnFilterType.noun,
    }

    let columnCityInfo = {
      columId: AreaNameId.CITY_ID,
      name: "",
      filterType: ColumnFilterType.noun,
    }

    let sortNameField = {
      tableColumnInfo: columnNameInfo,
      direction: SortDirection.desc,
    }

    let filterNameField = {
      tableColumnInfo: columnNameInfo,
      value: search
    }

    let filterCityField = {
      tableColumnInfo: columnCityInfo,
      value: cityId
    }


    let filterMetaData = {
      itemsPerPage: 0,
      currentPage: 1,
    }


    let filterList = search.hasActualValue() ? [filterNameField] : []
    if (cityId.hasActualValue()) {
      filterList.push(filterCityField)
    }

    let filter = new AreaFilterData();
    filter.filterFieldTable = filterList;
    filter.filterMetaData = filterMetaData;
    filter.sortField = sortNameField;

    this.isLoading = true;

    this.areaApi.getList(filter).subscribe({
      next: (response: ListAreasResponse) => {
        this.isLoading = false;
        this.listData = response.data;
        this.reloadView();
      },
      error: (error: any) => {
        // Handle error
        this.isLoading = false;
        console.error("Error:", error);
      },
    });

  }

  onInputChange(event: any): void {
    const selectedValue = event as OptionSelect;
    let value = selectedValue.value as Area
    this.selected = value;
    this.selectedArea = value.id;
    this.onchange.emit(value);
  }

  onSearchChanged(event: any): void {
    // const text = (event.target as HTMLSelectElement).value;
    this.reloadDataSource(event)

  }

  private reloadView() {
    let optionSelected: OptionSelect | null = null
    let list: OptionSelect[] = this.listData.map(item => {
      return {
        value: item,
        label: item.nameEn,
      };
    });

    if (this.selected) {
      optionSelected = {
        value: this.selected,
        label: this.selected.nameEn,
      }
    }

    this.dropDownList?.updateViews([list, optionSelected])
  }
}


export enum ProductNameId {
  ID = 'id',
  NAME_EN = 'nameEn',
  NAME_AR = 'nameAr',
  DESCRIPTION_EN = 'descriptionEn',
  DESCRIPTION_AR = 'descriptionAr',
  CATEGORY_ID = 'categoryId',
  MERCHANT_ID = 'merchantId',
  STATE = 'state',
  READY_WITHIN = 'readyWithin',
  PRICE = 'price',
  CURRENCY = 'currency',
  THUMBNAIL_URL = 'thumbnailURL',
  IMAGES = 'images',
  CREATED_AT = 'createdAt',
  SLUG = 'slug',

  // MERCHANT_OBJECT_ID = "merchant_id",
  // MERCHANT_SLUG = "slug",
  // MERCHANT_NAME_EN= "merchant_nameEn",
  // MERCHANT_NAME_Ar= "merchant_nameAr",

  // CATEGORY_OBJECT_ID = "category_id",
  // CATEGORY_NAME_EN= "category_nameEn",
  // CATEGORY_NAME_Ar= "category_nameEn",

}

export enum MerchantMiniObjectNameId {
  MERCHANT_OBJECT_ID = "merchant.id",
  MERCHANT_SLUG = "merchant.slug",
  MERCHANT_NAME_EN= "merchant.nameEn",
  MERCHANT_NAME_Ar= "merchant.nameAr",
}


export enum CategoryMiniObjectNameId {
  CATEGORY_OBJECT_ID = "category.id",
  CATEGORY_NAME_EN= "category.nameEn",
  CATEGORY_NAME_Ar= "category.nameAr",
}

export enum ProductMiniObjectNameId {
  PRODUCT_OBJECT_ID = "product.id",
  PRODUCT_NAME_EN= "product.nameEn",
  PRODUCT_NAME_Ar= "product.nameAr",
  PRODUCT_SLUG= "product.slug",
}





import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {isFormSubmitted} from "../../common/utilities/FormsHelper";
import {ValidationErrorType} from "../../common/enums/ValidationErrorType";
import {Field} from "../../common/enums/Field";
import {APIClientService} from "../../services/apiclient.service";
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {LoginAPI} from "../../business/apis/LoginAPI";
import { UserDatabase } from "../../business/database/user-database.service";
import { JWTToken } from "../../business/interfaces/JWTToken";



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{

  static navigatorName: string = NavigatorTarget.login.valueOf();

  public emailErrorMessage: string | null = null
  public passwordErrorMessage: string | null = null
  public serverErrorMessage: string | null = null
  public showPasswordFieldText = false;
  public isLoading: boolean = false
  public loginForm!: FormGroup;

  constructor(
    private loginAPI: LoginAPI,
    private appNavigator: AppNavigator,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiClient: APIClientService,
    private activatedRoute: ActivatedRoute,
    private userDatabase: UserDatabase) {  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      let  JWTToken = params['JWTToken'];
      if(JWTToken?.hasActualValue()){
        this.loginWithJWTToken(JWTToken)
      }
      // Do something with the JWTToken value
    });


    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.maxLength(320)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]]
    });
  }

  loginWithJWTToken (JWTToken:string) {
    const decodedString = decodeURIComponent(JWTToken);
    const jsonObject = JSON.parse(decodedString);
    this.userDatabase.saveJWTToken({jWTToken: jsonObject})
    let jWTToken  = jsonObject as JWTToken;
   let  merchantId = jWTToken.user.merchantId;
    if(merchantId?.hasActualValue()){
      this.appNavigator.navigateTo({
        target: NavigatorTarget.merchantDetails,
        id: merchantId,
      });
    }else {
      this.appNavigator.navigateTo({target: NavigatorTarget.home});
    }
  }


  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }


  togglePasswordShowButtonClicked () {
    console.log("togglePasswordShowButton");
    this.showPasswordFieldText = !this.showPasswordFieldText;
  }

  login2() {
    if(isFormSubmitted(this.loginForm))
    {
      console.log("Logging in.. email = " + this.email?.value + " & password = " + this.password?.value)

      this.isLoading = true

      let email: string = this.email?.value
      let password: string = this.password?.value
      this.apiClient.adminLogin(email, password).subscribe({
        next: () => {
          this.isLoading = false
          this.resetErrorMessages()

          this.router.navigate(["/"])
        },
        error: error => {
          this.isLoading = false
          this.resetErrorMessages()

          if (Array.isArray(error)) {
            // Handle each error separately
            console.log("error is an array")
            error.map(singleError => {
              console.log(singleError.errorType)
              console.log(singleError.field)

              if(singleError.field == Field.EMAIL) {
                if(singleError.errorType == ValidationErrorType.DUPLICATE) {
                  this.emailErrorMessage = "This email is already registered."
                }
                else if(singleError.errorType == ValidationErrorType.INVALID_FORMAT) {
                  this.emailErrorMessage = "Invalid email format."
                }
                else if(singleError.errorType == ValidationErrorType.REQUIRED) {
                  this.emailErrorMessage = "Email is required."
                }
              }
              if(singleError.field == Field.PASSWORD) {
                if(singleError.errorType == ValidationErrorType.INVALID_FORMAT) {
                  this.passwordErrorMessage = "Invalid password format."
                }
                else if(singleError.errorType == ValidationErrorType.REQUIRED) {
                  this.passwordErrorMessage = "Password is required."
                }
              }
            });
          } else {
            console.log("error is NOT an array")
            console.log(error)

            // TODO: Check if type of error is string before printing
            if(error === ValidationErrorType.UNAUTHORIZED)
              this.serverErrorMessage = "Incorrect username and/or password."
            else
              this.serverErrorMessage = "Something went wrong. Please try again later or contact support."
          }
        },
        complete: () => {
          // TODO: Remove if not needed.
        }
      })
    }
  }

  login() {
    if(isFormSubmitted(this.loginForm))
    {
      console.log("Logging in.. email = " + this.email?.value + " & password = " + this.password?.value)

      this.isLoading = true

      let email: string = this.email?.value
      let password: string = this.password?.value
      this.loginAPI.adminLogin({email:email ,password:password}).subscribe({
        next: () => {
          this.isLoading = false
          this.resetErrorMessages()
          this.appNavigator.navigateTo({target: NavigatorTarget.home});
          // this.router.navigate(["/"])
        },
        error: error => {
          this.isLoading = false
          this.resetErrorMessages()

          if (Array.isArray(error)) {
            // Handle each error separately
            console.log("error is an array")
            error.map(singleError => {
              console.log(singleError.errorType)
              console.log(singleError.field)

              if(singleError.field == Field.EMAIL) {
                if(singleError.errorType == ValidationErrorType.DUPLICATE) {
                  this.emailErrorMessage = "This email is already registered."
                }
                else if(singleError.errorType == ValidationErrorType.INVALID_FORMAT) {
                  this.emailErrorMessage = "Invalid email format."
                }
                else if(singleError.errorType == ValidationErrorType.REQUIRED) {
                  this.emailErrorMessage = "Email is required."
                }
              }
              if(singleError.field == Field.PASSWORD) {
                if(singleError.errorType == ValidationErrorType.INVALID_FORMAT) {
                  this.passwordErrorMessage = "Invalid password format."
                }
                else if(singleError.errorType == ValidationErrorType.REQUIRED) {
                  this.passwordErrorMessage = "Password is required."
                }
              }
            });
          } else {
            console.log("error is NOT an array")
            console.log(error)

            // TODO: Check if type of error is string before printing
            if(error === ValidationErrorType.UNAUTHORIZED)
              this.serverErrorMessage = "Incorrect username and/or password."
            else
              this.serverErrorMessage = "Something went wrong. Please try again later or contact support."
          }
        },
        complete: () => {
          // TODO: Remove if not needed.
        }
      })
    }
  }




  resetErrorMessages() {
    this.emailErrorMessage = null
    this.passwordErrorMessage = null
    this.serverErrorMessage = null
  }
}

import { Component } from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {FormGroup, Validators} from "@angular/forms";
import {ErrorHandling} from "../../common/classes/ErrorHandling";
import {GeoLocation} from "../../business/interfaces/GeoLocation";
import {InputField} from "../interfaces/InputField";
import {ActivatedRoute} from "@angular/router";
import {MerchantAPI} from "../../business/apis/MerchantAPI";
import {GeoLocationNameId} from "../../business/name-id/GeoLocationNameId";
import {getEnumFromValue} from "../../common/utilities/General";
import {isFormSubmitted} from "../../common/utilities/FormsHelper";
import {InputFieldType} from "../../components/input/enum/InputFieldType";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-merchant-geo-location-details',
  templateUrl: './merchant-geo-location-details.component.html',
  styleUrls: ['./merchant-geo-location-details.component.css']
})
export class MerchantGeoLocationDetailsComponent {
  static navigatorName: string =
    NavigatorTarget.merchantGeoLocationDetails.valueOf();

  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  geoLocation: GeoLocation | null = null;
  merchantId: string = "";
  geoLocationId: string = "";

  geoLocationFields: InputField[] = [];
  longitudeItem!: InputField;
  latitudeItem!: InputField;


  constructor(
    private activatedRoute: ActivatedRoute,
    private merchantApi: MerchantAPI,
    private appNavigator: AppNavigator
  ) {}

  ngOnInit(): void {
    this.geoLocationFields = this.getInputFields();

    this.activatedRoute.params.subscribe((parameters) => {
      this.merchantId = parameters["merchantId"] ?? "";
      this.geoLocationId = parameters["id"] ?? "";
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {}

  private getInputFields(): InputField[] {
    this.longitudeItem = {
      id: GeoLocationNameId.LONGITUDE,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Longitude",
      value: "",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;


    this.latitudeItem = {
      id: GeoLocationNameId.LATITUDE,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "Latitude",
      value: "",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>(""),
    } as InputField;

    return [this.longitudeItem,this.latitudeItem];
  }

  getInputFieldById(id: GeoLocationNameId): InputField | undefined {
    let item = this.geoLocationFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(GeoLocationNameId, id) as GeoLocationNameId;
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(GeoLocationNameId, id) as GeoLocationNameId;
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.saveMerchantGeoLocation();
    }
  }

  saveMerchantGeoLocation() {
    this.restError();
    this.isLoading = true;
    let merchantId = this.merchantId ?? "";

    let geoLocation = {
      id: this.geoLocationId,
      latitude: this.getValueFieldById(GeoLocationNameId.LATITUDE)?.toString(),
      longitude: this.getValueFieldById(GeoLocationNameId.LONGITUDE)?.toString(),
    } as GeoLocation;

    this.merchantApi
      .addGeoLocation({
        merchantId: merchantId,
        geoLocation: geoLocation,
      })
      .subscribe({
        next: (item) => {
          this.navigateBack();
        },
        error: (error: any) => {
          this.checkError(error);
          this.isLoading = false;
        },
      });
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {}

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

}


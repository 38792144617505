import { Component, ViewChild } from "@angular/core";
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { Area } from "../../business/interfaces/Area";
import { FormGroup, Validators } from "@angular/forms";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { ActivatedRoute } from "@angular/router";
import { AreaNameId } from "../../business/name-id/AreaNameId";
import { isFormSubmitted } from "../../common/utilities/FormsHelper";
import { City } from "../../business/interfaces/City";

import { Country } from "../../business/interfaces/Country";
import { CountryNameId } from "../../business/name-id/CountryNameId";
import { MerchantAPI } from "../../business/apis/MerchantAPI";
import { CitySearchTextFieldComponent } from "../city-details/component/city-search-text-field/city-search-text-field.component";
import { AreaSearchTextFieldComponent } from "../area-details/component/area-search-text-field/area-search-text-field.component";

import { CountrySearchTextFieldComponent } from "../country-details/component/country-search-text-field/country-search-text-field.component";
import { CityNameId } from "../../business/name-id/CityNameId";

@Component({
  selector: "app-merchant-area-details",
  templateUrl: "./merchant-area-details.component.html",
  styleUrls: ["./merchant-area-details.component.css"],
})
export class MerchantAreaDetailsComponent {
  static navigatorName: string = NavigatorTarget.merchantAreaDetails.valueOf();

  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  country: Country | null = null;
  city: City | null = null;

  areaDetails: Area | null = null;
  merchantId: string = "";

  @ViewChild(CountrySearchTextFieldComponent)
  countrySearchTextField?: CountrySearchTextFieldComponent;
  @ViewChild(CitySearchTextFieldComponent)
  citySearchTextField?: CitySearchTextFieldComponent;
  @ViewChild(AreaSearchTextFieldComponent)
  areaSearchTextField?: AreaSearchTextFieldComponent;

  // Enum
  CountryNameId = CountryNameId;
  AreaNameId = AreaNameId;
  CityNameId = CityNameId;
  Validators = Validators;

  constructor(
    private activatedRoute: ActivatedRoute,
    private merchantApi: MerchantAPI,
    private appNavigator: AppNavigator
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((parameters) => {
      this.merchantId = parameters["merchantId"] ?? "";
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setDefaultCountry();
  }

  setDefaultCountry() {
    this.countrySearchTextField?.updateCountrySelected("Egy");
  }

  onSelectCountry(country: Country) {
    this.areaSearchTextField?.reset();
    this.citySearchTextField?.reset();
    this.citySearchTextField?.loadListWithCountry(country);
  }

  onSelectCity(city: City) {
    setTimeout(() => {
      this.areaSearchTextField?.loadListWithCity(city);
    }, 200);
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.saveMerchantArea();
    }
  }

  saveMerchantArea() {
    this.restError();
    this.isLoading = true;
    let merchantId = this.merchantId ?? "";
    let areaId = this.areaSearchTextField?.selected?.id ?? "";
    this.merchantApi
      .addArea({
        merchantId: merchantId,
        areaId: areaId,
      })
      .subscribe({
        next: (item) => {
          this.navigateBack();
        },
        error: (error: any) => {
          this.checkError(error);
          this.isLoading = false;
        },
      });
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {}

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  isCityAvailable(): boolean {
    return this.countrySearchTextField?.selected != null;
  }

  isAreaAvailable(): boolean {
    return this.citySearchTextField?.selected != null;
  }
}

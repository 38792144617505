import { Component, ViewChild } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { Area } from "../../business/interfaces/Area";
import { InputField } from "../interfaces/InputField";
import { FormGroup, Validators } from "@angular/forms";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { ActivatedRoute } from "@angular/router";
import { AreaAPI } from "../../business/apis/AreaAPI";
import { BehaviorSubject, map } from "rxjs";
import { AreaNameId } from "../../business/name-id/AreaNameId";
import { getEnumFromValue } from "../../common/utilities/General";
import { isFormSubmitted } from "../../common/utilities/FormsHelper";
import { InputFieldType } from "../../components/input/enum/InputFieldType";
import { InputType } from "../../components/input/enum/InputType";
import { City } from "../../business/interfaces/City";


import {
  CitySearchTextFieldComponent
} from "../city-details/component/city-search-text-field/city-search-text-field.component";

@Component({
  selector: 'app-area-details',
  templateUrl: './area-details.component.html',
  styleUrls: ['./area-details.component.scss']
})
export class AreaDetailsComponent {

  static navigatorName: string = NavigatorTarget.areaDetails.valueOf();

  areaId: string = "";
  areaDetails: Area | null = null;
  areaFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;

  nameEnglishItem!: InputField;
  nameArabicItem!: InputField;
  cityItem!: InputField;


  @ViewChild(CitySearchTextFieldComponent) citySearchTextField?: CitySearchTextFieldComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private areaApi: AreaAPI,
    private appNavigator: AppNavigator,
  ) {
  }

  ngOnInit(): void {
    this.areaFields = this.getInputFields();

    this.activatedRoute.params
      .pipe(map((parameters) => parameters["id"]))
      .subscribe((id) => {
        this.areaId = id;
        this.reloadDataSource(
          () => {
          },
          () => {
          }
        );
      });

  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }

  getInputFieldById(id: AreaNameId): InputField | undefined {
    let item = this.areaFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(AreaNameId, id) as AreaNameId
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(AreaNameId, id) as AreaNameId
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateArea();
    }
  }

  updateArea() {
    let area = {
      id: this.areaDetails?.id ?? "",
      nameEn:
        this.getValueFieldById(AreaNameId.NAME_EN)?.toStringValue() ?? "",
      nameAr:
        this.getValueFieldById(AreaNameId.NAME_AR)?.toStringValue() ?? "",
      city: (this.cityItem.value as City),
    } as Area;

    console.log(area);
    this.restError();
    this.isLoading = true;
    this.areaApi.save({ area: area }).subscribe({
      next: (item) => {
        console.log(item);
        if (item.hasValue()) {
          this.areaId = item;
        }
        this.navigateBack()
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  reloadDataSource(completionHandler: () => void, errorHandler: () => void) {
    if (this.areaId?.hasActualValue()) {
      this.areaApi
        .getDetails({ id: this.areaId })
        .subscribe({
          next: (item: Area) => {
            // Handle successful data retrieval
            this.areaDetails = item;
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
        });
    } else {
      this.reloadDataViews();
      completionHandler(); // Call the completion handler
    }
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {
    this.getInputFieldById(AreaNameId.NAME_EN)?.loadData?.next(
      this.areaDetails?.nameEn
    );
    this.getInputFieldById(AreaNameId.NAME_AR)?.loadData?.next(
      this.areaDetails?.nameAr
    );
    this.citySearchTextField?.loadCityData(this.areaDetails?.city)

  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }

  private getInputFields(): InputField[] {
    this.nameEnglishItem = {
      id: AreaNameId.NAME_EN,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name En",
      validators: [Validators.required],
      value: "",
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.nameArabicItem = {
      id: AreaNameId.NAME_AR,
      inputFieldType: InputFieldType.textField,
      type: InputType.text,
      floatingLabel: "Name Arabic",
      validators: [Validators.required],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;

    this.cityItem = {
      id: AreaNameId.CITY,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "City",
      validators: [],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;


    return [
      this.nameEnglishItem,
      this.nameArabicItem,
      this.cityItem,
    ];
  }
}


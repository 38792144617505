<div *ngIf="isLoading" class="overlay">
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>


<nav aria-label="breadcrumb" style="--bs-breadcrumb-divider: '>';">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a (click)="navigateToListMerchant()" class="link-primary">List Merchants</a></li>
    <li *ngIf="merchantDetails" aria-current="page" class="breadcrumb-item active">{{merchantDetails.nameEn}}</li>
    <li *ngIf="!merchantDetails" aria-current="page" class="breadcrumb-item active">Add</li>
  </ol>
</nav>
<!-- Show this title only if myObject is null -->
<h1 *ngIf="!merchantDetails" class="h3 mb-3"><strong>Add</strong> Merchant</h1>
<!-- Show this title only if myObject exists (not null) -->
<!--<h1 *ngIf="merchantDetails" class="h3 mb-3"><strong>Update</strong> Merchant</h1>-->
<!-- merchant-basic-info -->

<div class="header-item">
  <h1 *ngIf="merchantDetails" class="h3 mb-3"><strong>Update</strong> Merchant</h1>
  <!--  <h1 *ngIf="productDetails" class="h3 mb-3">add new</h1>-->
  <div *ngIf="merchantDetails" class="button-action-layout">

    <primary-button
      (onClick)="addNewOne()"
      [title]="'Add New'">
    </primary-button>

    <div class="d-grid gap-2 mt-3">
      <button type="submit" class="btn btn-lg btn-success"
              (click)="accept()" *ngIf="showAction()">Approve</button>
    </div>

    <div class="d-grid gap-2 mt-3">
      <button type="submit" class="btn btn-lg btn-danger"
              (click)="reject()" *ngIf="showAction()">Reject</button>
    </div>


  </div>

</div>

<collapsible-header [isOpen]=true title="Basic Info">
  <merchant-basic-info (reloadData)="getMerchant($event)"
                       [loadData]="loadBasicInfoData"></merchant-basic-info>
</collapsible-header>

<div *ngIf="merchantDetails && !merchantDetails.isDeleted">

  <!-- images -->
  <collapsible-header  [isOpen]=false title="Images">
    <merchant-images
      [loadData]="loadBasicInfoData"
    ></merchant-images>
  </collapsible-header>

  <!-- Menu -->
  <collapsible-header  [isOpen]=true title="Menu">
    <merchant-menus
      [loadData]="loadBasicInfoData"
    ></merchant-menus>
  </collapsible-header>


  <!-- video -->
  <collapsible-header  [isOpen]=false title="Videos">
    <merchant-videos
      (reloadData)="getMerchant($event)"
      [loadData]="loadBasicInfoData"
    ></merchant-videos>
  </collapsible-header>



  <!-- products -->
  <collapsible-header [isOpen]=false title="Products">
    <merchant-products (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-products>
  </collapsible-header>

  <!-- products -->
  <collapsible-header [isOpen]=false title="Users">
    <merchant-users (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-users>
  </collapsible-header>



  <!-- Areas -->
  <collapsible-header [isOpen]=false title="Areas">
    <merchant-areas (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-areas>
  </collapsible-header>

  <!-- Address -->
  <collapsible-header [isOpen]=false title="Address">
    <merchant-address (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-address>
  </collapsible-header>

  <!-- Phones -->
  <collapsible-header [isOpen]=false title="Phones">
    <merchant-phones (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-phones>
  </collapsible-header>

  <!-- Websites -->
  <collapsible-header [isOpen]=false title="Websites">
    <merchant-websites (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-websites>
  </collapsible-header>

  <!--  social-media-platforms -->
  <collapsible-header [isOpen]=false title="Social Media Platforms">
    <merchant-social-media-platforms (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-social-media-platforms>
  </collapsible-header>


  <!--  geoLocations -->
  <collapsible-header [isOpen]=false title="GeoLocations">
    <merchant-geoLocations (reloadData)="getMerchant($event)" [loadData]="loadBasicInfoData"></merchant-geoLocations>
  </collapsible-header>


</div>

<!--<div>-->
<!--  <pre>{{ merchantDetails | json }}</pre>-->
<!--</div>-->

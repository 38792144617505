import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class TokenStorage  {

  private apiAccessTokenKey: string = "apiAccessToken";
  private apiRefreshTokenKey: string = "apiRefreshToken";

  updateTokens(data: {
    accessToken?: string | null,
    refreshToken?: string | null,
  }): void {
    if (data.accessToken !== null && data.accessToken !== undefined && data.accessToken.length > 0) {
      localStorage.setItem(this.apiAccessTokenKey, data.accessToken)
    }
    if (data.refreshToken !== null && data.refreshToken !== undefined && data.refreshToken.length > 0) {
     localStorage.setItem(this.apiRefreshTokenKey, data.refreshToken)
    }
  }

  getAccessToken(): string | null {
    return localStorage.getItem(this.apiAccessTokenKey)?.toString() ?? null
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.apiRefreshTokenKey)?.toString() ?? null
  }


  clearTokens(): void {
    localStorage.clear()
  }
}

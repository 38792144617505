import {Injectable} from "@angular/core";
import {APIClientService} from "../../services/apiclient.service";
import {catchError, Observable, tap, throwError} from "rxjs";
import {APIType} from "../../common/enums/APIType";
import {RequestType} from "../../common/enums/RequestType";
import {AreaFilterData} from "../classes/AreaFilterData";
import {Area} from "../interfaces/Area";
import {ListAreasResponse} from "../interfaces/response-pagination/ListAreasResponse";
import { User } from "../interfaces/User";
import { compare } from "../../common/utilities/General";
import { UserFilterData } from "../classes/UserFilterData";
import { ListUserResponse } from "../interfaces/response-pagination/ListUserResponse";


@Injectable({providedIn: 'root'})
export class AccountAPI {
  constructor(private readonly apiClient: APIClientService) {

  }

  getFirebaseToken(): Observable<string> {
    return  this.apiClient.call<{}, string>({
      apiType: APIType.GET_FIREBASE_TOKEN,
      requestType: RequestType.GET
    })
  }


  save(parameters: {
    user: User,
  }): Observable<string> {
    let user = parameters.user;
    let request = {
      name: user.name,
      countryCode: user.phoneNumber?.hasValue() ? user.countryCode : undefined,
      phoneNumber:user.phoneNumber?.hasValue() ? user.phoneNumber : undefined,
      gender: user.gender?.hasValue() ? user.gender : undefined,
      birthDate: user.birthDate?.hasValue() ? user.birthDate : undefined,
    }

    return this.apiClient.call<any, string>({
      apiType: APIType.UPDATE_ACCOUNT_INFO,
      requestType: RequestType.POST,
      body: request,
      // dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }

  updatePassword(parameters: {
    oldPassword: string,
    newPassword: string,
  }): Observable<string> {
    let request = {
      oldPassword: parameters.oldPassword,
      newPassword: parameters.newPassword,
    }

    return this.apiClient.call<any, string>({
      apiType: APIType.UPDATE_PASSWORD,
      requestType: RequestType.POST,
      body: request,
      // dataUrl,
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }




  getAccountInfo(parameters: {
    userId: string,
  }): Observable<User> {
    let dataUrl = parameters.userId;
    return this.apiClient.call<any, User>({
      apiType: APIType.GET_ACCOUNT_INFO,
      requestType: RequestType.GET,
      // dataUrl: dataUrl
    }).pipe(
      tap(response => {
        console.log(response);
      }),
      catchError(error => {
        console.log(error);
        return throwError(() => error)
      })
    )
  }


}

import { Component } from "@angular/core";
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import { Merchant, MerchantNameId } from "../../business/interfaces/Merchant";
import { BehaviorSubject, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";
import { format } from "date-fns";
import {
  ActionRow,
  ColumnFilterType,
  FilterTable,
  TableColumnInfo,
  TableConfiguration,
} from "../../components/table-view/table-view.component";
import { MerchantResponse } from "../../business/interfaces/response-pagination/MerchantResponse";
import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { MerchantFilterData } from "../../business/classes/MerchantFilterData";
import { MerchantAPI } from "../../business/apis/MerchantAPI";
import { TableAction } from "../../components/table-view/enum/TableAction";
import { MerchantStatus } from "../../business/enums/MerchantStatus";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { ValidationError } from "../../business/interfaces/ValidationError";


@Component({
  selector: "app-merchants",
  templateUrl: "./merchants.component.html",
  styleUrls: ["./merchants.component.scss"],
})
export class MerchantsComponent {
  static navigatorName: string = NavigatorTarget.merchants.valueOf();

  tableConfiguration: TableConfiguration<Merchant> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Merchant[] = [];
  isScreenLoading = false;
  filterData: FilterTable | null = null

  constructor(
    private sanitizer: DomSanitizer,
    private merchantApi: MerchantAPI,
    private appNavigator: AppNavigator
  ) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }

  getActionRowTable<T>(item: T): ActionRow[] {
    let merchant = item as Merchant;

    let isDeleted = merchant.isDeleted ?? false;
    let isApproved = merchant.isApproved ?? false;
    let isRejected = merchant.isRejected ?? false;
    let isBlocked = merchant.isBlocked ?? false;

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let merchantDetailsItem = {
      title: "Details",
      type: "btn btn-primary",
      iconClass: "bi bi-info-circle",
      actionType: TableAction.DETAILS,
      onTapAction: this.onTapAction.bind(this),
    };


    let approveItem = {
      title: "Approve",
      type: "btn btn-success",
      iconClass: "bi bi-check-lg",
      actionType: TableAction.APPROVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let rejectItem = {
      title: "Reject",
      type: "btn btn-danger",
      iconClass: "bi bi-x-circle",
      actionType: TableAction.REJECT,
      onTapAction: this.onTapAction.bind(this),
    };

    let blockItem = {
      title: "Block",
      type: "btn btn-warning me-2",
      iconClass: "bi bi-exclamation-lg",
      actionType: TableAction.BLOCK,
      onTapAction: this.onTapAction.bind(this),
    };

    let unBlockItem = {
      title: "UnBlock",
      type: "btn btn-primary",
      iconClass: "bi bi-arrow-counterclockwise",
      actionType: TableAction.UNBLOCK,
      onTapAction: this.onTapAction.bind(this),
    };

    let actionItems: [boolean, ActionRow][] = [
      [true, merchantDetailsItem],
      [!isApproved && !isRejected && !isDeleted, approveItem],
      [!isApproved && !isRejected && !isDeleted, rejectItem],
      [!isBlocked && !isDeleted, blockItem],
      [isBlocked && !isDeleted, unBlockItem],
      [!isDeleted, deleteItem],

    ];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }

  getColumTableInfo(): TableColumnInfo[] {
    let optionBool = [
      {
        value: true,
        label: "true",
        isSelected: false,
      },
      {
        value: "false",
        label: "false",
        isSelected: false,
      },
    ];

    let optionStatus = [
      {
        value: MerchantStatus.APPROVED,
        label: "Approve",
        isSelected: false,
        id: MerchantNameId.isApproved
      },
      {
        value: MerchantStatus.REJECTED,
        label: "Reject",
        isSelected: false,
      },
      {
        value: MerchantStatus.NO_REJECTED_OR_ACCEPTED,
        label: "NO Action",
        isSelected: false,
      },
    ];


    let idItem = {
      columId: MerchantNameId.id,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let nameEn = {
      columId: MerchantNameId.nameEn,
      name: "Name - English",
      filterType: ColumnFilterType.text,
    };

    let nameAr = {
      columId: MerchantNameId.nameAr,
      name: "Name - Arabic",
      filterType: ColumnFilterType.text,
    };

    let slug = {
      columId: MerchantNameId.slug,
      name: "Slug",
      filterType: ColumnFilterType.text,
    };

    let briefEn =
      {
        columId: MerchantNameId.briefEn,
        name: "Brief - English",
        filterType: ColumnFilterType.text,
      };

    let briefAr = {
      columId: MerchantNameId.briefAr,
      name: "Brief - Arabic",
      filterType: ColumnFilterType.text,
    };


    let taxIdentificationNumberItem = {
      columId: MerchantNameId.taxIdentificationNumber,
      name: "Tax Identification Number",
      filterType: ColumnFilterType.text,
    };

    let deliveryAvailableItem = {
      columId: MerchantNameId.deliveryAvailable,
      name: "Delivery Available",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let pickupAvailableItem = {
      columId: MerchantNameId.pickupAvailable,
      name: "Pickup Available",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let createdAtItem = {
      columId: MerchantNameId.createdAt,
      name: "Join Date",
      filterType: ColumnFilterType.dateRang,
    };


    let isDeletedItem = {
      columId: MerchantNameId.isDeleted,
      name: "is Deleted",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let statusItem = {
      columId: MerchantNameId.status,
      name: "Status",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionStatus,
    };


    return [
      idItem,
      nameEn,
      nameAr,
      slug,
      briefEn,
      briefAr,
      taxIdentificationNumberItem,
      deliveryAvailableItem,
      pickupAvailableItem,
      createdAtItem,
      isDeletedItem,
      statusItem,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == MerchantNameId.id) {
      this.onTapMerchantDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let merchant = item as Merchant;
    let value = merchant[id as keyof Merchant];
    let styleClass = null;
    if (id == MerchantNameId.id) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    } else if (id == MerchantNameId.status) {
      value = this.statusForMerchant(merchant)
    }

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({ target: NavigatorTarget.merchantDetails });
  }

  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new MerchantFilterData();
    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    this.merchantApi.getList(filter).subscribe({
      next: (response: MerchantResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        console.error("Error:", error);
      },
    });
  }

  reloadPage() {
    this.reloadDataSource(this.filterData)
  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    let list = errorHandling.list
    let errorMessage = "";
    if (list.length > 0) {
      let firstItem = list.first()
      if (firstItem?.uriError == "/Merchant/slug/empty") {
        errorMessage = "Slug is empty"
      } else if (firstItem?.uriError == "/Merchant/nameAr/empty") {
        errorMessage = "Name Arabic Slug is empty"
      } else {
        errorMessage = firstItem?.uriError ?? ""
      }
    }
    if (errorMessage.hasActualValue()) {
      alert(errorMessage)
    }

  }

  private statusForMerchant(merchant: Merchant): string {
    let isApproved = merchant.isApproved ?? false;
    let isRejected = merchant.isRejected ?? false;
    let status = ""
    if (isApproved) {
      status = status + "IsApproved"
    }
    if (isRejected) {
      status = status + "Rejected"
    }
    return status
  }

  private onTapAction<T>(action: any, item: T) {
    let merchantAction = action as TableAction;
    let merchant = item as Merchant;
    let call: Observable<any>;

    if (merchantAction == TableAction.REMOVE) {
      call = this.merchantApi.delete({ merchant: merchant });
    } else if (merchantAction == TableAction.APPROVE) {
      call = this.merchantApi.approve({ merchant: merchant });
    } else if (merchantAction == TableAction.REJECT) {
      call = this.merchantApi.reject({ merchant: merchant });
    } else if (merchantAction == TableAction.BLOCK) {
      call = this.merchantApi.block({ merchant: merchant });
    } else if (merchantAction == TableAction.UNBLOCK) {
      call = this.merchantApi.unblock({ merchant: merchant });
    } else if (merchantAction == TableAction.DETAILS) {
      this.onTapMerchantDetails(item)
      return;
    }



    else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadDataSource(null);
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        this.checkError(error);
      },
    });
  }

  private onTapMerchantDetails<T>(item: T) {
    let merchant = item as Merchant;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantDetails,
      id: merchant.id,
    });
  }

  private reloadTable(response: MerchantResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.filterData = filterData;
    this.reloadDataSource(filterData);
  }

}

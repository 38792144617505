import {Component} from '@angular/core';
import {AppNavigator, NavigatorTarget} from "../../services/app-navigator";
import {Currency} from "../../business/interfaces/Currency";
import {InputField} from "../interfaces/InputField";
import {FormGroup, Validators} from "@angular/forms";
import {ErrorHandling} from "../../common/classes/ErrorHandling";
import {ActivatedRoute} from "@angular/router";
import {CurrencyAPI} from "../../business/apis/CurrencyAPI";
import {BehaviorSubject, map} from "rxjs";
import {CurrencyNameId} from "../../business/name-id/CurrencyNameId";
import {getEnumFromValue} from "../../common/utilities/General";
import {isFormSubmitted} from "../../common/utilities/FormsHelper";
import {InputFieldType} from "../../components/input/enum/InputFieldType";

@Component({
  selector: 'app-currency-details',
  templateUrl: './currency-details.component.html',
  styleUrls: ['./currency-details.component.css']
})
export class CurrencyDetailsComponent {
  static navigatorName: string = NavigatorTarget.currencyDetails.valueOf();

  currencyId: string = "";
  currencyDetails: Currency | null = null;
  currencyFields: InputField[] = [];
  form: FormGroup = new FormGroup({});
  errorHandling: ErrorHandling | null = null;
  isLoading = false;


  iso3letterItem!: InputField;


  constructor(
    private activatedRoute: ActivatedRoute,
    private currencyApi: CurrencyAPI,
    private appNavigator: AppNavigator,
  ) {
  }

  ngOnInit(): void {
    this.currencyFields = this.getInputFields();
    this.activatedRoute.params
      .pipe(map((parameters) => parameters["id"] ?? ""))
      .subscribe((id) => {
        this.currencyId = id;
      });
  }


  ngOnDestroy(): void {

  }

  ngAfterViewInit(): void {
  }



  private getInputFields(): InputField[] {
    this.iso3letterItem = {
      id: CurrencyNameId.ISO3_LETTER,
      inputFieldType: InputFieldType.textField,
      floatingLabel: "iso3letter",
      validators: [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(3)],
      loadData: new BehaviorSubject<any>([]),
    } as InputField;


    return [
      this.iso3letterItem,
    ];
  }

  getInputFieldById(id: CurrencyNameId): InputField | undefined {
    let item = this.currencyFields.find((field) => field.id === id);
    return item;
  }

  updateInputFieldById(id: string, value: any) {
    let fieldId = getEnumFromValue(CurrencyNameId, id) as CurrencyNameId
    let field = this.getInputFieldById(fieldId);
    if (field != undefined) {
      field.value = value;
    }
  }

  getValueFieldById(id: string): Object {
    let fieldId = getEnumFromValue(CurrencyNameId, id) as CurrencyNameId
    let field = this.getInputFieldById(fieldId);
    return field?.value ?? null;
  }

  submit() {
    if (isFormSubmitted(this.form)) {
      this.updateCurrency();
    }
  }

  updateCurrency() {
    let currency = {
      iso3letter:
        this.getValueFieldById(CurrencyNameId.ISO3_LETTER)?.toStringValue() ?? "",
    } as Currency;


    this.restError();
    this.isLoading = true;
    let id = this.currencyId.hasActualValue() ? this.currencyId : ""
    this.currencyApi.save({id: id, currency: currency}).subscribe({
      next: (item) => {
        console.log(item);
        let currencyId = this.iso3letterItem.value ?? "";
        this.currencyId = currencyId;
        this.navigateBack()
      },
      error: (error: any) => {
        this.checkError(error);
        this.isLoading = false;
      },
    });
  }

  reloadDataSource(completionHandler: () => void, errorHandler: () => void) {
    if (this.currencyId?.hasActualValue()) {
      this.currencyApi
        .getDetails({currencyId: this.currencyId})
        .subscribe({
          next: (item: Currency) => {
            // Handle successful data retrieval
            this.currencyDetails = item;
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
          error: (error: any) => {
            // Handle error
            console.error("Error:", error);
            this.reloadDataViews();
            errorHandler(); // Call the completion handler
          },
        });
    } else {
      this.reloadDataViews();
      completionHandler(); // Call the completion handler
    }
  }

  navigateBack(): void {
    this.appNavigator.back();
  }

  reloadDataViews() {
    this.getInputFieldById(CurrencyNameId.ISO3_LETTER)?.loadData?.next(
      this.currencyDetails?.iso3letter
    );
  }

  checkError(error: any) {
    let errorHandling = ErrorHandling.configurationError(error);
    this.errorHandling = errorHandling;
  }

  restError() {
    this.errorHandling = null;
  }


}

import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { ColumnFilterType, FilterFieldTable, SortFieldTable } from "../../components/table-view/table-view.component";
import { DateRange } from "../../components/filters/date-range-filter.component";
import { mergeObjects } from "../../common/utilities/General";
import {CityNameId} from "../name-id/CityNameId";
import {AreaNameId} from "../name-id/AreaNameId";

export class AreaFilterData {
  filterMetaData: FilterMetaData | null = null
  filterFieldTable: FilterFieldTable[] = []
  sortField: SortFieldTable | null = null

  static getParameterApi(filter: AreaFilterData,) {
    let metaData = FilterMetaData.getParameterApi(filter.filterMetaData!)
    let sortField = FilterMetaData.getParameterSort(filter.sortField)
    let filterFields = this.mapFilterFieldsToList(filter.filterFieldTable)
    const params = mergeObjects(metaData, filterFields,sortField);
    return params;

  }

  static mapFilterFieldsToList(filterFields: FilterFieldTable[]): { [key: string]: any } {
    const result: { [key: string]: any } = {};

    for (const filterField of filterFields) {
      if (filterField.tableColumnInfo.filterType === ColumnFilterType.dateRang) {
        const dateRange = filterField.value as DateRange;
        const fromKey = `filters[from${filterField.tableColumnInfo.columId}]`;
        const toKey = `filters[to${filterField.tableColumnInfo.columId}]`;
        let dateStart = dateRange.start;
        let dateEnd = dateRange.end;
        result[fromKey] = dateStart.toISOString();
        result[toKey] = dateEnd.toISOString();
      } else if(filterField.tableColumnInfo.columId == AreaNameId.CITY){
        const key = `filters[${AreaNameId.CITY_ID}]`;
        result[key] = filterField.value;
      }
      else {
        const key = `filters[${filterField.tableColumnInfo.columId}]`;
        result[key] = filterField.value;
      }
    }
    return result;
  }

}

import { Component, ViewChild } from '@angular/core';
import { AppNavigator, NavigatorTarget } from "../../services/app-navigator";
import {
  ActionRow,
  ColumnFilterType, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../components/table-view/table-view.component";
import {Rate } from "../../business/interfaces/Rate";
import { BehaviorSubject, Observable } from "rxjs";
import { DomSanitizer } from "@angular/platform-browser";

import { TableAction } from "../../components/table-view/enum/TableAction";
import { format } from "date-fns";
import { RateFilterData } from "../../business/classes/RateFilterData";
import { FilterMetaData } from "../../common/classes/FilterMetaData";
import { ListRateResponse } from "../../business/interfaces/response-pagination/ListRateResponse";

import { RateNameId } from "../../business/name-id/RateNameId";
import { RateAPI } from "../../business/apis/RateApi";
import { CommentRateComponent } from "../comment-rate/comment-rate.component";
import { ErrorHandling } from "../../common/classes/ErrorHandling";
import { MerchantMiniObjectNameId, ProductMiniObjectNameId, ProductNameId } from "../../business/name-id/ProductNameId";
import { CityMiniObjectNameId } from "../../business/name-id/CityNameId";

@Component({
  selector: 'app-list-products-rates',
  templateUrl: './list-products-rates.component.html',
  styleUrls: ['./list-products-rates.component.scss']
})
export class ListProductsRatesComponent {

  static navigatorName: string = NavigatorTarget.listProductsRates.valueOf();

  tableConfiguration: TableConfiguration<Rate> = {};
  loadData: BehaviorSubject<[any, any]>;
  listData: Rate[] = [];
  isScreenLoading = false;

  @ViewChild(CommentRateComponent)
  commentRateComponent?: CommentRateComponent;

  constructor(
    private sanitizer: DomSanitizer,
    private rateAPI: RateAPI,
    private appNavigator: AppNavigator
  ) {
    this.loadData = new BehaviorSubject<[any, any]>([[], null]);
  }

  ngOnInit(): void {
    let pagePagination = {
      show: true,
      list: [5, 10, 15, 20],
      selectedItemsPerPage: 15,
    };

    this.tableConfiguration = {
      pagePagination: pagePagination,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };
  }



  getActionRowTable<T>(item: T): ActionRow[] {
    let rate = item as Rate;

    let isDeleted = rate.isDeleted ?? false;
    let isApproved = rate.isApproved ?? false;
    let isRejected = rate.isRejected ?? false;
    let isRepliedTo = rate.isRepliedTo ?? false;

    let isReply = rate.reply?.hasActualValue() ?? false;
    let isComment = rate.comment?.hasActualValue();

    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let commentItem = {
      title: "",
      type: "btn btn-primary",
      iconClass: "bi bi-chat-square-text",
      actionType: TableAction.EDIT,
      onTapAction: this.onTapAction.bind(this),
    };

    let removeCommentItem = {
      title: "Remove",
      type: "btn btn-danger",
      iconClass: "bi bi-chat-square-text",
      actionType: TableAction.removeCommit,
      onTapAction: this.onTapAction.bind(this),
    };



    let approveItem = {
      title: "Approve",
      type: "btn btn-success",
      iconClass: "bi bi-check-lg",
      actionType: TableAction.APPROVE,
      onTapAction: this.onTapAction.bind(this),
    };

    let rejectItem = {
      title: "Reject",
      type: "btn btn-danger",
      iconClass: "bi bi-x-circle",
      actionType: TableAction.REJECT,
      onTapAction: this.onTapAction.bind(this),
    };

    let actionItems: [boolean, ActionRow][] = [
      [!isApproved && !isRejected && !isDeleted, approveItem],
      [!isApproved && !isRejected && !isDeleted, rejectItem],
      [!isDeleted, deleteItem],
      // [!isDeleted, commentItem],
      [isReply && !isDeleted, removeCommentItem],
    ];

    // Filter out tuples where the condition is false and map the remaining tuples to their respective ActionRow objects
    return actionItems
      .filter(([condition]: [boolean, ActionRow]) => condition)
      .map(([, actionRow]: [boolean, ActionRow]) => actionRow);
  }


  getColumTableInfo(): TableColumnInfo[] {
    let optionBool = [
      {
        value: true,
        label: "true",
        isSelected: false,
      },
      {
        value: "false",
        label: "false",
        isSelected: false,
      },
    ];


    let productIdItem = {
      columId: RateNameId.productId,
      name: "Product Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let idItem = {
      columId: RateNameId.id,
      name: "Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };




    let ratingItem = {
      columId: RateNameId.rating,
      name: "Rating",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let commentItem = {
      columId: RateNameId.comment,
      name: "comment",
      filterType: ColumnFilterType.text,
    };

    let commenterIdItem = {
      columId: RateNameId.commenterId,
      name: "Commenter Id",
      filterType: ColumnFilterType.text,
    };

    let commenterNameIdItem = {
      columId: RateNameId.commenterName,
      name: "commenter Name",
      filterType: ColumnFilterType.text,
    };


    let createdAtItem = {
      columId: RateNameId.createdAt,
      name: "Join Date",
      filterType: ColumnFilterType.dateRang,
    };

    let replyItem = {
      columId: RateNameId.reply,
      name: "Reply",
      filterType: ColumnFilterType.text,
    };


    let isDeletedItem = {
      columId: RateNameId.isDeleted,
      name: "is Deleted",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let isRejectedItem = {
      columId: RateNameId.isRejected,
      name: "is Rejected",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };

    let isApprovedItem = {
      columId: RateNameId.isApproved,
      name: "is Approved",
      filterType: ColumnFilterType.select,
      listSelectedOption: optionBool,
    };


    let productId = {
      columId: ProductMiniObjectNameId.PRODUCT_OBJECT_ID,
      name: "Product Id",
      filterType: ColumnFilterType.text,
      isCellSelected: true,
    };

    let productSlugItem = {
      columId: ProductMiniObjectNameId.PRODUCT_SLUG,
      name: "Product - Slug",
      filterType: ColumnFilterType.text,
    };


    let productNameAr = {
      columId: ProductMiniObjectNameId.PRODUCT_NAME_Ar,
      name: "Product - Arabic",
      filterType: ColumnFilterType.text,
    };

    let productNameEn = {
      columId: ProductMiniObjectNameId.PRODUCT_NAME_EN,
      name: "Product - English",
      filterType: ColumnFilterType.text,
    };




    return [

      idItem,
      productId,
      productSlugItem,
      productNameAr,
      productNameEn,
      ratingItem,
      commentItem,
      commenterNameIdItem,
      commenterIdItem,
      replyItem,
      isDeletedItem,
      isRejectedItem,
      isApprovedItem,
      createdAtItem,
    ];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == RateNameId.id) {
      this.onTapRateDetails(item);
    }
    if (id == RateNameId.productId) {
      this.onTapProductIdDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let rate = item as Rate;
    let value = rate[id as keyof Rate];
    let styleClass = null;

    if (id == RateNameId.id || id == RateNameId.productId ) {
      styleClass = "link-primary cursor-pointer";
    }

    if (
      column.filterType == ColumnFilterType.date ||
      column.filterType == ColumnFilterType.dateRang
    ) {
      const date = new Date(value as string);
      value = format(date, "MMM dd, yyyy, HH:mm:ss");
    }

   else if (id == RateNameId.commenterName) {
      value = rate.commenter.name
    }

    if (id == RateNameId.commenterId) {
      value = rate.commenter.id
    }  else if(id == ProductMiniObjectNameId.PRODUCT_OBJECT_ID){
      value = rate.product?.id
    }
    else if(id == ProductMiniObjectNameId.PRODUCT_NAME_EN){
      value = rate.product?.nameEn ?? ""
    }
    else if(id == ProductMiniObjectNameId.PRODUCT_NAME_Ar){
      value = rate.product?.nameAr ?? ""
    }
    else if(id == ProductMiniObjectNameId.PRODUCT_SLUG){
      value = rate.product?.slug ?? ""
    }

    return {
      value: value,
      class: styleClass,
    };
  }



  reloadDataSource(filterData?: FilterTable | null) {
    let filter = new RateFilterData();

    filter.filterFieldTable = filterData?.filterFieldTable ?? [];
    filter.filterMetaData = filterData?.filterMetaData ?? new FilterMetaData();
    filter.sortField = filterData?.sortFieldTable ?? null;
    this.isScreenLoading = true;
    this.rateAPI.getAllProductsRate(filter).subscribe({
      next: (response: ListRateResponse) => {
        this.reloadTable(response);
        this.isScreenLoading = false;
      },
      error: (error: any) => {
        // Handle error
        this.isScreenLoading = false;
        this.checkError(error);
        console.error("Error:", error);
      },
    });
  }

  private onTapAction<T>(action: any, item: T) {
    let rateAction = action as TableAction;
    let rate = item as Rate;
    let call: Observable<any>;

    if (rateAction == TableAction.REMOVE) {
      call = this.rateAPI.deleteRate({ rate: rate });
    } else if (rateAction == TableAction.removeCommit) {
      call = this.rateAPI.removeReplayRate({ rate: rate });
    }else if (rateAction == TableAction.REJECT) {
      call = this.rateAPI.reject({ rate: rate });
    } else if (rateAction == TableAction.APPROVE) {
      call = this.rateAPI.approve({ rate: rate });
    } else if (rateAction == TableAction.EDIT) {
       this.showEdit(rate)
       return;
    }
    else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        let filter = new RateFilterData();
        this.reloadDataSource(null);
      },
      error: (error: any) => {
        this.checkError(error);
        this.isScreenLoading = false;
      },
    });
  }

  private showEdit(rate: Rate,){
    this.commentRateComponent?.showScreen({text:rate.comment})
    this.commentRateComponent?.onSuccess.subscribe((text) => {
      this.isScreenLoading = true;
      let call: Observable<any>;
      call = this.rateAPI.addReplayRate({ rate: rate,message:text });
      call.subscribe({
        next: () => {
          let filter = new RateFilterData();
          this.reloadDataSource(null);
        },
        error: (error: any) => {
          this.checkError(error);
          this.isScreenLoading = false;
        },
      });
    });
  }

  private onTapRateDetails<T>(item: T) {
    let rate = item as Rate;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.rateDetails,
      id: rate.id,
    });
  }

  private onTapProductIdDetails<T>(item: T) {
    let rate = item as Rate;

    this.appNavigator.navigateTo({
      target: NavigatorTarget.productDetails,
      id: "id",
      id2: rate.productId,
    });

  }



  private reloadTable(response: ListRateResponse) {
    this.listData = response.data;
    let metaData = response.meta;
    this.tableConfiguration.loadData?.next([this.listData, metaData]);
  }

  private reloadDataTable<T>(filterData: FilterTable) {
    this.reloadDataSource(filterData);
  }

 private checkError(error: any) {
   alert(error)
    // let errorHandling = ErrorHandling.configurationError(error);
    // let list = errorHandling.list
    // let errorMessage = "";
    // if (list.length > 0) {
    //   let firstItem = list.first()
    //    errorMessage = firstItem?.uriError ?? ""
    // }
    // if (errorMessage.hasActualValue()) {
    //   alert(errorMessage)
    // }
  }
}


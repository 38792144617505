<!-- Show this title only if myObject exists (not null) -->
<h1 *ngIf="userDetails" class="h3 mb-3"><strong>Update</strong> Profile</h1>



<div class="fields-layout">









  <!-- old passwordItem -->
  <input-text-field (onchange)="updateOldPassword($event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="'Old Password'"
                    [group]="form"
                    [id]="'password'"
                    [inputType]="InputType.text"
                    [validators]="[Validators.required]"
                    class="input-field-layout"
  ></input-text-field>

  <!-- new passwordItem -->
  <input-text-field (onchange)="updateNewPassword($event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="'New Password'"
                    [group]="form"
                    [id]="'newPassword'"
                    [inputType]="InputType.text"
                    [validators]="[Validators.required]"
                    class="input-field-layout"
  ></input-text-field>


</div>

<div class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="'Update'">
  </primary-button>
</div>

<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>







<ng-container>
  <h1 class="h3 mb-3"><strong>List</strong> Merchant Rate</h1>
</ng-container>
<div  class="list-layout">
  <div class="header-action-item">
    <button class="btn btn-primary" onclick="location.reload();">
      <i class="bi bi-arrow-clockwise"></i>
    </button>
  </div>
  <table-view
    [isLoading] = isScreenLoading
    [tableConfiguration] = "tableConfiguration">
  </table-view>

  <comment-rate> </comment-rate>
</div>

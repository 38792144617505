<!--<button (keyup.enter)="onClick.emit()"-->
<!--        (click)="onClick.emit()" class="btn btn-primary" type="button" [ngClass]="isButtonEnabled()">-->
<!--  <div class="button-style">-->
<!--    <img *ngIf="iconType !== null" src="{{icon}}" alt="" class="icon-size"/>-->
<!--    {{title}}-->
<!--    <div *ngIf="loading" class="spinner-border" role="status">-->
<!--    </div>-->
<!--  </div>-->
<!--</button>-->



<div class="d-grid gap-2 mt-3">
  <button type="submit" class="btn btn-lg btn-primary" [ngClass]="isButtonEnabled()"
          (click)="onClick.emit()" *ngIf="!loading">{{ title }}</button>

  <button class="btn btn-primary" type="button" disabled *ngIf="loading">
    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
    <span class="visually-hidden" role="status">Loading...</span>
  </button>
</div>

<div class="fields-layout">
  <!-- nameEnglishItem -->
  <input-text-field (onchange)="updateInputFieldById(nameEnglishItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameEnglishItem.floatingLabel"
                    [group]="form"
                    [id]="nameEnglishItem.id.toString()"
                    [inputType]="nameEnglishItem.type"
                    [loadData]="nameEnglishItem.loadData"
                    [validators]="nameEnglishItem.validators"
                    class="input-field-layout"
  ></input-text-field>
  <!-- nameArabicItem -->
  <input-text-field (onchange)="updateInputFieldById(nameArabicItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameArabicItem.floatingLabel"
                    [group]="form"
                    [id]="nameArabicItem.id.toString()"
                    [inputType]="nameArabicItem.type"
                    [loadData]="nameArabicItem.loadData"
                    [validators]="nameArabicItem.validators"
                    class="input-field-layout"
  ></input-text-field>
  <!-- briefEnglishItem -->
  <text-area (onchange)="updateInputFieldById(briefEnglishItem.id.toString(), $event)"
             [errorHandling]="errorHandling"
             [floatingLabel]="briefEnglishItem.floatingLabel"
             [group]="form"
             [id]="briefEnglishItem.id.toString()"
             [loadData]="briefEnglishItem.loadData"
             [validators]="briefEnglishItem.validators"
             class="input-field-layout"
  ></text-area>
  <!-- briefArabicItem -->
  <text-area
    (onchange)="updateInputFieldById(briefArabicItem.id.toString(), $event)"
    [errorHandling]="errorHandling"
    [floatingLabel]="briefArabicItem.floatingLabel"
    [group]="form"
    [id]="briefArabicItem.id.toString()"
    [loadData]="briefArabicItem.loadData"
    [validators]="briefArabicItem.validators"
    class="input-field-layout"
  ></text-area>
  <!-- taxIdentificationNumberItem -->
  <input-text-field
    (onchange)="updateInputFieldById(taxIdentificationNumberItem.id.toString(), $event)"
    [errorHandling]="errorHandling"
    [floatingLabel]="taxIdentificationNumberItem.floatingLabel"
    [group]="form"
    [id]="taxIdentificationNumberItem.id.toString()"
    [inputType]="taxIdentificationNumberItem.type"
    [loadData]="taxIdentificationNumberItem.loadData"
    [validators]="taxIdentificationNumberItem.validators"
    class="input-field-layout"
  ></input-text-field>


  <!-- slugItem -->
  <input-text-field (onchange)="updateInputFieldById(slugItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="slugItem.floatingLabel"
                    [group]="form"
                    [id]="slugItem.id.toString()"
                    [inputType]="slugItem.type"
                    [isReadOnly]="canUpdateSlug"
                    [loadData]="slugItem.loadData"
                    [validators]="slugItem.validators"
                    class="input-field-layout"
  ></input-text-field>

  <!-- profilePictureItem -->
  <input-picture *ngIf="merchantDetails"
                 (onchange)="updateInputFieldById(profilePictureItem.id.toString(), $event)"
                 [errorHandling]="errorHandling"
                 [group]="form"
                 [id]="profilePictureItem.id.toString()"
                 [imagesFilePath]="logoFilePath"
                 [loadData]="profilePictureItem.loadData"
                 [title]="profilePictureItem.label"
                 [validators]="profilePictureItem.validators"
                 class="input-field-layout">
  </input-picture>
  <!-- coverPhotoItem -->
  <input-picture *ngIf="merchantDetails"
                 (onchange)="updateInputFieldById(coverPhotoItem.id.toString(), $event)"
                 [errorHandling]="errorHandling"
                 [group]="form"
                 [id]="coverPhotoItem.id.toString()"
                 [imagesFilePath]="coverPhotoFilePath"
                 [loadData]="coverPhotoItem.loadData"
                 [title]="coverPhotoItem.label"
                 [validators]="coverPhotoItem.validators"
                 class="input-field-layout">
  </input-picture>

  <!-- deliveryAvailableItem -->
  <check-box
    (onchange)="updateInputFieldById(deliveryAvailableItem.id.toString(), $event)"
    [group]="form"
    [id]="deliveryAvailableItem.id.toString()"
    [label]="deliveryAvailableItem.label"
    [loadData]="deliveryAvailableItem.loadData"
    [validators]="deliveryAvailableItem.validators"
  ></check-box>
  <!-- pickupAvailableItem -->
  <check-box
    (onchange)="updateInputFieldById(pickupAvailableItem.id.toString(), $event)"
    [group]="form"
    [id]="pickupAvailableItem.id.toString()"
    [label]="pickupAvailableItem.label"
    [loadData]="pickupAvailableItem.loadData"
    [validators]="pickupAvailableItem.validators"></check-box>

</div>
<div *ngIf="!(merchantDetails?.isDeleted ?? false)" class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="merchantDetails ? 'Update' : 'Add'">
  </primary-button>
</div>
<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>

<div class="fields-layout">
  <!-- nameEnglishItem -->
  <input-text-field (onchange)="updateInputFieldById(nameEnglishItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameEnglishItem.floatingLabel"
                    [group]="form"
                    [id]="nameEnglishItem.id.toString()"
                    [inputType]="nameEnglishItem.type"
                    [loadData]="nameEnglishItem.loadData"
                    [validators]="nameEnglishItem.validators"
                    class="input-field-layout"
  ></input-text-field>
  <!-- nameArabicItem -->
  <input-text-field (onchange)="updateInputFieldById(nameArabicItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="nameArabicItem.floatingLabel"
                    [group]="form"
                    [id]="nameArabicItem.id.toString()"
                    [inputType]="nameArabicItem.type"
                    [loadData]="nameArabicItem.loadData"
                    [validators]="nameArabicItem.validators"
                    class="input-field-layout"
  ></input-text-field>
  <!-- descriptionEnglishItem -->
  <text-area (onchange)="updateInputFieldById(descriptionEnglishItem.id.toString(), $event)"
             [errorHandling]="errorHandling"
             [floatingLabel]="descriptionEnglishItem.floatingLabel"
             [group]="form"
             [id]="descriptionEnglishItem.id.toString()"
             [loadData]="descriptionEnglishItem.loadData"
             [validators]="descriptionEnglishItem.validators"
             class="input-field-layout"
  ></text-area>
  <!-- descriptionArabicItem -->
  <text-area
    (onchange)="updateInputFieldById(descriptionArabicItem.id.toString(), $event)"
    [errorHandling]="errorHandling"
    [floatingLabel]="descriptionArabicItem.floatingLabel"
    [group]="form"
    [id]="descriptionArabicItem.id.toString()"
    [loadData]="descriptionArabicItem.loadData"
    [validators]="descriptionArabicItem.validators"
    class="input-field-layout"
  ></text-area>


  <!-- readyWithinItem -->
  <drop-down-list (onSelected)="updateInputFieldById(readyWithinItem.id.toString(), $event)"
                  [dataList]=getListProductReadyWithin()
                  [errorHandling]="errorHandling"
                  [floatingLabel]=readyWithinItem.floatingLabel
                  [group]="form"
                  [id]=readyWithinItem.id
                  [loadSelected]=readyWithinItem.loadData
                  [validators]=readyWithinItem.validators
                  class="input-field-layout"></drop-down-list>


  <!-- priceItem -->
  <input-text-field
    (onchange)="updateInputFieldById(priceItem.id.toString(), $event)"
    [errorHandling]="errorHandling"
    [floatingLabel]="priceItem.floatingLabel"
    [group]="form"
    [id]="priceItem.id.toString()"
    [inputType]="priceItem.type"
    [loadData]="priceItem.loadData"
    [validators]="priceItem.validators"
    class="input-field-layout"></input-text-field>


  <!-- CategoryItem -->
  <category-search-text-field (onchange)="updateInputFieldById(categoryItem.id.toString(), $event)"
                              [errorHandling]="errorHandling"
                              [group]="form"
                              [id]="categoryItem.id.toString()"
                              [loadData]="categoryItem.loadData"
                              class="input-field-layout">
  </category-search-text-field>


  <!-- currency -->
  <currency-search-text-field (onchange)="updateInputFieldById(currencyItem.id.toString(), $event)"
                              [errorHandling]="errorHandling"
                              [group]="form"
                              [id]="currencyItem.id.toString()"
                              [loadData]="currencyItem.loadData"
                              class="input-field-layout">
  </currency-search-text-field>

  <!-- productStateItem -->
  <drop-down-list (onSelected)="updateInputFieldById(productStateItem.id.toString(), $event)"
                  [dataList]=getListProductState()
                  [errorHandling]="errorHandling"
                  [floatingLabel]=productStateItem.floatingLabel
                  [group]="form"
                  [id]=productStateItem.id
                  [loadSelected]=productStateItem.loadData
                  [validators]=productStateItem.validators
                  class="input-field-layout"></drop-down-list>


  <!-- slugItem -->
  <input-text-field (onchange)="updateInputFieldById(slugItem.id.toString(), $event)"
                    [errorHandling]="errorHandling"
                    [floatingLabel]="slugItem.floatingLabel"
                    [group]="form"
                    [id]="slugItem.id.toString()"
                    [inputType]="slugItem.type"
                    [isReadOnly]="true"
                    [loadData]="slugItem.loadData"
                    [validators]="slugItem.validators"
                    class="input-field-layout"
  ></input-text-field>

  <!-- thumbnailItem -->
  <input-picture (onchange)="updateInputFieldById(thumbnailItem.id.toString(), $event)"
                 [errorHandling]="errorHandling"
                 [group]="form"
                 [id]="thumbnailItem.id.toString()"
                 [imagesFilePath]="thumbnailProductFilePath"
                 [loadData]="thumbnailItem.loadData"
                 [title]="thumbnailItem.label"
                 [validators]="thumbnailItem.validators"
                 class="full-width">
    </input-picture>

</div>
<div *ngIf="!(productDetails?.isDeleted ?? false)" class="action-button">
  <primary-button
    (onClick)="submit()"
    [loading]="isLoading"
    [title]="productDetails ? 'Update' : 'Add'">
  </primary-button>
</div>
<div *ngIf="errorHandling != null && errorHandling.errorMessage.hasActualValue()" class="alert alert-danger"
     role="alert">
  {{ errorHandling.errorMessage }}
</div>

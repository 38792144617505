import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {Merchant} from "../../../../business/interfaces/Merchant";
import {ActivatedRoute} from "@angular/router";
import {AppNavigator, NavigatorTarget} from "../../../../services/app-navigator";
import {MerchantAPI} from "../../../../business/apis/MerchantAPI";
import {
  ActionRow, FilterTable,
  TableColumnInfo,
  TableConfiguration
} from "../../../../components/table-view/table-view.component";
import {Area} from "../../../../business/interfaces/Area";
import {TableAction} from "../../../../components/table-view/enum/TableAction";
import {AreaNameId} from "../../../../business/name-id/AreaNameId";
import {ErrorHandling} from "../../../../common/classes/ErrorHandling";

@Component({
  selector: 'merchant-areas',
  templateUrl: './merchant-areas.component.html',
  styleUrls: ['./merchant-areas.component.scss']
})
export class MerchantAreasComponent {
  @Input() loadData: BehaviorSubject<any> | null = null;
  @Output() reloadData = new EventEmitter<string>();

  merchantDetails: Merchant | null = null;
  errorHandling: ErrorHandling | null = null;

  tableConfiguration: TableConfiguration<Area> = {};
  loadTableData: BehaviorSubject<[any, any]> = new BehaviorSubject<[any, any]>([[], null]);


  isScreenLoading: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private appNavigator: AppNavigator,
    private merchantApi: MerchantAPI
  ) {
  }

  ngOnInit(): void {
    this.tableConfiguration = {
      hasFilter: false,
      tableColumnInfo: this.getColumTableInfo(),
      actionsRow: this.getActionRowTable.bind(this),
      getRowValue: this.getRowValue.bind(this),
      onCellTapped: this.onCellTapped.bind(this),
      loadData: this.loadTableData,
      reloadDataTable: this.reloadDataTable.bind(this),
    };

    this.loadData!.subscribe((data) => {
      this.merchantDetails = data;
      this.reloadDataViews();
      this.isScreenLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.loadTableData?.unsubscribe();
  }


  reloadDataViews() {
    let list = this.merchantDetails?.deliveryAreas ?? [];
    this.tableConfiguration.loadData?.next([list, null]);
  }


  getActionRowTable<T>(item: T): ActionRow[] {
    let deleteItem = {
      title: "Delete",
      type: "btn btn-danger",
      iconClass: "bi bi-trash",
      actionType: TableAction.REMOVE,
      onTapAction: this.onTapAction.bind(this),
    };
    return [deleteItem]
  }

  getColumTableInfo(): TableColumnInfo[] {

    let nameEn = {
      columId: AreaNameId.NAME_EN,
      name: "Name - English",

    };

    let nameAr = {
      columId: AreaNameId.NAME_AR,
      name: "Name - Arabic",
    };

    let city = {
      columId: AreaNameId.CITY,
      name: "City",
    };

    return [city, nameEn, nameAr];
  }

  onCellTapped<T>(column: TableColumnInfo, item: T) {
    const id = column.columId;
    if (id == AreaNameId.ID) {
      this.onTapAreaDetails(item);
    }
  }

  getRowValue<T>(column: TableColumnInfo, item: T): any {
    const id = column.columId;
    let area = item as Area;
    let value = area[id as keyof Area];
    let styleClass = null;

    return {
      value: value,
      class: styleClass,
    };
  }

  navigateToCreateNewItem() {
    this.appNavigator.navigateTo({
      target: NavigatorTarget.merchantAreaDetails,
      id: this.merchantDetails?.id,
    });
  }


  private onTapAction<T>(action: any, item: T) {
    let areaAction = action as TableAction;
    let area = item as Area;
    let call: Observable<any>;

    if (areaAction == TableAction.REMOVE) {
      let merchantId = this.merchantDetails?.id ?? ""
      let areaId = area.id
      call = this.merchantApi.deleteArea({merchantId: merchantId, areaId: areaId});
    } else {
      return;
    }

    this.isScreenLoading = true;
    call.subscribe({
      next: () => {
        this.reloadData!.emit(this.merchantDetails?.id);
      },
      error: (error: any) => {
      },
    });
  }

  private onTapAreaDetails<T>(item: T) {
    let area = item as Area;
    this.appNavigator.navigateTo({
      target: NavigatorTarget.areaDetails,
      id: area.id,
    });
  }

  private reloadDataTable<T>(filterData: FilterTable) {
  }
}



import {Injectable} from "@angular/core";
import {User} from "../interfaces/User";
import {JWTToken} from "../interfaces/JWTToken";
import {TokenStorage} from "../../common/local-storage/TokenStorage";


@Injectable({providedIn: 'root'})
export class UserDatabase {

  tableName: string = "user"

  constructor(private tokenStorage: TokenStorage) {
  }

  save(parameters: { user: User }) {
    let userValue = JSON.stringify(parameters.user);
    localStorage.setItem(this.tableName, userValue);
  }

  getCurrentUser(): User | null {
    let user = localStorage.getItem(this.tableName);
    if (user) {
      let userObject = JSON.parse(user!);
      return userObject;
    }
    return null
  }


  saveJWTToken(parameters: { jWTToken: JWTToken }) {
    let jWTToken = parameters.jWTToken;
    let user = jWTToken.user;
    let accessToken = jWTToken.accessToken;
    let expiresIn = jWTToken.expiresIn;
    this.save({user: user});
    this.tokenStorage.updateTokens({accessToken:accessToken})
  }

  removeCurrentUser() {
    localStorage.clear()
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { ErrorHandling } from "../../../common/classes/ErrorHandling";


@Component({
  selector: "input-date-text-field",
  templateUrl: "./input-date-text-field.component.html",
  styleUrls: ["./input-date-text-field.component.scss"],
})

export class InputDateTextFieldComponent implements OnInit {
  @Input() loadData?: BehaviorSubject<any>;
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>();

  inputValue: string = "";
  @Input() errorHandling: ErrorHandling | null = null;

  @Input() floatingLabel?: string = "";
  @Input() id!: string;
  @Input() group!: FormGroup;
  @Input() validators: ValidatorFn[] = [];
  @Input() isReadOnly: boolean = false;
  control: FormControl = new FormControl();


  constructor() {
  }

  get errorMessage(): string {
    if (this.control.touched) {
       if (this.control.hasError("invalid")) {
        let message = `${this.floatingLabel} is invalid`;

        if (this.control.errors && this.control.hasError("message")) {
          message = this.control.errors["message"];
        }
        return message
      }
    }
    if (this.errorHandling) {
      return this.getErrorMessage();
    }
    return "";
  }

  ngOnInit(): void {
    let validators = [];
    validators.push(...this.validators);
    this.control.setValidators(validators);
    this.group.addControl(this.id, this.control);

    this.loadData?.subscribe((data) => {
      this.inputValue = data;
      this.updateViews(data);
    });
  }

  isInputValid(): string {
    // console.log("is touched == " + this.id + " " + this.control.touched);
    if (this.control.touched) {
      if (
        this.control.hasError("required")
      ) {
        return "is-invalid";
      }
    }
    if (this.errorMessage.hasActualValue()) {
      return "is-invalid";
    }
    return "";
  }




  ngOnDestroy(): void {
    this.group?.removeControl(this.id);
  }

  onInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.updateViews(value);
  }

  updateViews(value: any) {
    this.onchange?.emit(value);
  }

  getErrorMessage() {
    let id = this.id ?? "";
    let title = this.floatingLabel ?? "";
    return this.errorHandling?.getValidationErrorMessage(id, title) ?? "";
  }
}

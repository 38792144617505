import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ErrorHandling } from "../../../common/classes/ErrorHandling";
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { NameValidationError } from "../validators/name-validator";
import { Country } from "../../../business/interfaces/Country";

@Component({
  selector: "mobile-number",
  templateUrl: "./mobile-number.component.html",
  styleUrls: ["./mobile-number.component.css"],
})
export class MobileNumberComponent {
  @Input() loadInputData?: BehaviorSubject<[any, any]>;
  @Input() list: Country[] = [];

  @Input() isLoading: boolean = false;
  @Input() allowEditing: boolean = false;
  @Input() connectToServer: boolean = false;
  @Input() errorHandling: ErrorHandling | null = null;
  @Input() floatingMobileLabel?: string = "Mobile Number";
  @Input() floatingCodeLabel?: string = "Code";
  @Input() mobileNumberId!: string;
  @Input() countryCodeId!: string;

  @Input() group!: FormGroup;
  @Input() validators: ValidatorFn[] = [];

  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSearchChanged: EventEmitter<any> = new EventEmitter<any>();

  control: FormControl = new FormControl();
  countryControl: FormControl = new FormControl();
  loadingTimeout: any;
  selectedCountry: Country | null = null; // Hold the selected option
  countryCode: string = "";
  mobileNumber: string = "";
  dropdownOpen: boolean = false;
  selectClicked: boolean = false;

  constructor() {}

  get filteredOptions(): Country[] {
    let list = this.list;
    if (this.allowEditing && !this.connectToServer) {
      list = list.filter((option) =>
        option.nameEn.toLowerCase().includes(this.countryCode.toLowerCase())
      );
    }
    return list;
  }

  get errorMessage(): string {
    let errorCountryCodeMessage = this.errorCountryCodeMessage;
    if (errorCountryCodeMessage.hasValue()) {
      return errorCountryCodeMessage;
    }

    let errorMobileNumber = this.errorMobileNumberMessage;
    if (errorMobileNumber.hasActualValue()) {
      return errorMobileNumber;
    }
    return "";
  }

  get errorMobileNumberMessage(): string {
    if (this.control.touched) {
      if (this.control.hasError(NameValidationError.invalidFormat)) {
        return `${this.floatingMobileLabel} is invalidFormat`;
      } else if (this.control.hasError("required")) {
        return `${this.floatingMobileLabel} is required`;
      } else if (
        this.control.hasError("minlength") ||
        this.control.hasError("maxLength")
      ) {
        return `${this.floatingMobileLabel} is invalid`;
      }
    }
    if (this.errorHandling) {
      return this.getErrorMessage();
    }
    return "";
  }

  get errorCountryCodeMessage(): string {
    if (this.countryControl.touched) {
      if (this.countryControl.hasError(NameValidationError.invalidFormat)) {
        return `${this.floatingCodeLabel} is invalidFormat`;
      } else if (this.countryControl.hasError("required")) {
        return `${this.floatingCodeLabel} is required`;
      }
    }
    if (this.errorHandling) {
      return this.getErrorMessage();
    }
    return "";
  }

  ngOnInit(): void {
    let validators = [];
    validators.push(Validators.minLength(6));
    validators.push(...(this.validators ?? []));

    this.control.setValidators(validators);
    this.countryControl.setValidators([...(this.validators ?? [])]);
    this.group.addControl(this.mobileNumberId, this.countryControl);
    this.group.addControl(this.countryCodeId, this.countryControl);

    this.loadInputData?.subscribe((data) => {
      this.updateViews(data);
    });

    this.list = [
      {
        nameEn: "Egypt",
        nameAr: "مصر",
        iso2letter: "EG",
        iso3letter: "EGY",
        countryCode: "20",
        createdAt: "",
      },
      {
        nameEn: "United States",
        nameAr: "الولايات المتحدة",
        iso2letter: "US",
        iso3letter: "USA",
        countryCode: "1",
        createdAt: "",
      },
      {
        nameEn: "United Kingdom",
        nameAr: "المملكة المتحدة",
        iso2letter: "GB",
        iso3letter: "GBR",
        countryCode: "44",
        createdAt: "",
      },
    ];
  }

  ngOnDestroy(): void {
    this.group?.removeControl(this.mobileNumberId);
    this.group?.removeControl(this.countryCodeId);
  }

  isInputValid(): string {
    let isMobileNumberInputValid = this.isMobileNumberInputValid();

    if (isMobileNumberInputValid.hasActualValue()) {
      return isMobileNumberInputValid;
    }
    let isCountryCodeInputValid = this.isCountryCodeInputValid();
    if (isCountryCodeInputValid.hasActualValue()) {
      return isCountryCodeInputValid;
    }
    return "";
  }

  isMobileNumberInputValid(): string {
    if (this.control.touched) {
      if (
        this.control.hasError("required") ||
        this.control.hasError(NameValidationError.invalidFormat)
      ) {
        return "is-invalid";
      }
    }
    if (this.errorMessage.hasActualValue()) {
      return "is-invalid";
    }

    return "";
  }

  isCountryCodeInputValid(): string {
    if (this.countryControl.touched) {
      if (
        this.countryControl.hasError("required") ||
        this.countryControl.hasError(NameValidationError.invalidFormat)
      ) {
        return "is-invalid";
      }
    }
    if (this.errorMessage.hasActualValue()) {
      return "is-invalid";
    }
    return "";
  }

  onSearchChange(event: any) {
    this.dropdownOpen = true;
    const value = (event.target as HTMLInputElement).value;
    if (this.connectToServer == false) {
      return;
    }

    clearTimeout(this.loadingTimeout); // Clear any existing timeout
    // Show loading spinner after 500 milliseconds
    this.loadingTimeout = setTimeout(() => {
      // Show loading indicator
      this.onSearchChanged.emit(value);
    }, 1000);
  }

  updateCountrySelected(country: any) {
    if (country.isString()) {
      const selected = [country, this.mobileNumber];
      setTimeout(() => {
        this.updateViews(selected);
      }, 100);
    }
  }

  onSelectCountry(option: any): void {
    let selected = option as Country;
    this.selectedCountry = selected;
    this.updateCountryCodeField();
    this.dropdownOpen = false;
    // this.onSelected.emit(option);
  }

  updateViews(value: any) {
    const [countryCode, mobile] = value;
    let country = this.getCountryByISOCode(countryCode);
    this.selectedCountry = country;
    this.updateCountryCodeField();
    this.mobileNumber = mobile;
    // if(selected != null){
    //   this.onSearchChanged?.emit(value);
    // }
  }

  getCountryByISOCode(countryCode: string): Country | null {
    let search = countryCode.toLowerCase();
    let countryList = this.list;
    return (
      countryList.find(
        (country) =>
          country.countryCode.toLowerCase() === search ||
          country.iso3letter.toLowerCase() === search
      ) ?? null
    );
  }

  updateCountryCodeField() {
    let countryCode = this.selectedCountry?.countryCode ?? "";
    this.countryCode = countryCode.hasActualValue() ? `+ ${countryCode}` : "";
  }

  getErrorMessage() {
    let id = this.mobileNumberId ?? "";
    let title = this.floatingMobileLabel ?? "";
    return this.errorHandling?.getValidationErrorMessage(id, title) ?? "";
  }

  /* option views */
  toggleSelect(isOpen: boolean) {
    if (isOpen == false) {
      this.updateCountryCodeField();
    }

    this.dropdownOpen = isOpen;
  }

  onInputBlur() {
    // Delay hiding the select to allow for a click event on the select
    setTimeout(() => {
      if (!this.selectClicked) {
        this.toggleSelect(false);
      }
    }, 200);
  }

  onSelectBlur() {
    // Flag indicating that the select was clicked
    this.selectClicked = false;
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  onKeyDown(event: KeyboardEvent) {
    if (!this.allowEditing) {
      event.preventDefault();
    }
  }

  focusInputField(inputField: HTMLInputElement): void {
    inputField.focus();
  }
}
